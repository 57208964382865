import React from 'react';
import {
  PreviewSettings,
  TemplateType,
  Orientation,
  PageSize,
  MonetaryUnit,
  PageBreak,
  ColumnWidth,
  TextOverflow
} from '../types';

interface SettingsProps {
  settings: PreviewSettings;
  onChange: (settings: PreviewSettings) => void;
}

export const Settings: React.FC<SettingsProps> = ({ settings, onChange }) => {
  const handleChange = <K extends keyof PreviewSettings>(key: K, value: PreviewSettings[K]) => {
    onChange({ ...settings, [key]: value });
  };

  const renderPageBreak = () => {
    if (settings.template !== 'grouped-aggregate') {
      return;
    }

    return (
      <div className="pdf-preview-modal__settings-group">
        <label>Page Break</label>
        <select
          value={settings.pageBreak}
          onChange={(e) => handleChange('pageBreak', e.target.value as PageBreak)}
        >
          <option value="standard">Standard</option>
          <option value="group">Break on groups</option>
        </select>
      </div>
    );
  };

  return (
    <div className="pdf-preview-modal__settings">
      {/* <div className="pdf-preview-modal__settings-group">
        <label>Template</label>
        <select
          value={settings.template}
          onChange={(e) => handleChange('template', e.target.value as TemplateType)}
        >
          <option value="transaction-table">Transaction Table</option>
          <option value="income-statement">Income Statement</option>
          <option value="grouped-aggregate">Aggregated</option>
        </select>
      </div> */}

      <div className="pdf-preview-modal__settings-group">
        <label>Page Size</label>
        <select
          value={settings.pageSize}
          onChange={(e) => handleChange('pageSize', e.target.value as PageSize)}
        >
          <option value="A4">A4</option>
          <option value="Letter">Letter</option>
          <option value="Legal">Legal</option>
        </select>
      </div>

      <div className="pdf-preview-modal__settings-group">
        <label>Orientation</label>
        <select
          value={settings.orientation}
          onChange={(e) => handleChange('orientation', e.target.value as Orientation)}
        >
          <option value="portrait">Portrait</option>
          <option value="landscape">Landscape</option>
        </select>
      </div>

      {renderPageBreak()}

      <div className="pdf-preview-modal__settings-group">
        <label>Column Width</label>
        <select
          value={settings.columnWidth}
          onChange={(e) => handleChange('columnWidth', e.target.value as ColumnWidth)}
        >
          <option value="bestfit">Best fit</option>
          <option value="inherit">Inherit</option>
        </select>
      </div>

      <div className="pdf-preview-modal__settings-group">
        <label>Text overflow</label>
        <select
          value={settings.textOverflow}
          onChange={(e) => handleChange('textOverflow', e.target.value as TextOverflow)}
        >
          <option value="linebreak">Word Wrap</option>
          <option value="visible">Overflow</option>
          <option value="ellipsize">Truncate</option>
        </select>
      </div>
    </div>
  );
};

export default Settings;
