import React, { useRef } from 'react';
import { noop } from 'lodash';

import { VerticalFilterBar, VerticalFilterBarProps } from 'common/components/FilterBar/VerticalFilterBar';

export type VerticalViewModeFilterBarProps = Pick<
  VerticalFilterBarProps,
  | 'columns'
  | 'computedColumns'
  | 'dataSource'
  | 'filterParameterConfigurations'
  | 'onUpdate'
  | 'onUpdateAllFilterParameters'
  | 'onUpdateSingleFilterParameter'
>;

/**
 * Displays the filter bar for use in a view mode filterable card.
 */
const VerticalViewModeFilterBar = ({
  columns = {},
  computedColumns = [],
  dataSource = [],
  filterParameterConfigurations = [],
  onUpdate = noop,
  onUpdateAllFilterParameters = noop,
  onUpdateSingleFilterParameter = noop
}: VerticalViewModeFilterBarProps) => {
  const filterBarRef = useRef<HTMLDivElement>(null);

  if (filterParameterConfigurations.length <= 0) {
    return null;
  }

  const filterBarProps: VerticalFilterBarProps = {
    columns,
    computedColumns,
    dataSource,
    filterParameterConfigurations,
    onUpdate,
    onUpdateAllFilterParameters,
    onUpdateSingleFilterParameter,
    editMode: false,
    isReadOnly: true,
    onInEditFilterChange: noop,
    onToggle: (isShown: boolean) => {
      if (!filterBarRef?.current) {
        return;
      }

      // This sets the height of the filter bar options based on the height of the forge-card parent
      if (filterBarRef.current && isShown) {
        // If no forge-card parent is found, just use a default size that will look ok
        // ICardComponent doesn't have the offsetHeight property in typescript, but it does in browser
        // @ts-expect-error
        const parentHeight = filterBarRef.current.closest('forge-card')?.offsetHeight ?? 150;

        // Set the height of the filters container to the card height minus the filter bar toggle height
        const FILTER_HEADER_HEIGHT = 38;
        const filtersContainer = filterBarRef.current.querySelector('.vertical-filter-wrapper');
        if (filtersContainer) {
          (filtersContainer as HTMLElement).style.height = parentHeight - FILTER_HEADER_HEIGHT + 'px';
        }
      }
    }
  };

  return (
    <div ref={filterBarRef} className="filterable-card-header-filter-bar">
      <VerticalFilterBar {...filterBarProps}></VerticalFilterBar>
    </div>
  );
};

export default VerticalViewModeFilterBar;
