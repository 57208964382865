import _ from 'lodash';

import I18n from 'common/i18n';
import { fetchJsonWithDefaultHeaders, checkStatus, defaultHeaders, fetchJson } from 'common/http';
import {
  AssetType,
  AudienceScope,
  DisplayType,
  PublicationStage,
  View,
  ViewRight,
  ViewType
} from 'common/types/view';
import { ViewColumn } from 'common/types/viewColumn';
import getRevisionSeq from 'common/js_utils/getRevisionSeq';
import { isStoryDraft, isQueryJSONBased } from 'common/views/view_types';

import { DsmapiResource } from 'common/types/dsmapi';
import { Revision, RevisionMetadata } from 'common/types/revision';
import { ViewWithVQEColumns, VQEColumn } from 'common/explore_grid/redux/store';

export const assetIsInternal = (coreView: View) => _.isMatch(coreView, { permissions: { scope: 'site' } });
export const assetIsPublic = (coreView: View) =>
  (_.get(coreView, 'grants') || []).some((grant) =>
    _.get(grant, 'flags', []).some((flag) => flag === 'public')
  );
  import { localizeLink } from 'common/locale';

// There is nothing (core-side) preventing a Visualization Canvas from having a broader
// audience than its data source. VizCans cannot render unless the user can access the
// data source, hence a mismatched audience is confusing for users. To work around the
// problem until we can figure out a better way (hi future person, it's 2019! What year are
// you in?), we prevent people from publishing a VizCan to a public audience unless the data
// source is itself public (at publication time, anyway. We make no attempt to react to
// data source audience changes).
// This method returns a promise evaluating to true if the vizcan's data source is currently
// public, or false otherwise. If there is an error fetching the answer, the error is passed
// through as a promise rejection.
export const fetchVisualizationCanvasHasPublicDataSource = (viewUid: string) =>
  fetchJsonWithDefaultHeaders(`/visualization_canvas/${viewUid}/has_public_data_source.json`, {
    credentials: 'same-origin'
  });

// NOTE: defaults are best effort - use them with caution!
// Requires coreView, but allows caller to pass isRevision and isStoryDraft
// Provides default values to isRevision and isStoryDraft if not provided
export const assetIsDraft = ({
  coreView,
  isRevision,
  isDraftStory
}: {
  coreView: Partial<View>;
  isRevision?: boolean;
  isDraftStory?: boolean;
}) => {
  if (_.isNil(coreView)) {
    throw new Error('coreView is a required argument to isDraft()');
  }

  if (_.isUndefined(isRevision)) {
    isRevision = !_.isUndefined(getRevisionSeq()); // default to checking getRevisionSeq()
  }

  if (_.isUndefined(isDraftStory)) {
    isDraftStory = isStoryDraft(coreView);
  }

  return !!isRevision || !!isDraftStory || assetIsDraftInCore(coreView);
};

// NOTE: this does not cover story drafts nor revisions.
const assetIsDraftInCore = (coreView: Partial<View>) => {
  return coreView.publicationStage === PublicationStage.Unpublished;
};

const fetchOptions = (method: 'POST' | 'PATCH' | 'GET' | 'PUT' | 'DELETE'): RequestInit => ({
  method,
  credentials: 'same-origin',
  headers: defaultHeaders
});

const fetchPostOptions: RequestInit = fetchOptions('POST');
const fetchPatchOptions: RequestInit = fetchOptions('PATCH');
const fetchGetOptions: RequestInit = fetchOptions('GET');

export const fetchAllDsmapiRevisions = async (fourByFour: string): Promise<DsmapiResource<Revision>[]> => {
  return await fetch(`/api/publishing/v1/revision/${fourByFour}`, fetchGetOptions)
    .then(checkStatus)
    .then((resp) => resp.json());
};

export const getOpenDsmapiBrowserRevision = async (fourByFour: string) => {
  const assets = await fetchAllDsmapiRevisions(fourByFour);
  return _(assets)
    .filter({ resource: { creation_source: 'browser' } })
    .reject('resource.closed_at')
    .map('resource')
    .first();
};

export const createDsmapiRevision = async (
  fourByFour: string,
  makeViewSource: boolean,
  metadata: RevisionMetadata | undefined = undefined
): Promise<number> => {
  const path = `/api/publishing/v1/revision/${fourByFour}`;
  const body = {
    action: { type: 'replace' },
    creation_source: 'browser'
  };

  if (metadata) {
    body['metadata'] = metadata;
  }

  const reqOpts = {
    ...fetchPostOptions,
    body: JSON.stringify(body)
  };
  const { revision_seq: revisionSeq } = await fetch(path, reqOpts)
    .then(checkStatus)
    .then((resp) => resp.json())
    .then(({ resource }) => resource);

  // non tabular datasets should not have a view source
  if (makeViewSource) {
    await createDsmapiViewSource(fourByFour, revisionSeq);
  }
  return revisionSeq;
};

const createViewAndRevision = async (newViewData: CreateView): Promise<Revision> => {
  const path = '/api/publishing/v1/revision';

  const body = {
    action: { type: 'replace' },
    creation_source: 'browser',
    metadata: newViewData
  };

  const reqOpts = {
    ...fetchPostOptions,
    body: JSON.stringify(body)
  };
  const revision = await fetch(path, reqOpts)
    .then(checkStatus)
    .then((resp) => resp.json())
    .then(({ resource }) => resource);

  return revision;
};

// returns the URL to redirect to
// will be either a view, or a revision based on a new view
export const createNewChildDraft = async (
  parentView: View | ViewWithVQEColumns,
  queryString: string,
  viewName: string,
  displayType?: DisplayType
): Promise<string> => {
  const newViewData = getNewViewData(parentView, queryString, viewName, displayType);
  if (shouldCreateViewViaRevision(parentView)) {
    const revision = await createViewAndRevision(newViewData);
    if (!revision || !revision.fourfour || (revision.revision_seq !== 0 && !revision.revision_seq)) {
      throw new Error(`Error creating view & revision; got ${revision}`);
    }
    return `/d/${revision.fourfour}/revisions/${revision.revision_seq}/soql`;
  } else {
    const childView = await createChildView(newViewData);
    if (!childView || !childView.id) {
      throw new Error(`Error creating view; got ${childView}`);
    }
    return `/d/${childView.id}/explore`;
  }
};

export const restoreDsmapiRevision = (rev: Revision): Promise<Revision> => {
  const path = `/api/publishing/v1/revision/${rev.fourfour}/${rev.revision_seq}/restore`;
  const body = {
    creation_source: 'browser'
  };

  const reqOpts = {
    ...fetchPostOptions,
    body: JSON.stringify(body)
  };
  return fetch(path, reqOpts)
    .then(checkStatus)
    .then((resp) => resp.json())
    .then(({ resource }: { resource: Revision }) => resource);
};

export const changeRevisionVisibility = (rev: Revision, visible: boolean): Promise<Revision> => {
  const path = `/api/publishing/v1/revision/${rev.fourfour}/${rev.revision_seq}`;
  const body = {
    archive: { visible }
  };

  const reqOpts = {
    ...fetchPatchOptions,
    body: JSON.stringify(body)
  };
  return fetch(path, reqOpts)
    .then(checkStatus)
    .then((resp) => resp.json())
    .then(({ resource }: { resource: Revision }) => resource);
};

export const createDsmapiViewSource = async (fourByFour: string, revisionSeq: number) => {
  return fetch(`/api/publishing/v1/revision/${fourByFour}/${revisionSeq}/source`, {
    ...fetchPostOptions,
    body: JSON.stringify({
      source_type: {
        type: 'view'
      }
    })
  })
    .then(checkStatus)
    .then((resp) => resp.json());
};

export const navigateToRevision = (fourfour: string, revisionSeq: number) => {
  window.location.href = localizeLink(`/d/${fourfour}/revisions/${revisionSeq}`);
};

export const editDatasetAsRevision = async (fourByFour: string, makeViewSource: boolean) => {
  const openRevision = await getOpenDsmapiBrowserRevision(fourByFour);
  if (_.has(openRevision, 'revision_seq')) {
    navigateToRevision(fourByFour, openRevision!.revision_seq);
  } else {
    const revisionSeq = await createDsmapiRevision(fourByFour, makeViewSource);
    navigateToRevision(fourByFour, revisionSeq);
  }
};

// false if no working copy
export const findWorkingCopyFor = (uid: string) => {
  const workingCopyUrl = `/api/views/${uid}.json?method=getLensPublicationGroup&stage=unpublished`;

  return fetchJson(workingCopyUrl, fetchGetOptions).then((views: View[]) => {
    return views[0] || false;
  });
};

type CreateView = Partial<Omit<View, 'columns'>> & {
  columns?: VQEColumn[] | ViewColumn[];
  originalViewId: string;
};

const getNewViewData = (
  parentView: View | ViewWithVQEColumns,
  queryString: string,
  viewName: string,
  displayType?: DisplayType
): CreateView => {
  const { id, name, columns, hideFromCatalog, hideFromDataJson } = parentView;
  return {
    columns,
    displayType: displayType || DisplayType.Table,
    hideFromCatalog,
    hideFromDataJson,
    metadata: {
      availableDisplayTypes: ['table', 'fatrow', 'page'],
      renderTypeConfig: {
        visible: {
          table: true
        }
      }
    },
    name: viewName || `${I18n.t('screens.ds.bar.create_view_name_prefix')} ${name}`,
    originalViewId: id,
    queryString,
    viewType: ViewType.Tabular
  };
};

const createChildView = (newViewData: CreateView): Promise<View> => {
  return fetch('/api/views.json', {
    ...fetchPostOptions,
    body: JSON.stringify(newViewData)
  })
    .then(checkStatus)
    .then((resp) => resp.json());
};

export const copyTabularSoQLView = (viewToBeCopied: View, viewName: string): Promise<View> => {
  const name = viewName || `${I18n.t('screens.ds.bar.create_view_name_prefix')} ${viewToBeCopied.name}`;
  return fetchJson(`/api/views/${viewToBeCopied.id}?method=clone&name=${encodeURIComponent(name)}`, {
    ...fetchPostOptions,
    body: JSON.stringify({})
  });
};

export const getDirectParents = (viewId: string): Promise<View[]> => {
  return fetchJson(`/api/views/${viewId}.json?method=getDirectParents`, fetchGetOptions);
};

export const canReadFromAllParents = (viewId: string): Promise<boolean> => {
  return getDirectParents(viewId)
    .then((parents: View[]) => {
      return parents.reduce(
        (acc: boolean, current: View) =>
          acc &&
          (current.rights.includes(ViewRight.Read) || current.permissions?.scope === AudienceScope.Public),
        true
      );
    })
    .catch((err) => false);
};

export const defaultWithOnlyOrderByInQuery = (view?: View | ViewWithVQEColumns): boolean => {
  return (
    !!view &&
    view.assetType === AssetType.Dataset &&
    Object.keys(view.query).length === 1 &&
    Object.keys(view.query)[0] === 'orderBys'
  );
};

export const isUnacceptableQueryJson = (v?: View | ViewWithVQEColumns) =>
  isQueryJSONBased(v) && !defaultWithOnlyOrderByInQuery(v);

export const shouldUseExploreInsteadOfGrid = (coreView?: View): boolean => {
  return !isUnacceptableQueryJson(coreView);
};

export const shouldCreateViewViaRevision = (basedOnView?: View | ViewWithVQEColumns): boolean => {
  if (basedOnView && (isUnacceptableQueryJson(basedOnView))) {
    return false;
  }
  return true;
};

export const checkIsMetadataValid = async (fourByFour: string) => {
  return fetch(`/api/publishing/v1/templates/validate/${fourByFour}`, fetchGetOptions)
      .then(checkStatus)
      .then((resp) => resp.json())
      .then(({status}) => status === 'valid');
};

export const setLockedOnAsset = async (fourByFour: string, locked: boolean) => {
  return fetch(`/api/views/${fourByFour}?method=setLocked&locked=${locked}`, { ...fetchPostOptions})
      .then(checkStatus)
      .then((resp) => resp.json());
};
