import _ from 'lodash';

import SoqlHelpers from 'common/visualizations/dataProviders/SoqlHelpers';
import assertIsOneOfTypes from 'common/assertions/assertIsOneOfTypes';
import { getBasemapLayerStyles } from 'common/visualizations/views/map/basemapStyle';
import { getTileUrl, escapeGeoColumn } from 'common/visualizations/helpers/MapHelper';

import HeatClusters from './partials/HeatClusters';
import PartialWrapper from './partials/PartialWrapper';

// Prepares renderOptions and renders partials.
// Refer: common/visualizations/views/map/README.md
// Basic heat map version (No tuning option from vif)
// Clusters on server side using snap_to_grid and draws heatmap
// based on count of each record.
export default class VifHeatOverlay {
  constructor(map) {
    const seriesId = _.uniqueId();

    this._heatClusters = new PartialWrapper(map, new HeatClusters(map, seriesId));
  }

  // Renders the partials required for this overlay and sets up required mouse interactions.
  // vif            : <object> vif got from AX/visualization
  // renderOptions  : <object> got from this overlays prepare method
  // overlayOptions : object
  // overlayOptions.renderLayersBefore : <string> renders all mapbox-gl-layers for this overlay
  //                                      before given layer-id
  render(vif, renderOptions, overlayOptions) {
    this._heatClusters.render(vif, renderOptions, overlayOptions);
  }

  async prepare(vif, metaDataPromise) {
    const datasetMetadata = await metaDataPromise;
    return {
      layerStyles: getBasemapLayerStyles(vif),
      dataUrl: this._getDataUrl(vif, datasetMetadata)
    };
  }

  destroy() {
    this._heatClusters.destroy();
  }

  _getDataUrl(vif, datasetMetadata) {
    const domain = vif.getDomain();
    const datasetUid = vif.getDatasetUid();
    const columnName = vif.getColumnName();
    const escapedColumnName = escapeGeoColumn(columnName, datasetMetadata);

    assertIsOneOfTypes(columnName, 'string');
    assertIsOneOfTypes(domain, 'string');
    assertIsOneOfTypes(datasetUid, 'string');

    let conditions = [`{{'${escapedColumnName}' column condition}}`];

    const filters = SoqlHelpers.whereClauseFilteringOwnColumn(vif, 0);
    if (!_.isEmpty(filters)) {
      conditions.push(filters);
    }

    const query =
      `select count(*),snap_to_grid(${escapedColumnName},{snap_precision}) ` +
      `where ${conditions.join(' AND ')} ` +
      `group by snap_to_grid(${escapedColumnName}, {snap_precision}) ` +
      'limit 100000 ';

    return getTileUrl(vif.getDomain(), vif.getDatasetUid(), query, vif.getSimplificationLevel());
  }
}
