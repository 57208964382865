import $ from 'jquery';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { assert, assertHasProperties } from 'common/assertions';
import { shouldUseReactComponentBase } from 'lib/FlexibleLayoutUtils';

import { ComponentType } from 'types';
import StorytellerUtils from 'lib/StorytellerUtils';
import { StorytellerReduxStore } from 'store/StorytellerReduxStore';
import { GlobalFilterProps } from './types';
// This import is needed by jest
import './shared/componentBase';

import GlobalFilter from './componentGlobalFilter/GlobalFilter';

$.fn.componentGlobalFilter = componentGlobalFilter;
function componentGlobalFilter(props: GlobalFilterProps): JQuery {
  const $this = $(this);

  // This makes the component tabbable so that when there is a
  // dataset selected it will render the dataset name when focused

  const { blockId, componentIndex, componentData, editMode } = props;

  assertHasProperties(componentData, 'type');
  assert(
    componentData.type === ComponentType.GLOBAL_FILTER,
    `componentGlobalFilter: Unsupported component type ${componentData.type}`
  );

  if (!shouldUseReactComponentBase()) {
    this.componentBase(props);
  }

  const classes = StorytellerUtils.typeToClassesForComponentType(componentData.type);
  $this.addClass(classes);

  let $globalFilterComponent = $this.find('.global-filter-container');
  if ($globalFilterComponent.length === 0) {
    $globalFilterComponent = $('<div>', { class: 'global-filter-container' });
    $this.append($globalFilterComponent);
  }
  ReactDOM.render(
    <Provider store={StorytellerReduxStore}>
      <GlobalFilter
        blockId={blockId as string}
        componentIndex={componentIndex as number}
        $element={$this}
        editMode={editMode}
      />
    </Provider>,
    $globalFilterComponent[0]
  );

  return this;
}

export default componentGlobalFilter;
