import React, { useRef } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { BlockComponent, ComponentType } from 'types';
import BlockEditControls from './BlockSectionEditControls';
import StoryComponentRenderer from './StoryComponentRenderer';
import { generateComponentId } from 'editor/model/BlockItemModel';
import { selectors as storySelectors } from 'store/selectors/StorySelectors';
import { StorytellerState } from 'store/StorytellerReduxStore';

export interface ClassicBlockSectionProps {
  blockId: string;
  components: BlockComponent[];
  storyUid: string;
  blockIndex: number;
  blockCount: number;
  editMode: boolean;
}

const ClassicBlockSection: React.FC<ClassicBlockSectionProps> = (props) => {
  const { blockId, components, editMode, blockIndex, blockCount, storyUid } = props;

  const backgroundColor = useSelector((state: StorytellerState) =>
    storySelectors.getBlockBackgroundColor(state, blockId)
  );

  const storyLayout = useSelector((state: StorytellerState) =>
    storySelectors.getStoryLayout(state, storyUid)
  );

  const blockLayout = useSelector((state: StorytellerState) => storySelectors.getBlockLayout(state, blockId));
  const componentWidths = blockLayout.split('-');
  const blockRef = useRef(null);

  const getComponents = () => {
    return components.map((_component, componentIndex) => {
      let classes: string;
      if (blockLayout === 'fluid6') {
        classes = classNames('fluid6', {
          col4: storyLayout === 'narrative',
          col2: storyLayout !== 'narrative'
        });
      } else {
        classes = classNames(`col${componentWidths[componentIndex]}`);
      }

      const reactKey = generateComponentId(blockId, componentIndex);

      return (
        <StoryComponentRenderer
          key={reactKey}
          blockId={blockId}
          componentIndex={componentIndex}
          additionalClasses={classes}
          editMode={editMode}
        />
      );
    });
  };

  const getTextAndMediaComponents = () => {
    const columnClass = 'col12';
    return (
      <>
        <div className="col6 text-top-media">
          <StoryComponentRenderer
            blockId={blockId}
            componentIndex={0}
            additionalClasses={columnClass}
            editMode={editMode}
          />
          <StoryComponentRenderer
            blockId={blockId}
            componentIndex={1}
            additionalClasses={columnClass}
            editMode={editMode}
          />
        </div>
        <div className="col6 text-top-media">
          <StoryComponentRenderer
            blockId={blockId}
            componentIndex={2}
            additionalClasses={columnClass}
            editMode={editMode}
          />
          <StoryComponentRenderer
            blockId={blockId}
            componentIndex={3}
            additionalClasses={columnClass}
            editMode={editMode}
          />
        </div>
      </>
    );
  };

  const isGlobalFilter = components[0].type === ComponentType.GLOBAL_FILTER;
  const blockClasses = classNames('block', {
    sticky: isGlobalFilter
  });
  const blockContentClasses = classNames('block-content', {
    'block-edit-outline': editMode
  });

  return (
    <div className={blockClasses} style={{ backgroundColor }} data-block-id={blockId} ref={blockRef}>
      <div className={blockContentClasses}>
        {editMode && (
          <BlockEditControls
            blockId={blockId}
            color={backgroundColor || ''}
            storyUid={storyUid}
            blockIndex={blockIndex}
            blockCount={blockCount}
            parentRef={blockRef}
          />
        )}
        {blockLayout === '12-12-12-12' ? getTextAndMediaComponents() : getComponents()}
      </div>
    </div>
  );
};

export default ClassicBlockSection;
