import { mapValues } from 'lodash';
import { ColDef } from '@ag-grid-community/core';

import { Row } from './type';

export const parseRows = (rows: Row[], columnDefs: ColDef[]): Row[] => {
  const numericSortColumns = columnDefs
    .filter((columnDef) => columnDef.type === 'number')
    .map((columnDef) => columnDef.field!);

  if (numericSortColumns.length === 0) {
    return rows;
  }

  return rows.map((row) => {
    return mapValues(row, (value: any, columnName: string) => {
      if (numericSortColumns.includes(columnName)) {
        return parseFloat(value);
      }
      return value;
    });
  });
};
