import $ from 'jquery';
import _ from 'lodash';

import I18n from 'common/i18n';
import { assert, assertHasProperty, assertIsOneOfTypes, assertIsNotNil } from 'common/assertions';

import { HTMLBlockComponent } from 'types';
import { HTMLProps } from './types';
import Constants from 'lib/Constants';
import Environment from 'StorytellerEnvironment';
import StorytellerUtils from 'lib/StorytellerUtils';

import { richTextEditorManager } from 'editor/RichTextEditorManager';
import { windowSizeBreakpointStore } from 'editor/stores/WindowSizeBreakpointStore';
import { storyStore } from 'editor/stores/StoryStore';
import { shouldUseReactComponentBase } from 'lib/FlexibleLayoutUtils';
import './shared/componentBase';

/**
 * @function componentHTML
 * @description
 *
 * With Flexible Layouts Enabled:
 * IN STORY VIEW MODE: Returns the HTML from componentData
 * IN STORY EDIT MODE: Creates a RichTextEditor, and handles setups and updates.
 *
 * Triggers:
 * - rich-text-editor::format-change
 * - rich-text-editor::content-change
 * - rich-text-editor::height-change
 * Events:
 * - destroy: Tears down the component, i.e. calls deleteEditor on richTextEditorManager
 * @param {object} componentData - An object with a type and value attribute
 * @returns {jQuery} - The rendered layout jQuery element
 */
$.fn.componentHTML = componentHTML;

export default function componentHTML(props: HTMLProps): JQuery {
  const { componentData, theme, extraContentClasses = [], editMode } = props;

  assertHasProperty(componentData, 'type');
  assert(
    StorytellerUtils.componentInstanceOf(componentData.type, 'html'),
    `componentHTML: Unsupported component type ${componentData.type}`
  );

  if (!editMode) {
    this.html(componentData.value);
    this.addClass('typeset squire-formatted');
    this.addClass(extraContentClasses.join(' '));
    return this;
  } else {
    const isToC = componentData.type === 'html.tableOfContents';
    const needsEditorSetup = !this.is('[data-editor-id]');

    this.data('component-rendered-data', componentData); // Cache the data.
    if (isToC) {
      this.toggleClass('component-html-table-of-contents-empty', _.isNull(componentData.value));
    }

    if (needsEditorSetup) {
      _setupRichTextEditor(this, componentData, theme, extraContentClasses);
    } else {
      _updateRichTextEditor(this, componentData, theme);
    }

    // If we're showing the empty-ToC placeholder, prevent RTE from sending
    // update events (it likes to treat empty content as a single <br>).
    if (isToC && _.isNull(componentData.value)) {
      this.on('rich-text-editor::content-change.component-html', (event: Event) => {
        event.stopImmediatePropagation();
      });
    } else {
      this.off('rich-text-editor::content-change.component-html');
    }
  }

  if (!shouldUseReactComponentBase()) {
    this.componentBase(props);
  }

  return this;
}

function _setupRichTextEditor(
  $element: JQuery,
  componentData: HTMLBlockComponent,
  theme: string | null,
  extraContentClasses: string[]
) {
  const editorId = _.uniqueId();

  assertHasProperty(componentData, 'value');

  const isToC = componentData.type === 'html.tableOfContents';
  _setupPhantomEditor($element, componentData, theme);

  if (isToC) {
    $element.append(
      $('<div>', { class: 'component-html-table-of-contents-placeholder' }).text(
        I18n.t('editor.table_of_contents.placeholder')
      )
    );
  }

  $element.append($('<div>', { class: 'component-blinder hidden' }));

  $element
    .addClass(StorytellerUtils.typeToClassesForComponentType(componentData.type))
    .attr('data-editor-id', editorId);

  const editor = richTextEditorManager.createEditor($element, editorId, componentData.value || '');

  theme && editor.applyThemeAndLayoutClass(theme, storyStore.getStoryLayout(Environment.STORY_UID));

  $element.one('destroy', () => {
    richTextEditorManager.deleteEditor(editorId);
  });
  _.each(extraContentClasses, editor.addContentClass);
}

/**
 * @function _setupPhantomEditor
 * @description
 * A phantom editor is used on first render to determine the height
 * of the container (roughly) before the actual squire editor is rendered.
 *
 * This system avoids flying blocks on render.
 *
 * @param $element - The container element for this component.
 * @param componentData - The block component data used to render the HTML content.
 * @param theme - The current CSS theme to apply to the HTML content.
 */
function _setupPhantomEditor($element: JQuery, componentData: HTMLBlockComponent, theme: string | null) {
  const sizeClass = windowSizeBreakpointStore.getWindowSizeClass();
  const $phantomContainer = $('<div>', {
    class: `theme-${theme} ${sizeClass}`,
    style: `font-size: ${Constants.THEME_BASE_FONT_SIZE}`
  });

  const $phantomContent = $('<div>', {
    class: 'typeset squire-formatted'
  });

  $phantomContent.css({
    visibility: 'hidden',
    position: 'absolute'
  });

  $phantomContent.html(componentData.value);
  $phantomContainer.append($phantomContent);

  $element.append($phantomContainer);

  // Used as a signifier that the editor has loaded.
  $element.one('rich-text-editor::height-change', () => {
    $phantomContainer.remove();
  });
}

function _updateRichTextEditor($element: JQuery, componentData: HTMLBlockComponent, theme: string | null) {
  const editorId = $element.attr('data-editor-id');
  const editor = richTextEditorManager.getEditor(editorId);

  assertIsOneOfTypes(theme, 'string');
  assertHasProperty(componentData, 'value');

  assertIsNotNil(editor, `Cannot find the rich text editor associated with ${editorId}.`);

  assertIsNotNil(theme, 'Cannot find theme');

  editor.applyThemeAndLayoutClass(theme, storyStore.getStoryLayout(Environment.STORY_UID));
  editor.setContent(componentData.value);
}
