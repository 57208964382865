import _ from 'lodash';
import $ from 'jquery';

import { assert, assertHasProperty } from 'common/assertions';

import StorytellerUtils from 'lib/StorytellerUtils';
import './shared/componentBase';

/**
 * @function componentSpacer
 * @desc Renders out a <div.spacer>.
 * @param {object} componentData - An object with a type and value attribute
 * @returns {jQuery} - The rendered spacer jQuery element
 */
$.fn.componentSpacer = componentSpacer;

/*
  Component format:
  {
    type: 'spacer'
  }
*/

function componentSpacer(props) {
  const $this = $(this);
  const { componentData } = props;

  assertHasProperty(componentData, 'type');
  assert(
    componentData.type === 'spacer',
    `componentSpacer: Unsupported component type ${componentData.type}`
  );
  assert($this.length === 1, 'Selection must have exactly one element.');

  // We just add the classes to the `.component` div. No extra div.
  $this.empty().addClass(StorytellerUtils.typeToClassesForComponentType(componentData.type));

  return $this;
}
