import _ from 'lodash';
import $ from 'jquery';

import FeatureFlags from 'common/feature_flags';
import { BaseVisualization } from './types';

export const template = (): JQuery => {
  const classes = FeatureFlags.valueOrDefault('enable_global_filters_measures', false)
    ? 'socrata-visualization-actions-toolbar-container'
    : 'socrata-visualization-actions-toolbar-container pre-forge-measure-card';
  return $('<div>', { class: classes });
};

export const onUpdateVif = (self: BaseVisualization) => {
  self.$container.addClass('socrata-visualization-toolbar');
};
