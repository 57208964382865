import _ from 'lodash';
import BigNumber from 'bignumber.js';
import React, { Component } from 'react';

import I18n from 'common/i18n';
import FeatureFlags from 'common/feature_flags';

import { TargetTypes } from '../../lib/constants';
import { formatMeasureValue, formatTargetDate, getMostRecentTarget } from '../../lib/measureHelpers';
import { ViewColumn } from 'common/types/viewColumn';
import { Measure, Target } from 'common/performance_measures/types';
import { ForgeLabelValue } from '@tylertech/forge-react';

interface RecentTargetsInfoProps {
  calculationColumns?: ViewColumn[];
  date?: string;
  measure: Measure;
}

/**
 * Display information about the current and next targets.
 */
export class RecentTargetsInfo extends Component<RecentTargetsInfoProps> {
  /**
   * Format a target as a table row
   * @param target
   * @return a table row if there is a target, else null
   */
  renderTargetRow(target: Target) {
    if (!target) {
      return null;
    }

    const { calculationColumns, measure } = this.props;

    const targetTerminology = _.get(measure, 'metricConfig.display.targetTerminology');
    const label = targetTerminology || I18n.t('shared.performance_measures.measure.targets.target');

    const formattedValue = formatMeasureValue(
      new BigNumber(target.value ?? ''),
      measure,
      calculationColumns,
      false
    );

    if (!FeatureFlags.valueOrDefault('enable_global_filters_measures', false)) {
      return (
        <div className="measure-result-card-target-row">
          <div className="measure-result-card-target-label">{label}</div>
          <div className="measure-result-card-target-value">{formattedValue}</div>
          {target.type === TargetTypes.PERIODIC ? (
            <div className="measure-result-card-target-date">
              {formatTargetDate(this.props.measure, target)}
            </div>
          ) : null}
        </div>
      );
    }

    const valueText =
      target.type === TargetTypes.PERIODIC
        ? `${formattedValue} ${formatTargetDate(this.props.measure, target)}`
        : formattedValue;

    return (
      <ForgeLabelValue className="measure-result-card-target-row">
        <span slot="label" className="measure-result-card-target-label">
          {label}
        </span>
        <span slot="value" className="measure-result-card-target-value">
          {valueText}
        </span>
      </ForgeLabelValue>
    );
  }

  render() {
    const { measure, date } = this.props;
    const mostRecentTarget = getMostRecentTarget(measure, date);

    if (!mostRecentTarget) {
      return null;
    }

    return <div className="measure-result-card-targets">{this.renderTargetRow(mostRecentTarget)}</div>;
  }
}

export default RecentTargetsInfo;
