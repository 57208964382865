import _ from 'lodash';

import { ViewColumn } from 'common/types/viewColumn';

import { CalculationTypes } from './constants';
import { MetricConfig } from '../types';

export const isPercentColumn = (column: ViewColumn) => column?.format?.precisionStyle === 'percentage';

const getPercentScaleForColumn = (column: ViewColumn) => {
  return isPercentColumn(column) ? column?.format?.percentScale : null;
};

/**
 * Columns with percent formatting could be stored using a 0-1 scale or a 0-100 scale.
 * This function returns a number that can be multiplied against a measure calculation
 * in order to format it for display as percent.
 *
 * It is safe to call this against all measure and column types. It will return 1 (no-op)
 * if nothing needs to be done.
 *
 * @param {Object} calculationColumns metadata about columns involved in the measure calculation
 * @param {String} calculationType calculation type for the measure
 * @return {Number} number to multiply with the measure calculation in order to correctly display as a percent.
 */
export const getPercentScaleMultiplier = (
  calculationColumns: ViewColumn[] | undefined,
  calculationType: CalculationTypes
) => {
  const validCalculationTypes = _.without(
    _.values(CalculationTypes),
    CalculationTypes.RATE, // Rate calculations don't make sense with percent columns
    CalculationTypes.COUNT // A count of rows is never a percentage
  );

  if (_.isEmpty(calculationColumns) || !_.includes(validCalculationTypes, calculationType)) {
    return 1;
  }

  const percentScale = getPercentScaleForColumn(_.head(calculationColumns)!);
  return percentScale === '1' ? 100 : 1;
};

/**
 * Display a percent sign if the measure's calculation is configured to have a
 * percent sign (rate only) or if the column that the calculation is based on in
 * the underlying dataset is formatted as a percent (excludes rate).
 *
 * @param {Object} metricConfig
 * @param {Object} calculationColumns metadata about columns which the
 *                  the calculation is based on.
 * @return {bool} whether to append a percent sign to the value
 */
export const shouldUsePercentSign = (metricConfig: MetricConfig, calculationColumns?: ViewColumn[]) => {
  const calculationType = _.get(metricConfig, 'type');

  if (calculationType === CalculationTypes.COUNT) {
    // Count is the number of rows, so never use column formatting
    return false;
  }
  if (calculationType === CalculationTypes.RATE) {
    return _.get(metricConfig, 'display.asPercent');
  } else {
    const column = _.head(calculationColumns);
    return isPercentColumn(column!);
  }
};
