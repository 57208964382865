// Calculates measures via direct SoQL calls.
// Exports from this function are intended to be the module's public API.
// Internal helpers live in the sibling file `helpers.js`.
// We export the helpers to facilitate testing.

import _ from 'lodash';

import assertIsOneOfTypes from 'common/assertions/assertIsOneOfTypes';
import { UID_REGEX } from 'common/http/constants';

import * as ReportingPeriods from '../lib/reportingPeriods';
import { CalculationTypes } from '../lib/constants';

import { applyAdditionalFiltersToMeasure, getMeasureConfigurationErrors, getLastDataTime } from './helpers';

// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module './av... Remove this comment to see the full error message
import { calculateAverageMeasure } from './average';
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module './co... Remove this comment to see the full error message
import { calculateCountMeasure } from './count';
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module './ra... Remove this comment to see the full error message
import { calculateRateMeasure } from './rate';
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module './re... Remove this comment to see the full error message
import { calculateRecentValueMeasure } from './recent_value';
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module './su... Remove this comment to see the full error message
import { calculateSumMeasure } from './sum';
import { ComputedMeasureSeries, Measure, MeasureErrors, MetricSeriesResult } from '../types';
import DateRange from '../lib/dateRange';

function markFetchStart(markId: string) {
  if (markId && window.performance.mark) {
    window.performance.mark(`ReportingPeriodsCalc:${markId}:Start`);
  }
}

function markFetchFinish(markId: string) {
  if (window.performance.mark) {
    window.performance.mark(`ReportingPeriodsCalc:${markId}:Finish`);
    window.performance.measure(
      `ReportingPeriodsCalc:${markId}`,
      `ReportingPeriodsCalc:${markId}:Start`,
      `ReportingPeriodsCalc:${markId}:Finish`
    );

    performance.clearMarks(`ReportingPeriodsCalc:${markId}:Finish`);
    performance.clearMarks(`ReportingPeriodsCalc:${markId}:Start`);
    performance.clearMeasures(`ReportingPeriodsCalc:${markId}:Start`);
  }
}

// These helpers are used internally by other helpers
// as well as parts of the UI (outside this module). We
// re-export them here to maintain a clearer distinction
// between public API (this file) and private API.
export { measureArgumentNeedsNumericColumn, isColumnUsableWithMeasureArgument } from './helpers';

export const calculateMeasure = async (
  measure: Measure | null,
  dateRange: DateRange | null
): Promise<Omit<MetricSeriesResult, 'date'>> => {
  assertIsOneOfTypes(measure, 'object');
  // Our error detection logic needs to be very robust and be able to provide sensible guidance to the user
  // in common cases. The easiest way we came up with is to have an errors hash that we poke error states
  // into as we go along.
  const errors: MeasureErrors = {};

  const calculationType = _.get(measure, 'metricConfig.type');
  const dateColumn = _.get(measure, 'metricConfig.dateColumn');
  const reportingPeriod = _.get(measure, 'metricConfig.reportingPeriod');
  // Did the user even specify a reporting period?
  errors.noReportingPeriodConfigured = !ReportingPeriods.isFullyConfigured(reportingPeriod);
  // ... and if they did, does it give us a valid date range for today's value?
  errors.noReportingPeriodAvailable = !dateRange;

  errors.dataSourceNotConfigured = !measure?.dataSourceLensUid || !UID_REGEX.test(measure?.dataSourceLensUid);

  measure = applyAdditionalFiltersToMeasure(measure);

  // A blank dateRange can happen if:
  //   * The start date is in the future, or
  //   * The period type is "closed" and we haven't closed any reporting periods (i.e.,
  //     it's 1/1/2018, the period length is 1 year, and the first period started on 6/01/2017).
  // Account for measures with cumulative math enabled
  dateRange = dateRange ? dateRange.forCalculation(measure) : null;
  const dateRangeWhereClause = dateRange && dateColumn ? dateRange.asSoQL(dateColumn) : null;

  switch (calculationType) {
    case CalculationTypes.AVERAGE:
      return calculateAverageMeasure(errors, measure, dateRangeWhereClause);
    case CalculationTypes.COUNT:
      return calculateCountMeasure(errors, measure, dateRangeWhereClause);
    case CalculationTypes.SUM:
      return calculateSumMeasure(errors, measure, dateRangeWhereClause);
    case CalculationTypes.RECENT:
      return calculateRecentValueMeasure(errors, measure, dateRangeWhereClause);
    case CalculationTypes.RATE:
      return calculateRateMeasure(errors, measure, dateRangeWhereClause);
    case undefined:
      errors.calculationNotConfigured = true;
      return { errors };
    default:
      throw new Error(`Unknown calculation type: ${calculationType}`);
  }
};

/**
 * The getMetricSeries function returns the computed data for each reporting period given the
 * measure that is passed in to the function. This function is used for both the metric card
 * and the metric viz. Data that gets fetched back can be filtered and transformed into the
 * format that the metric viz expects.
 *
 * Returns an array of objects:
 * {
 *   date: String of the starting date of the reporting period
 *   result: {
 *     value: BigNumber Object
 *     numerator: BigNumber Object (Rate measures only)
 *     denominator: BigNumber Object (Rate measures only)
 *   },
 *   errors: {
 *    dividingByZero: Boolean (Rate measures only). Indicates the denominator is zero.
 *    dataSourceNotConfigured: Boolean. If set to true, indicates that the data source is not configured.
 *    noRecentValue: Boolean (Recent Value measures only). If set to true, the selected reference date column
 *     does not contain any non-null values.
 *    noReportingPeriodAvailable: Boolean. If set to true, indicates that no reporting period is usable
 *     (this can happen if the start date is in the future, or we're using closed reporting periods and
 *     no period has closed yet).
 *    noReportingPeriodConfigured: Boolean. If set to true, indicates that no reporting period was set
 *     by the user.
 *    calculationNotConfigured: Boolean. Indicates an insufficiently-specified calculation.
 *   }
 * }
 */
export const getMetricSeries = async (
  measure: Measure | null,
  { lastPeriodOnly = false } = {}
): Promise<{ errors?: MeasureErrors; series: MetricSeriesResult[] }> => {
  // When querying for data, also grab the last reporting data datetime. We will need
  // it for various edge cases later on.
  const lastDataMoment = await getLastDataTime(measure);
  const errors = getMeasureConfigurationErrors(measure, lastDataMoment);

  if (Object.keys(errors).length > 0) {
    // There's not enough information to calculate this measure.
    return { errors, series: [] };
  }

  const reportingPeriodConfig = _.get(measure, 'metricConfig.reportingPeriod');
  let reportingPeriods = ReportingPeriods.getSeries(reportingPeriodConfig, lastDataMoment);

  if (lastPeriodOnly) {
    reportingPeriods = _.takeRight(reportingPeriods); // eslint-disable-line require-atomic-updates
  }

  const calculationPromises = reportingPeriods.map((dateRange) => calculateMeasure(measure, dateRange));

  const markId = _.uniqueId();
  markFetchStart(markId);
  const measureResultsPerReportingPeriod = await Promise.all(calculationPromises);
  markFetchFinish(markId);

  return {
    series: measureResultsPerReportingPeriod.map((measureResult, i) => {
      const startDate = reportingPeriods[i].start.format('YYYY-MM-DDTHH:mm:ss.SSS');
      // Must always return null for a value that cant be calculated since charts dont know how to deal with
      // other nil-y values like undefined
      const result = _.get(measureResult, 'result.value', null);
      const errorsForResult = _.get(measureResult, 'errors', null);

      // This should be refactored at some point
      const denominator = _.get(measureResult, 'result.denominator', null);
      const numerator = _.get(measureResult, 'result.numerator', null);

      return {
        date: startDate,
        result: {
          value: result,
          numerator,
          denominator
        },
        errors: errorsForResult
      };
    })
  };
};

/**
 * Filters out null value and any bigNumber object that does not have a finite value
 * @returns MetricSeriesResult[]
 */
export const getNonNullResults = (metricSeriesResult: MetricSeriesResult[]): MetricSeriesResult[] => {
  const nonNullData = _.filter(metricSeriesResult, (data: MetricSeriesResult) => {
    return !!data.result?.value?.isFinite();
  });

  return nonNullData;
};

/**
 * Transforms the results from getMetricSeries into what the metric viz is expecting.
 */
export const toVizData = (metricSeriesResult: MetricSeriesResult[]): ComputedMeasureSeries =>
  metricSeriesResult.map(({ date, result }) => [date, result?.value]);
