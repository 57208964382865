import { uniqBy, get } from 'lodash';
import { GridApi, ColDef } from '@ag-grid-community/core';
import { ExtraOptions, GridDataProcessor, GridDataProcessorResult } from '../types';
import { ExportData } from 'common/visualizations/views/agGridReact/types';
import { OrderConfig } from 'common/visualizations/vif';
import { GROUP_COLUMN_PREFIX } from 'common/visualizations/views/agGridReact/Constants';
import DataTypeFormatter from 'common/DataTypeFormatter';

import { NormalRow } from './type';
import { parseRows } from './gridDataParser';
import { groupRows } from './gridDataGrouper';
import { formatRows } from './gridDataFormatter';
import { buildTotalsRow } from './gridDataAggregator';
import { TableColumnFormat } from 'common/authoring_workflow/reducers/types';
import { ViewColumn } from 'common/types/viewColumn';

// Note: Retrieveing and grouping/formating data can also be moved to the worker for efficiency.
// Currently, we retrieve 10K records, format/group them in main thread.
// Serialize and send it to worker thread. It again deserializes it (JSON) which is a waste.
// export class GridDataProcessor implements IGridDataProcessor {
export class AgGridDataProcessor implements GridDataProcessor {
  async processGridData(
    gridApi: GridApi,
    getExportData: (selectedFiltered: boolean) => Promise<ExportData>,
    extraOptions: ExtraOptions
  ): Promise<GridDataProcessorResult> {
    const { vifOrderConfig, showTotal, showSubTotal } = extraOptions;
    const exportData = await getExportData(true);

    // Get visible columns and their order, excluding group column
    let visibleColumnIds = gridApi
      .getAllDisplayedColumns()
      .map((col) => col.getColId())
      .filter((id) => !id.startsWith(GROUP_COLUMN_PREFIX));
    // Get row grouping configuration
    const rowGroupCols = gridApi.getRowGroupColumns();
    const groupedFields = rowGroupCols.map((col) => col.getColId());
    visibleColumnIds = groupedFields.concat(visibleColumnIds);

    // Get column definitions with grouping info
    const columnDefs = this.processColumnDefs(gridApi, exportData, visibleColumnIds, extraOptions);

    // Process formatters
    const formatters = this.processFormatters(gridApi, exportData);

    const sortDefs = uniqBy(
      vifOrderConfig.map((orderConfig: OrderConfig) => ({
        ...orderConfig,
        columnName: orderConfig.columnName.replace(`${GROUP_COLUMN_PREFIX}-`, '')
      })),
      'columnName'
    );

    const parsedRows = parseRows(exportData.rows as NormalRow[], columnDefs);
    const rowData = groupRows(parsedRows, groupedFields, columnDefs, sortDefs, showSubTotal);
    const formattedRowData = formatRows({
      rowData: showTotal ? [...rowData, buildTotalsRow(rowData, columnDefs)] : rowData,
      gridApi,
      extraOptions,
      columns: exportData.columns.filter((col) => visibleColumnIds.includes(col.fieldName))
    });

    // Grid mapping with display configuration
    const gridMapping = {
      defaultHeaderHeight: Number(gridApi.getGridOption('headerHeight')) || 10,
      defaultRowHeight: Number(gridApi.getGridOption('rowHeight')) || 10,
      defaultGroupHeight: Number(gridApi.getGridOption('groupHeaderHeight')) || 10,
      groupedColumns: groupedFields,
      aggregatedColumns: this.getAggregatedColumns(columnDefs),
      visibleColumns: visibleColumnIds,
      groupedFields
    };

    return {
      columnDefs,
      formatters,
      rowData: formattedRowData,
      gridMapping,
      processedRowCount: exportData.rows.length
    };
  }

  private processColumnDefs(
    gridApi: GridApi,
    exportData: ExportData,
    visibleColumnIds: string[],
    extraOptions: ExtraOptions
  ): ColDef[] {
    const { columnFormats } = extraOptions;

    return exportData.columns
      .filter((col: any) => visibleColumnIds.includes(col.fieldName))
      .map((col: any) => {
        const gridCol = gridApi.getColumn(col.fieldName);
        const colDef = gridCol?.getColDef();
        const columnFormat = columnFormats[col.fieldName];

        return {
          cellStyle: this.getCellStyleForColumn(col, columnFormat),
          field: col.fieldName,
          headerName: get(columnFormat, 'displayName') || col.name,
          index: visibleColumnIds.indexOf(col.fieldName),
          width: gridCol?.getActualWidth(),
          rowGroup: colDef?.rowGroup || false,
          hide: !gridCol?.isVisible(),
          aggFunc: colDef?.aggFunc ? String(colDef.aggFunc) : undefined,
          type: col?.dataTypeName,
          valueFormatter: colDef?.valueFormatter ? colDef.valueFormatter.toString() : undefined
        };
      })
      .sort((a, b) => a.index - b.index);
  }

  private createAggregatedValues(rows: any[], aggregatedFields: string[]): any {
    const aggregatedValues: any = {};

    aggregatedFields.forEach((field) => {
      // Sum numerical values for the aggregated field
      const sum = rows.reduce((total, row) => {
        const value = row[field];
        const numValue = typeof value === 'number' ? value : parseFloat(value);
        return total + (isNaN(numValue) ? 0 : numValue);
      }, 0);

      aggregatedValues[field] = sum;
    });

    return aggregatedValues;
  }

  private getAggregatedColumns(columnDefs: ColDef[]): string[] {
    return columnDefs
      .filter((col) => col.aggFunc)
      .map((col) => col.field!)
      .filter((field) => field); // Remove any undefined fields
  }

  private getCellStyleForColumn = (column: ViewColumn, columnFormat: TableColumnFormat) => {
    const textAlign = DataTypeFormatter.getCellAlignment(column);
    const align = get(columnFormat, 'format.align');
    const isAlign = get(columnFormat, 'isFormatValue', true);

    return {
      align: isAlign ? align || textAlign : textAlign
    };
  };

  private processFormatters(gridApi: GridApi, exportData: ExportData): Record<string, any> {
    const formatters: Record<string, any> = {};
    exportData.columns.forEach((col) => {
      const colDef = gridApi.getColumn(col.fieldName)?.getColDef();
      formatters[col.fieldName] = {
        headerName: col.name,
        width: gridApi.getColumn(col.fieldName)?.getActualWidth(),
        type: col.dataTypeName,
        format: col.format || null,
        valueFormatter: colDef?.valueFormatter?.toString(),
        groupFormatter: colDef?.cellRendererParams?.innerRenderer?.toString(),
        aggFunc: colDef?.aggFunc ? String(colDef.aggFunc) : undefined
      };
    });
    return formatters;
  }
}
