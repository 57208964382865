import airbrake from 'common/airbrake';
import { MODES } from 'common/components/AccessManager/Constants';
import I18n from 'common/i18n';

import type { AccessManagerUiState, ApprovalOutcomeMessages } from '../types';
import type UiActions from '../actions/UiActionType';
import type PermissionsActions from '../actions/PermissionsActionType';
import type { FederatedSite } from 'common/types/approvals';

import { UiActionTypes } from 'common/components/AccessManager/actions/UiActions';
import { PermissionsActionsTypes } from 'common/components/AccessManager/actions/PermissionsActions';
import { AudienceScope } from 'common/types/view';
import { getHaveUsersChanged } from '../sagas/Selectors';

// see the saga for the calls this action actually does
const saveButtonClicked = (state: AccessManagerUiState): AccessManagerUiState => ({
  ...state,
  footer: {
    ...state.footer,
    confirmButtonBusy: true
  },
  errors: []
});

const setConfirmButtonDisabled = (
  state: AccessManagerUiState,
  confirmButtonDisabled: boolean
): AccessManagerUiState => ({
  ...state,
  footer: {
    ...state.footer,
    confirmButtonDisabled
  }
});

// called by the AccessManagerSaga when the api call fails
const saveFail = (state: AccessManagerUiState, error: any): AccessManagerUiState => {
  airbrake.notify({
    error,
    context: { component: 'UiReducer saveFail' }
  });
  return {
    ...state,
    footer: {
      ...state.footer,
      confirmButtonBusy: false
    },
    errors: [...state.errors, error],
    errorIntro: I18n.t('shared.site_chrome.access_manager.manage_collaborators.save_failed')
  };
};

const fetchPermissionsSuccess = (state: AccessManagerUiState) => {
  return {
    ...state,
    footer: {
      ...state.footer,
      confirmButtonBusy: false
    }
  };
};

// called by AccessManagerSaga when fetching permissions fails
const fetchPermissionsFail = (state: AccessManagerUiState, error: any): AccessManagerUiState => {
  airbrake.notify({
    error: error,
    context: { component: 'fetchPermissionsFail' }
  });
  return {
    ...state,
    footer: {
      ...state.footer,
      confirmButtonBusy: false
    },
    errors: [...state.errors, error],
    errorIntro: I18n.t('shared.site_chrome.access_manager.manage_collaborators.save_failed')
  };
};

/*
This has the ability to toggle the Save button in one of 4 ways:
1. as a disabled Save button
2. as a disabled Save button with a message about auto-rejection
3. as an enabled Save button
4. as a Submit for Approval button with a message about approval needed.
 */
const toggleSaveButton = (
  state: AccessManagerUiState,
  {
    outcomes,
    sitesFederatedToIfMadePublic,
    confirmButtonDisabled
  }: {
    outcomes: ApprovalOutcomeMessages;
    sitesFederatedToIfMadePublic: FederatedSite[];
    confirmButtonDisabled: boolean;
  }
): AccessManagerUiState => {
  return {
    ...state,
    showApprovalMessage: outcomes.showApprovalMessage,
    showRejectedMessage: outcomes.showRejectedMessage,
    sitesFederatedToIfMadePublic,
    footer: {
      ...state.footer,
      confirmButtonDisabled,
      confirmButtonBusy: false
    }
  };
};

const changeAudienceScope = (state: AccessManagerUiState, scope: AudienceScope) => {
  return {
    ...state,
    showApprovalMessage: false,
    showRejectedMessage: false,
    targetAudience: scope,
    footer: {
      ...state.footer,

      // we disable the confirm button until we know if the asset will go
      // through approvals or not (see UiSagas)
      confirmButtonDisabled: scope === AudienceScope.Public || scope === AudienceScope.Site,
      confirmButtonBusy: scope === AudienceScope.Public || scope === AudienceScope.Site
    }
  };
};

const switchModes = (state: AccessManagerUiState): AccessManagerUiState => ({
  ...state,
  modalTransition: 'out'
});

const didSwitchModes = (state: AccessManagerUiState, mode: MODES): AccessManagerUiState => ({
  ...state,
  modalTransition: 'in',
  mode
});

const getScheduleCount = (state: AccessManagerUiState) => {
  return {
    ...state,
    errors: []
  };
};

const getScheduleCountFail = (state: AccessManagerUiState, error: any) => {
  airbrake.notify({
    error: error,
    context: { component: 'getScheduleCountFail' }
  });
  return {
    ...state,
    errors: [...state.errors, error],
    errorIntro: I18n.t('schedules_error', {
      scope: 'shared.site_chrome.access_manager.access_removal_confirmation'
    })
  };
};

export default (
  state: AccessManagerUiState = {} as any,
  action: UiActions | PermissionsActions
): AccessManagerUiState => {
  switch (action.type) {
    // uiActions
    case UiActionTypes.SAVE_BUTTON_CLICKED:
      return saveButtonClicked(state);
    case UiActionTypes.SET_CONFIRM_BUTTON_DISABLED:
      return setConfirmButtonDisabled(state, action.payload.disabled);
    case UiActionTypes.TOGGLE_SAVE_BUTTON:
      return toggleSaveButton(state, action.payload);
    case UiActionTypes.SWITCH_MODES:
      return switchModes(state);
    case UiActionTypes.DID_SWITCH_MODES:
      return didSwitchModes(state, action.payload.newMode);

    // permissionsActions
    case PermissionsActionsTypes.SAVE_FAIL:
      return saveFail(state, action.payload.error);
    case PermissionsActionsTypes.FETCH_PERMISSIONS_SUCCESS:
      return fetchPermissionsSuccess(state);
    case PermissionsActionsTypes.FETCH_PERMISSIONS_FAIL:
      return fetchPermissionsFail(state, action.payload.error);
    case PermissionsActionsTypes.CHANGE_AUDIENCE_SCOPE:
      return changeAudienceScope(state, action.payload.scope);
    case PermissionsActionsTypes.GET_SCHEDULE_COUNT:
      return getScheduleCount(state);
    case PermissionsActionsTypes.GET_SCHEDULE_COUNT_FAIL:
      return getScheduleCountFail(state, action.payload.error);

    default:
      return state;
  }
};
