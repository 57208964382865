import { FunCall } from 'common/types/soql';
import { translateFunCall, translateFunCallByName } from '../../lib/soql-helpers';
import * as _ from 'lodash';
import React from 'react';
import { ForgeButton, ForgeIcon } from '@tylertech/forge-react';

interface Props {
  fun: FunCall | string | null;
  className?: string;
  defaultContent?: string;
  // formatFunctionName will only be called if prop 'fun' is a function
  formatFunctionName?: (functionName: string, translatedName: string) => string;
  testId?: string;
}

const FunctionBadge = (props: Props) => {
  let content;

  if (props.fun) {
    if (_.isString(props.fun)) {
      content = translateFunCallByName(props.fun);
    } else {
      if (props.formatFunctionName) {
        content = props.formatFunctionName(props.fun.function_name, translateFunCall(props.fun));
      } else {
        content = translateFunCall(props.fun);
      }
    }
  } else {
    content = props.defaultContent;
  }

  return (
    <ForgeButton type="flat">
      <button type="button" data-testid={props.testId ? props.testId : 'function-badge-button'}>
        <span>{content}</span>
        <ForgeIcon name="keyboard_arrow_down" />
      </button>
    </ForgeButton>
  );
};

export default FunctionBadge;
