import _ from 'lodash';
import React, { ReactElement } from 'react';

import I18n from 'common/i18n';

import { TimelineScopes } from '../../lib/constants';
import { allowTimelineScopeCurrent, hasMeasureEnded } from '../../lib/measureHelpers';

import { Measure } from '../../types';

import './timeline-scope-chooser.scss';

interface TimelineScopeChooserProps {
  measure: Measure;
  timelineScope: TimelineScopes;
  setTimelineScope: (timelineScope: TimelineScopes) => void;
}

const translationScope = 'shared.performance_measures.chart';
const TimelineScopeChooser = ({ measure, timelineScope, setTimelineScope }: TimelineScopeChooserProps) => {
  const scopeOption = (scope: TimelineScopes, text: string): ReactElement =>
    scope === timelineScope ? (
      <span>{text}</span>
    ) : (
      <a
        href="#"
        className={`scope-${scope}`}
        onClick={(e) => {
          setTimelineScope(scope);
          e.preventDefault();
        }}
      >
        {text}
      </a>
    );

  if (!measure || !allowTimelineScopeCurrent(measure)) {
    return null;
  }

  return (
    <div className="measure-chart-scope-selector">
      {scopeOption(TimelineScopes.ALL, I18n.t('all_periods', { scope: translationScope }))}
      {scopeOption(
        TimelineScopes.CURRENT,
        hasMeasureEnded(measure)
          ? I18n.t('last_period', { scope: translationScope })
          : I18n.t('current_period', { scope: translationScope })
      )}
    </div>
  );
};

export default TimelineScopeChooser;
