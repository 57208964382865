import _ from 'lodash';

import RenderByHelper from 'common/visualizations/helpers/RenderByHelper';
import SoqlHelpers from 'common/visualizations/dataProviders/SoqlHelpers';
import { getTileUrl, escapeGeoColumn } from 'common/visualizations/helpers/MapHelper';

import { getBasemapLayerStyles } from '../basemapStyle';
import Clusters, { LAYERS as CLUSTER_LAYERS } from './partials/Clusters';
import PartialWrapper from './partials/PartialWrapper';
import PointsAndStacks, { LAYERS as POINT_AND_STACK_LAYERS } from './partials/PointsAndStacks';

const COLOR_BY_CATEGORY_ALIAS = '__color_by_category__';
const COUNT_ALIAS = '__count__';
export const RESIZE_BY_ALIAS = '__resize_by__';
const ROWID_ALIAS = '__row_id__';
export const OTHER_COLOR_BY_CATEGORY = '__$$other$$__';

// Prepares renderOptions and renders partials.
// Refer: common/visualizations/views/map/README.md
export default class VifPointOverlay {
  constructor(map, mouseInteractionHandler) {
    const seriesId = _.uniqueId();

    this._pointsAndStacks = new PartialWrapper(map, new PointsAndStacks(map, seriesId));
    this._clusters = new PartialWrapper(map, new Clusters(map, seriesId));
    this._mouseInteractionHandler = mouseInteractionHandler;
  }

  // Renders the partials required for this overlay and sets up required mouse interactions.
  // vif            : <object> vif got from AX/visualization
  // renderOptions  : <object> got from this overlays prepare method
  // overlayOptions : object
  // overlayOptions.renderLayersBefore : <string> renders all mapbox-gl-layers for this overlay
  //                                      before given layer-id
  // overlayOptions.hasMultiplePointMapSeries : <boolean> it's true hide stackCount label and
  //                                           add spiderify for points.false unhide stackCount label.
  // overlayOptions.stackSize : <number> renders all stack for this size.
  render(vif, renderOptions, overlayOptions) {
    this._pointsAndStacks.render(vif, renderOptions, overlayOptions);
    this._clusters.render(vif, renderOptions, overlayOptions);

    this._mouseInteractionHandler.register(
      this._pointsAndStacks.getLayerId(POINT_AND_STACK_LAYERS.POINTS_CIRCLE),
      vif,
      renderOptions,
      {
        highlight: true,
        popupOnMouseOver: !_.isEmpty(vif.getAllFlyoutColumns()),
        shouldSpiderfyOnClick: true,
        renderFeatureInSpiderLeg: this._pointsAndStacks.renderFeatureInSpiderLeg,
        hidePointsWithId: this._pointsAndStacks.hidePointsWithId,
        unhidePoints: this._pointsAndStacks.unhidePoints,
        overlayOptions
      }
    );

    this._mouseInteractionHandler.register(
      this._pointsAndStacks.getLayerId(POINT_AND_STACK_LAYERS.STACKS_CIRCLE),
      vif,
      renderOptions,
      {
        popupOnMouseOver: true,
        shouldSpiderfyOnClick: true,
        renderFeatureInSpiderLeg: this._pointsAndStacks.renderFeatureInSpiderLeg,
        overlayOptions
      }
    );

    this._mouseInteractionHandler.register(
      this._clusters.getLayerId(CLUSTER_LAYERS.CLUSTERS_CIRCLE),
      vif,
      renderOptions,
      {
        shouldZoomOnClick: true
      }
    );

    return renderOptions;
  }

  // Makes required soql calls
  //    * getting top values for coloring by.
  //    * getting range for resizePointsBy buckets.
  // and returns the renderOptions.
  async prepare(vif, metaDataPromise) {
    let getResizeByRange = Promise.resolve(null);
    const colorByColumn = vif.getColorPointsByColumn();
    const resizeByColumn = vif.getResizePointsByColumn();

    if (!_.isNil(resizeByColumn)) {
      getResizeByRange = RenderByHelper.getResizeByRange(vif, resizeByColumn);
    }

    const [colorByBuckets, resizeByRange, datasetMetadata] = await Promise.all([
      RenderByHelper.getColorByBuckets(vif, colorByColumn, null, metaDataPromise, vif.isCustomColorPalette()),
      getResizeByRange,
      metaDataPromise
    ]);

    return {
      aggregateAndResizeBy: resizeBy(vif),
      colorBy: COLOR_BY_CATEGORY_ALIAS,
      colorByBuckets,
      countBy: COUNT_ALIAS,
      datasetMetadata,
      dataUrl: this._getDataUrl(vif, colorByBuckets, datasetMetadata),
      idBy: ROWID_ALIAS,
      layerStyles: getBasemapLayerStyles(vif),
      legendItems: colorByBuckets,
      resizeByRange
    };
  }

  destroy() {
    this._pointsAndStacks.destroy();
    this._mouseInteractionHandler.unregister(
      this._pointsAndStacks.getLayerId(POINT_AND_STACK_LAYERS.STACKS_CIRCLE)
    );
    this._mouseInteractionHandler.unregister(
      this._pointsAndStacks.getLayerId(POINT_AND_STACK_LAYERS.POINTS_CIRCLE)
    );
    this._clusters.destroy();
    this._mouseInteractionHandler.unregister(this._clusters.getLayerId(CLUSTER_LAYERS.CLUSTERS_CIRCLE));
  }

  _getDataUrl(vif, colorByBuckets, datasetMetadata) {
    const colorByColumn = vif.getColorPointsByColumn();
    const filters = SoqlHelpers.whereClauseFilteringOwnColumn(vif, 0);
    const resizeByColumn = vif.getResizePointsByColumn();
    const escapedColumnName = escapeGeoColumn(vif.getColumnName(), datasetMetadata);
    const snappedLocationColumn = `snap_to_grid(${escapedColumnName},{snap_precision})`;

    let conditions = [`{{'${escapedColumnName}' column condition}}`];

    if (!_.isEmpty(filters)) {
      conditions.push(filters);
    }

    let selects = [snappedLocationColumn, `min(:id) as ${ROWID_ALIAS}`];
    let groups = [snappedLocationColumn];

    if (_.isString(colorByColumn) && !_.isEmpty(colorByBuckets)) {
      // We are not grouping by colorByColumn. In case that column had 10K unique values,
      // then grouping by the colorByColumn and snapToGrid, will return
      // 10K * snappedToGrid location => number of results. Which will be too much.
      // Instead, we are only interesed in the top x values(colorByCategories) in the colorbyColumn.
      // So we select/group the remaining values as OTHER_COLOR_BY_CATEGORY and the top x in separate groups.

      // We are concatenating empty string to the resizeBy column to convert it to string.
      // Otherwise, depending on whether it is a numeric column or string column, we need to
      // use quotes around values(colorByCategories value) in case statement.
      selects.push(`min(${SoqlHelpers.escapeColumnName(colorByColumn)}||'') as ${COLOR_BY_CATEGORY_ALIAS}`);
    }

    if (_.isString(resizeByColumn)) {
      selects.push(`sum(${SoqlHelpers.escapeColumnName(resizeByColumn)}) as ${RESIZE_BY_ALIAS}`);
    }
    selects.push(`count(*) as ${COUNT_ALIAS}`);

    const query =
      `select ${selects.join(',')} ` +
      `where ${conditions.join(' AND ')} ` +
      `group by ${groups.join(',')} ` +
      'limit 50000 ';

    return getTileUrl(vif.getDomain(), vif.getDatasetUid(), query, vif.getSimplificationLevel());
  }
}

function resizeBy(vif) {
  const resizeByColumn = vif.getResizePointsByColumn();
  if (_.isString(resizeByColumn)) {
    return RESIZE_BY_ALIAS;
  }
  return COUNT_ALIAS;
}
