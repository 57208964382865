// Vendor Imports
import _ from 'lodash';

// Project Imports
import { CALENDAR_DATA_RETRIEVAL_DAY_PADDING } from 'common/visualizations/views/SvgConstants';
import { DATE_FORMAT } from 'common/dates';
import I18n from 'common/i18n';

export const getDateRangeCondition = (calendarDate, startDateColumn, endDateColumn) => {
  const newCalendarDate = _.cloneDeep(calendarDate);
  // We also show last few days from the previous month and the first few days from the next month.
  // So we are fetching data for the current month plus a few days from last and next month.
  const dateRange = [
    calendarDate.
      startOf('month').
      subtract(CALENDAR_DATA_RETRIEVAL_DAY_PADDING, 'days').
      format(DATE_FORMAT),
    newCalendarDate.
      endOf('month').
      add(CALENDAR_DATA_RETRIEVAL_DAY_PADDING, 'days').
      format(DATE_FORMAT)
  ];
  let dateRangeConditions = [
    `(\`${startDateColumn}\` >= '${dateRange[0]}' AND \`${startDateColumn}\` <= '${dateRange[1]}')`
  ];

  if (!_.isEmpty(endDateColumn)) {
    dateRangeConditions = dateRangeConditions.concat([
      `(\`${endDateColumn}\` >= '${dateRange[0]}' AND \`${endDateColumn}\` <= '${dateRange[1]}')`,
      `(\`${startDateColumn}\` < '${dateRange[0]}' AND \`${endDateColumn}\` > '${dateRange[1]}')`,
    ]);
  }
  return `(${dateRangeConditions.join(' OR ')})`;
};

// Arguments:
//   dataToRender: <object>
//     {
//       columns: ['url_column', 'test1'],
//       columnFormats: {
//         url_column: {
//           "name": "Url Column",
//           "dataTypeName": "url",
//           "fieldName": "url_column",
//           "renderTypeName": "url"
//         },
//         ....
//       },
//       rows: [
//         ["https://test.com", '2019-01-01T09:25:14.000']
//       ]
//     };
export const isEventTitleOfUrlColumnType = (dataToRender) => {
  const titleColumnName = _.get(dataToRender, 'columns[0]');

  return isUrlColumnType(titleColumnName, dataToRender.columnFormats);
};

export const isUrlColumnType = (columnName, columnFormats) => {
  const columnFormat = _.find(columnFormats, ['fieldName', columnName]);
  const columnType = _.get(columnFormat, 'dataTypeName');
  const columnDisplayStyle = _.get(columnFormat, 'format.displayStyle', '');

  return (columnType === 'url') || (columnDisplayStyle === 'url');
};

export const getEventTitle = (dataToRender, row) => {
  const noValueLabel = I18n.t('shared.visualizations.charts.common.no_value');
  const titleColumnName = _.get(dataToRender, 'columns[0]');
  const defaultTitleValue = _.isEmpty(titleColumnName) ? '' : noValueLabel;
  const titleValue = _.get(row, '0', defaultTitleValue);

  if (isUrlColumnType(titleColumnName, dataToRender.columnFormats)) {
    const url = _.isString(titleValue) ? titleValue : _.get(titleValue, 'url');
    return addHttp(url);
  } else {
    return titleValue;
  }
};

export function addHttp(domain) {
  if (!_.isEmpty(domain) && !/^https?:\/\//i.test(domain)) {
    return '//' + domain;
  } else {
    return domain;
  }
}
