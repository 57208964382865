import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import set from 'lodash/set';
import { migrateInSituTables } from 'common/visualizations/helpers/VifHelpers';
import { migrateVif } from 'common/visualizations/helpers/migrateVif';
import { StoryData } from 'types';

// In the future, add new migrations to a function, and then call that function here.
/**
 * Migrate a storyData object to the latest version of story JSON. Note that this is executed before all other code
 * in both edit and view mode. If your migration will cause a visual or interaction change to the story, please confirm
 * that it's expected for that migration to take effect without users editing their story.
 * @param storyData This will be mutated with the new storyData
 */
export default async function migrateStoryData(storyData: StoryData) {
  const { blocks } = storyData;

  for (const block of blocks) {
    const { components } = block;

    for (const component of components) {
      if (component.value?.vif) {
        // migrate all vifs
        const originalVif = component.value.vif;
        let migratedVif = migrateVif(cloneDeep(originalVif));
        const vifType = get(migratedVif, 'series[0].type');

        if (vifType === 'table') {
          // migrate table vif to be compatible with agTable
          migratedVif = await migrateInSituTables(migratedVif);

          set(component, 'type', 'socrata.visualization.agTable');
        }

        set(component, 'value.vif', migratedVif);
      }
    }
  }

  Promise.resolve();
}
