import { Expr, SoQLType, Scope, UnAnalyzedAst, BinaryTree } from 'common/types/soql';
import SoqlDataProvider from 'common/visualizations/dataProviders/SoqlDataProvider';
import { getSuggestions, Suggester } from '../../lib/data-helpers';
import { getUnAnalyzedAst } from '../../lib/selectors';
import { AppState } from '../../redux/store';
import _ from 'lodash';
import { default as React } from 'react';
import { connect } from 'react-redux';
import { none, Option } from 'ts-option';
import { PhxChannel } from 'common/types/dsmapi';
import { ForgeAutocomplete, ForgeChipField, ForgeChip, ForgeIcon } from '@tylertech/forge-react';
import { IAutocompleteOption } from '@tylertech/forge';
import { fetchTranslation } from 'common/locale';

const t = (k: string) => fetchTranslation(k, 'shared.explore_grid.visual_nodes');

type ExternalProps = {
  update: (newVals: string[]) => void;
  suggestionTarget: Expr;
  soqlT: Option<SoQLType>;
  literals: string[];
  scope: Scope;
};

type Props = ExternalProps & {
  fourfour: string;
  tree: Option<BinaryTree<UnAnalyzedAst>>;
  channel: PhxChannel;
};
class VariadicMultiSelect extends React.Component<Props> {
  suggester: Option<Suggester> = none;

  constructor(props: Props) {
    super(props);
    const { fourfour, scope, channel, suggestionTarget } = props;

    const provider = new SoqlDataProvider({
      domain: window.location.host,
      datasetUid: fourfour,
    });

    this.suggester = props.tree.map(tree => {
      return getSuggestions(provider, suggestionTarget, scope, tree, channel);
    });
  }

  onAdd = (literal: string) => {
    this.props.update(_.uniq([
      ...this.props.literals,
      literal
    ]));
  };

  onRemove = (literal?: string) => {
    if (literal) {
      this.props.update(this.props.literals.filter(l => l !== literal));
    }
  };

  fetchSuggestions = (filterText: string): Promise<IAutocompleteOption<string>[]> => {
    const noResults: IAutocompleteOption[] = [{
      label: t('no_results'),
      value: null,
      disabled: true
    }];

    return this.suggester
      .map(suggest =>
        suggest(filterText, 0, true)
          .then(
            suggestions => {
              const options = suggestions.results.map(res => ({ label: res.title, value: res.title }));
              return options.length === 0 ? noResults : options;
        })
      )
      .getOrElseValue(Promise.resolve([]));
  };

  multiSelect() {
    return (
      <div id="dsmui-tags-input" className='multi-select-visual-node'>
        <ForgeAutocomplete
          mode="stateless"
          filter={this.fetchSuggestions}
          on-forge-autocomplete-select={(evt: CustomEvent) => {
            this.onAdd(evt.detail.value);
          }}
          on-forge-chip-field-member-added={(evt: CustomEvent) => {
            this.onAdd(evt.detail);
          }}
          filterOnFocus={true}
          allowUnmatched={true}
          filterFocusFirst={false}
        >
          <ForgeChipField className='multi-select-chip-field'>
            {this.props.literals.map((chipTag: string) => {
              return (
                <ForgeChip
                  key={chipTag}
                  slot='member'
                  type='field'
                  dense
                  on-forge-chip-delete={(evt: CustomEvent) => this.onRemove(evt.detail.value)}
                  value={chipTag}
                >
                  {chipTag}
                </ForgeChip>
              );
            })}
            <ForgeIcon slot='leading' name='search' />
            <label slot='label' htmlFor='chip-field-input'>{t('search')}</label>
            <input autoComplete='off' type='text' id='chip-field-input' />
            <ForgeIcon slot="trailing" data-forge-dropdown-icon name="arrow_drop_down" />
          </ForgeChipField>
        </ForgeAutocomplete>
      </div>
    );
  }


  render() {
    return (
      <div className="function-arg variadic-multi-select" data-testid="variadic-multi-select">
        <div className="literal string-literal-with-suggestions">
          {this.multiSelect()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState, props: ExternalProps): Props => {

  return {
    ...props,
    fourfour: state.fourfourToQuery,
    tree: getUnAnalyzedAst(state.query),
    channel: state.channel
  };
};

export default connect(mapStateToProps)(VariadicMultiSelect);
