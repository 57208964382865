import { Option } from 'ts-option'; // Only imported for factorOption.

/**
 * This design for Either has left/right neutrality.
 *
 * Most Either designs assume a rightward bias (i.e., they assume Either<Error, Result> is what you intend.), but that
 * isn't the use case that this was created for. This was created in order to hold onto two different types at the same
 * time, handling both versions of an implementation.
 *
 * This is heavily inspired by https://github.com/shogogg/ts-option/blob/master/index.ts
 */

interface EitherLike<Left, Right> {
  bimap<B, C> (fl: (_: Left) => B, fr: (_: Right) => C): Either<B, C>; // Name comes from Haskell.
  mapBoth<B, C> (fl: (_: Left) => B, fr: (_: Right) => C): Either<B, C>;
  map<B, C> (fl: (_: Left) => B, fr: (_: Right) => C): Either<B, C>;

  mapRight<B> (f: (_: Right) => B): Either<Left, B>;
  mapLeft<B> (f: (_: Left) => B): Either<B, Right>;

  fold<C> (fl: (_: Left) => C, fr: (_: Right) => C): C; // Implementation inspired by Scala.
  foldEither<C> (f: (_: Left | Right) => C): C;

  readonly isLeft: boolean;
  readonly isRight: boolean;
  readonly left: Left;
  readonly right: Right;
  readonly get: Left | Right;
}

export abstract class Either<Left, Right> implements EitherLike<Left, Right> {
  abstract mapRight<B> (f: (_: Right) => B): Either<Left, B>;
  abstract mapLeft<B> (f: (_: Left) => B): Either<B, Right>;
  abstract bimap<B, C> (fl: (_: Left) => B, fr: (_: Right) => C): Either<B, C>;
  mapBoth<B, C> (fl: (_: Left) => B, fr: (_: Right) => C): Either<B, C> {
    return this.bimap(fl, fr); // Just an alias.
  }
  map<B, C> (fl: (_: Left) => B, fr: (_: Right) => C): Either<B, C> {
    return this.bimap(fl, fr); // Just an alias.
  }

  abstract fold<C> (fl: (_: Left) => C, fr: (_: Right) => C): C;
  abstract foldEither<C> (f: (_: Left | Right) => C): C;

  abstract get isLeft(): boolean;
  abstract get isRight(): boolean;
  abstract get left(): Left;
  abstract get right(): Right;
  abstract get get(): Left | Right;
}

class Left<L, R = never> extends Either<L, R> implements EitherLike<L, R> {
  constructor(private readonly value: L) {
    super();
  }

  mapRight<B> (f: (_: R) => B): Left<L> {
    return this as unknown as Left<L>; // sigh. alternative is instantiating a new Either.
  }

  mapLeft<B> (f: (_: L) => B): Left<B> {
    return left(f(this.value));
  }

  bimap<B, C> (fl: (_: L) => B, fr: (_: R) => C): Either<B, C> {
    return left(fl(this.value));
  }

  fold<C> (fl: (_: L) => C, fr: (_: R) => C): C {
    return fl(this.value);
  }

  foldEither<C> (f: (_: L) => C): C {
    return f(this.value);
  }

  get isLeft(): boolean { return true; }
  get isRight(): boolean { return false; }
  get left(): L { return this.value; }
  get right(): R { throw new Error('is left; cannot call #right'); }
  get get(): L | R { return this.value; }
}

class Right<R, L = never> extends Either<L, R> implements EitherLike<L, R> {
  constructor(private readonly value: R) {
    super();
  }

  mapRight<B> (f: (_: R) => B): Right<B> {
    return right(f(this.value));
  }

  mapLeft<B> (f: (_: L) => B): Right<R> {
    return this as unknown as Right<R>; // sigh. alternative is instantiating a new Either.
  }

  bimap<B, C> (fl: (_: L) => B, fr: (_: R) => C): Either<B, C> {
    return right(fr(this.value));
  }

  fold<C> (fl: (_: L) => C, fr: (_: R) => C): C {
    return fr(this.value);
  }

  foldEither<C> (f: (_: R) => C): C {
    return f(this.value);
  }

  get isLeft() { return false; }
  get isRight() { return true; }
  get left(): L { throw new Error('is right; cannot call #left'); }
  get right(): R { return this.value; }
  get get(): L | R { return this.value; }
}

// This should only be used for test fixtures.
export class Both<L, R> extends Either<L, R> implements EitherLike<L, R> {
  constructor(private readonly leftV: L, private readonly rightV: R, private readonly condition: () => boolean) {
    super();
  }

  mapRight<B> (f: (_: R) => B): Both<L, B> {
    return both(this.leftV, f(this.rightV), this.condition);
  }

  mapLeft<B> (f: (_: L) => B): Both<B, R> {
    return both(f(this.leftV), this.rightV, this.condition);
  }

  bimap<B, C> (fl: (_: L) => B, fr: (_: R) => C): Both<B, C> {
    return both(fl(this.leftV), fr(this.rightV), this.condition);
  }

  fold<C> (fl: (_: L) => C, fr: (_: R) => C): C {
    return this.condition() ? fr(this.rightV) : fl(this.leftV);
  }

  foldEither<C> (f: (_: L | R) => C): C {
    return f(this.condition() ? this.rightV : this.leftV);
  }

  get isLeft() { return !this.condition(); }
  get isRight() { return this.condition(); }
  get left(): L { return this.leftV; }
  get right(): R { return this.rightV; }
  get get(): L | R { return this.foldEither(x => x); }
}

export const left = <T>(value: T): Left<T> => new Left<T>(value);
export const right = <T>(value: T): Right<T> => new Right<T>(value);
const both = <L, R>(leftV: L, rightV: R, condition: () => boolean): Both<L, R> => new Both<L, R>(leftV, rightV, condition);

export const when = <L, R>(condition: boolean) => (value: L | R): Either<L, R> =>
  condition
    ? (right(value) as Right<R>)
    : (left(value) as Left<L>);

export const iif = (condition: boolean | (() => boolean)) => <L, R>(fl: (..._: any[]) => L, fr: (..._: any[]) => R) => {
  if (typeof condition === 'function') {
    return (...args: any[]) => condition() ? right(fr(...args)) : left(fl(...args));
  } else {
    return condition
      ? (...args: any[]) => right(fr(...args))
      : (...args: any[]) => left(fl(...args));
  }
};

export const buildBoth = (condition: () => boolean) => <L, R>(fl: (..._: any[]) => L, fr: (..._: any[]) => R) => (...args: any[]) => both(fl(...args), fr(...args), condition);

interface Mappable<A> {
  map<B> (f: (_: A) => B): Mappable<B>;
}

// Express Either as L+R, and thus an Either of Mappables ML+MR can be factored into M(L+R)
// For example, if you have an Either<Option<OldWay>, Option<NewWay>>, this can transform it into Option<Either<OldWay, NewWay>>.
// This is very useful when you're converting a function that was producing Option<OldWay> using iif to generate Either<Option<OldWay>, Option<NewWay>>, but want to maintain the semantics for consumers as much as possible.
const factorMappable = <L, R>(either: Either<Mappable<L>, Mappable<R>>): Mappable<Either<L, R>> => either.fold(
  mappable => (mappable.map(left) as Mappable<Either<L, R>>),
  mappable => (mappable.map(right) as Mappable<Either<L, R>>),
);

export const factorOption = <L, R>(either: Either<Option<L>, Option<R>>): Option<Either<L, R>> => factorMappable(either) as Option<Either<L, R>>;
export const factorArray = <L, R>(either: Either<L[], R[]>): Either<L, R>[] => factorMappable(either) as Either<L, R>[];

export const zipEithers = (...eithers: Either<any, any>[]) => {
  if (eithers.every(e => e.isLeft)) {
    return left(eithers.map(e => e.left));
  } else if (eithers.every(e => e.isRight)) {
    return right(eithers.map(e => e.right));
  } else {
    throw new Error('not allowed');
  }
};
