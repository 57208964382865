import { takeEvery, takeLatest, put, select, delay } from 'redux-saga/effects';

import {
  checkExpectedOutcomes,
  hasAudienceChanged,
  shouldConfirmButtonBeDisabled
} from 'common/components/AccessManager/Util';
import type { FederatedSite, GuidanceSummaryV2 } from 'common/types/approvals';

import {
  PermissionsActionsTypes,
  changeAudienceScope
} from 'common/components/AccessManager/actions/PermissionsActions';
import {
  UiActionTypes,
  didSwitchModes,
  switchModes,
  toggleSaveButton
} from 'common/components/AccessManager/actions/UiActions';

import * as selectors from './Selectors';
import { AudienceScope } from 'common/types/view';
import { ApprovalOutcomeMessages } from '../types';

function* onChangeAudienceScope(action: ReturnType<typeof changeAudienceScope>) {
  const { scope } = action.payload;
  const approvalsGuidance: GuidanceSummaryV2 = yield select(selectors.getApprovalsGuidance);
  const currentScope: AudienceScope | undefined = yield select(selectors.getCurrentViewScope);
  const haveUsersChangedFlag: boolean = yield select(selectors.getHaveUsersChanged);
  const hasAudienceChangedFlag: boolean = yield select(selectors.getHasAudienceChanged);
  const { outcomes, sitesFederatedTo, confirmButtonDisabled } = checkExpectedOutcomes(
    approvalsGuidance,
    scope,
    currentScope,
    haveUsersChangedFlag,
    hasAudienceChangedFlag
  );
  yield put(toggleSaveButton(outcomes, sitesFederatedTo, confirmButtonDisabled));
}

function* onUsersChanged() {
  const outcomes: ApprovalOutcomeMessages = yield select(selectors.getApprovalOutcomeMessages);
  const sitesFederatedTo: FederatedSite[] = yield select(selectors.getSitesFederatedToIfMadePublic) || [];
  const haveUsersChangedFlag: boolean = yield select(selectors.getHaveUsersChanged);
  const hasAudienceChangedFlag: boolean = yield select(selectors.getHasAudienceChanged);

  // This is confusingly named. The selector getCurrentScope looks at the currently selected scope in the UI,
  //  not the view's current scope
  const currentScope: AudienceScope = yield select(selectors.getCurrentScope);
  const confirmButtonDisabled = shouldConfirmButtonBeDisabled(
    outcomes,
    haveUsersChangedFlag,
    hasAudienceChangedFlag,
    currentScope
  );

  yield put(toggleSaveButton(outcomes, sitesFederatedTo, confirmButtonDisabled));
}

function* onSwitchModes(action: ReturnType<typeof switchModes>) {
  const { newMode } = action.payload;

  // Unfortunately this value has to be duplicated w/ the CSS animation
  yield delay(150);
  yield put(didSwitchModes(newMode));
}

export default [
  takeLatest(PermissionsActionsTypes.CHANGE_AUDIENCE_SCOPE, onChangeAudienceScope),
  takeLatest(PermissionsActionsTypes.ADD_USERS, onUsersChanged),
  takeLatest(PermissionsActionsTypes.REMOVE_USER_ACCESS, onUsersChanged),
  takeEvery(UiActionTypes.SWITCH_MODES, onSwitchModes)
];
