import { Both, Either, factorOption, iif, buildBoth } from 'common/either';
import { FeatureFlags } from 'common/feature_flags';
import { FunCall, TypedSoQLFunCall, Expr, TypedExpr } from 'common/types/soql';
import { Eexpr, EexprNA } from '../types';
import { Option, option } from 'ts-option';
import { Query } from '../redux/store';
import { analysisSuccess, compilationSuccess } from '../lib/selectors';
import {
  QueryAnalysisResult, QueryAnalysisSucceeded,
  QueryCompilationSucceeded, QueryCompilationResult
} from 'common/types/compiler';

export const usingNewAnalysisEndpoint = () =>
  FeatureFlags.valueOrDefault('soql_analyzer_implementation', 'original') === 'new-analyzer';

export const whichAnalyzer = iif(usingNewAnalysisEndpoint);
export const eitherForTest = buildBoth(usingNewAnalysisEndpoint);

export type TransitionSuccessType = Both<QueryCompilationSucceeded, Option<QueryAnalysisSucceeded>>;
export const buildSucceeded = (c: QueryCompilationSucceeded, a?: QueryAnalysisSucceeded) =>
  buildBoth(usingNewAnalysisEndpoint)(() => c, () => option(a))();
export const buildSuccessOption = (q: Query): Option<TransitionSuccessType> => compilationSuccess(q.compilationResult).map(cr => {
  const ar = analysisSuccess(q.analysisResult).orUndefined;
  return buildSucceeded(cr, ar);
});

export const createUpdateContents = (
  eitherEexpr: Either<Eexpr<FunCall, TypedSoQLFunCall>, EexprNA<TypedSoQLFunCall>>,
  otherArgs: Either<Expr[], TypedExpr[]>
) =>
  eitherEexpr.map(
    eexpr => ({
      ...eexpr.untyped,
      args: [eexpr.untyped.args[0], ...otherArgs.left]
    }),
    eexpr => ({
      ...eexpr.expr,
      args: [eexpr.expr.args[0], ...otherArgs.right]
    })
  );
