import $ from 'jquery';
import _ from 'lodash';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import { assert, assertHasProperty } from 'common/assertions';
import 'common/visualizations/jquery';

import { TableProps } from './types';
import './shared/componentBase';
import Constants from 'lib/Constants';
import StorytellerUtils from 'lib/StorytellerUtils';
import { flyoutRenderer } from 'lib/components/FlyoutRenderer';
import { migrateInSituTables } from 'common/visualizations/helpers/VifHelpers';
import StoryVisualization from 'lib/components/StoryVisualization';
import { StorytellerReduxStore } from 'store/StorytellerReduxStore';
import {
  shouldEnableLookupLog,
  markLookupLogAttached,
  getDatasetIdFromGlobalFilterBar,
  sendLookupToolLogRequest
} from 'lib/LookupToolLog';
import { shouldUseReactComponentBase } from 'lib/FlexibleLayoutUtils';

$.fn.componentSocrataVisualizationTable = componentSocrataVisualizationTable;

// @ts-expect-error TS(1064) FIXME: The return type of an async function or method mus... Remove this comment to see the full error message
export default async function componentSocrataVisualizationTable(props: TableProps): JQuery {
  props = _.extend({}, props, {
    resizeSupported: true,
    resizeOptions: {
      minHeight: Constants.MINIMUM_COMPONENT_HEIGHTS_PX.VISUALIZATION
    },
    defaultHeight: Constants.DEFAULT_TABLE_HEIGHT
  });

  const $this = $(this);
  const { componentData } = props;

  assertHasProperty(componentData, 'type');
  assert(
    componentData.type === 'socrata.visualization.table' ||
      componentData.type === 'socrata.visualization.agTable',
    `componentSocrataVisualizationTable: Unsupported component type ${componentData.type}`
  );

  if (componentData.type === 'socrata.visualization.table') {
    // update component data with ag type to render the correct template and apply classnames
    _.set(componentData, 'type', 'socrata.visualization.agTable');

    const originalVif = _.cloneDeep(_.get(componentData, 'value.vif'));

    // migrate vif from type table to type AgTable
    const migratedTableVif = await migrateInSituTables(originalVif);
    _.set(componentData, 'value.vif', migratedTableVif);
  }

  if ($this.children().length > 0) {
    const componentTypeClass = StorytellerUtils.typeToClassesForComponentType(componentData.type);

    const hasComponentTypeChanged = $this.hasClass(componentTypeClass) === false;

    if (hasComponentTypeChanged) {
      _destroyPreviousTable($this);
      _renderTemplate($this, props);
    }
  } else {
    _renderTemplate($this, props);
  }

  _updateVisualization($this, props);

  if (!shouldUseReactComponentBase()) {
    $this.componentBase(props);
  }

  return $this;
}

function _renderTemplate($element: JQuery, props: TableProps): void {
  const { componentData } = props;
  const $componentContent = $('<div>', { class: 'component-content' });

  assertHasProperty(componentData, 'type');

  $element
    .addClass(StorytellerUtils.typeToClassesForComponentType(componentData.type))
    // Pass on the destroy event to plugin.
    .on('destroy', () => {
      $componentContent.triggerHandler('destroy');
    })
    .on('SOCRATA_VISUALIZATION_FLYOUT', (event) => {
      const payload = _.get(event, 'originalEvent.detail');

      if (payload !== null) {
        flyoutRenderer.render(payload);
      } else {
        flyoutRenderer.clear();
      }
    });

  $element.append($componentContent);
}

function _destroyPreviousTable($element: JQuery): void {
  const $componentContent = $element.find('.component-content');

  $componentContent.triggerHandler('SOCRATA_VISUALIZATION_DESTROY');
  $element.empty();
  $element.removeClass();
  $element.addClass('component');
}

function _updateVisualization($element: JQuery, props: TableProps): void {
  const { componentData } = props;
  const $componentContent = $element.find('.component-content');

  assertHasProperty(componentData, 'value.vif');

  // Determine if we should enable lookup log
  const datasetUid = _.get(componentData, 'value.vif.series[0].dataSource.datasetUid');
  let additionalOptions = {};
  if (shouldEnableLookupLog() && !!datasetUid && getDatasetIdFromGlobalFilterBar() === datasetUid) {
    // add callback as an option if we need to log lookup tool usage
    additionalOptions = { lookupLogCallback: sendLookupToolLogRequest };
    markLookupLogAttached();
  }

  const { vif } = componentData.value;

  ReactDOM.render(
    <Provider store={StorytellerReduxStore}>
      <StoryVisualization storyVif={vif} options={additionalOptions} />
    </Provider>,
    $componentContent[0]
  );
}
