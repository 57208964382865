import moment from 'moment';

// Breaking with classic enum convention here because we don't want to enforce an all uppercase API when dealing with measure calculation types.
// We decided to have slightly awkward enums here instead of awkward `toUpperCase()` everywhere we are comparing values
export enum CalculationTypes {
  AVERAGE = 'average',
  COUNT = 'count',
  RATE = 'rate',
  RECENT = 'recent',
  SUM = 'sum'
}

export enum PeriodTypes {
  OPEN = 'open',
  CLOSED = 'closed',
  LAST_REPORTED = 'last_reported'
}

export const StartDateTypes = Object.freeze({
  FIXED: 'fixed',
  FLOATING: 'floating'
});

export const DefaultStartDateDuration = 7;

export const DefaultCumulativeStartDate = moment().startOf('year').format('YYYY-MM-DD');

// NOTE: These are intentionally in decreasing duration.
// Do not alphabetize these :)
export enum PeriodSizes {
  YEAR = 'year',
  QUARTER = 'quarter',
  MONTH = 'month',
  WEEK = 'week',
  DAY = 'day'
}

// NOTE: These are intentionally in decreasing duration.
// Do not alphabetize these :)
export const TimelineSamplingSizes = Object.freeze([
  PeriodSizes.QUARTER,
  PeriodSizes.MONTH,
  PeriodSizes.WEEK,
  PeriodSizes.DAY
]);

export const DefaultTimelineSamplingSizes = Object.freeze({
  year: 'quarter',
  quarter: 'month',
  month: 'week',
  week: 'day'
});

export enum TimelineScopes {
  ALL = 'all',
  CURRENT = 'current'
}

export enum StatusFunctions {
  NONE = 'none',
  MANUAL = 'manual',
  PROXIMITY = 'proximity',
  ABOVE_BELOW = 'above_below'
}

// NOTE: Values correspond to i18n translation keys
export enum StatusValues {
  NEAR_TARGET = 'near_target',
  MEASURING = 'measuring',
  OFF_TRACK = 'off_track',
  ON_TRACK = 'on_track'
}

// This is not part of the enum above because it can be in addition to those statuses.
export const STATUS_ENDED = 'ended';

export const StatusIcons = Object.freeze({
  [StatusValues.NEAR_TARGET]: 'info',
  [StatusValues.MEASURING]: null,
  [StatusValues.OFF_TRACK]: 'close-circle',
  [StatusValues.ON_TRACK]: 'checkmark-alt'
});

export const ForgeStatusIcons = Object.freeze({
  [StatusValues.NEAR_TARGET]: 'info',
  [StatusValues.MEASURING]: null,
  [StatusValues.OFF_TRACK]: 'close_circle',
  [StatusValues.ON_TRACK]: 'check_circle'
});

export const ProximityStatusColors = Object.freeze({
  [StatusValues.OFF_TRACK]: 'red',
  [StatusValues.NEAR_TARGET]: 'yellow',
  [StatusValues.ON_TRACK]: 'green'
});

export const AboveBelowDirection = Object.freeze({
  ABOVE: 'above',
  BELOW: 'below'
});

export const StatusesOrdered = Object.freeze([
  StatusValues.MEASURING,
  StatusValues.ON_TRACK,
  StatusValues.OFF_TRACK,
  StatusValues.NEAR_TARGET
]);

export enum TargetTypes {
  ONGOING = 'ongoing',
  PERIODIC = 'periodic'
}

export const LatestSchemaVersion = 5;

export const BlankMeasure = Object.freeze({
  version: LatestSchemaVersion
});

export const TIMESTAMP_WITHOUT_ZONE = 'YYYY-MM-DDTHH:mm:ss.SSS';

export enum AxisScalingTypes {
  MAX_ONLY = 'max_only',
  MIN_MAX = 'min_max',
  CUSTOM = 'custom'
}
