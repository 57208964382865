import $ from 'jquery';
import _ from 'lodash';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import { assert, assertHasProperty } from 'common/assertions';

import Constants from 'lib/Constants';
import StorytellerUtils from 'lib/StorytellerUtils';
import { flyoutRenderer } from 'lib/components/FlyoutRenderer';
import StoryVisualization from 'lib/components/StoryVisualization';
import { StorytellerReduxStore } from 'store/StorytellerReduxStore';

import './shared/componentBase';
import { FlyoutEvent, MapProps } from './types';
import { MapBlockComponent } from 'types';
import { shouldUseReactComponentBase } from 'lib/FlexibleLayoutUtils';

$.fn.componentSocrataVisualizationMap = componentSocrataVisualizationMap;

/*
  Component format:
  {
   type: "socrata.visualization.map",
   value: {
     vif: <vif object>
   }
  }
*/

export default function componentSocrataVisualizationMap(props: MapProps) {
  props = _.extend({}, props, {
    resizeSupported: true,
    resizeOptions: {
      minHeight: Constants.MINIMUM_COMPONENT_HEIGHTS_PX.VISUALIZATION
    }
  });

  const $this = $(this);
  const { componentData } = props;

  assertHasProperty(props, 'componentData.type');
  assert(
    props.componentData.type === 'socrata.visualization.map',
    `componentSocrataVisualizationMap: Unsupported component type ${props.componentData.type}`
  );

  if ($this.children().length === 0) {
    _renderTemplate($this, componentData, props);
  }

  _updateVisualization($this, props);

  if (!shouldUseReactComponentBase()) {
    $this.componentBase(props);
  }

  return $this;
}

function _renderTemplate($element: JQuery, componentData: MapBlockComponent, props: MapProps) {
  assertHasProperty(componentData, 'type');

  const classes = StorytellerUtils.typeToClassesForComponentType(componentData.type);
  const $componentContent = $('<div>', { class: 'component-content' });
  const flyoutEvent = 'SOCRATA_VISUALIZATION_FLYOUT';

  $element
    .addClass(classes)
    .on('destroy', () => {
      $componentContent.triggerHandler('destroy');
    })
    .on(flyoutEvent, (e: JQuery.Event) => {
      const event = e as FlyoutEvent;
      // TODO: Flyouts will/should be handled on the mouse interaction story.
      const payload = event.originalEvent?.detail;

      if (payload !== null) {
        flyoutRenderer.render(payload);
      } else {
        flyoutRenderer.clear();
      }
    });

  $element.append($componentContent);
}

function _updateVisualization($element: JQuery, props: MapProps) {
  assertHasProperty(props, 'componentData.value.vif');

  const { vif } = props.componentData.value;
  const $componentContent = $element.find('.component-content');
  const additionalOptions = { displayFilterBar: true, toggleMapLayersInternally: true };

  ReactDOM.render(
    <Provider store={StorytellerReduxStore}>
      <StoryVisualization storyVif={vif} options={additionalOptions} />
    </Provider>,
    $componentContent[0]
  );
}
