import { chain, find, forEach, map, merge } from 'lodash';
import * as BaseFilter from './Filters/BaseFilter';
import { SoqlFilter } from 'common/components/FilterBar/SoqlFilter';
import { FilterBarColumns } from 'common/components/FilterBar/index';
import {
  DataSourceCCVs,
  FilterItemType,
  FilterParameterConfiguration,
  ParameterConfiguration
} from 'common/types/reportFilters';
import FeatureFlags from 'common/feature_flags';

export function getNonHiddenFilters(filters: SoqlFilter[], isReadOnly = true) {
  return chain(filters)
    .map((filterItem) => {
      if (isReadOnly && filterItem.isHidden) {
        return null;
      }
      return filterItem;
    })
    .compact()
    .value();
}

/** Given a parameter, find its client context variable's default value.
 *  If in viewmode, the client context variable's override value is the default.
 */
export const findClientContextVariableDefault = (
  clientContextVariables: DataSourceCCVs | undefined,
  parameter: ParameterConfiguration,
  editMode: boolean
): string => {
  let defaultValue = '';

  if (clientContextVariables) {
    const dataSourceCCVs = clientContextVariables[parameter.paramIds[0].datasetUid];
    if (dataSourceCCVs) {
      const ccvForParameter = dataSourceCCVs.find((ccv) => ccv.name === parameter.paramIds[0].name);
      if (ccvForParameter) {
        defaultValue = editMode
          ? ccvForParameter.defaultValue
          : (ccvForParameter.overrideValue ?? ccvForParameter.defaultValue);
      }
    }
  }
  return defaultValue;
};

export function resetFiltersToDefaults(
  filters: SoqlFilter[],
  columns: FilterBarColumns,
  resetOnlyVisibleFilters = false
) {
  return map(filters, (filter) => {
    // We only want to pass in the columns associated to the filter so we manually map the columns for each filter
    const filterColumns = {};
    forEach(filter.columns, (column) => {
      filterColumns[column.datasetUid] = find(columns[column.datasetUid], ['fieldName', column.fieldName]);
    });
    return BaseFilter.reset(filter, filterColumns, resetOnlyVisibleFilters);
  });
}

export function resetAllFiltersToDefaults(
  filterParameterConfigurations: FilterParameterConfiguration[],
  columns: FilterBarColumns,
  clientContextVariables: DataSourceCCVs | undefined,
  editMode: boolean,
  resetOnlyVisibleFilters = false
): FilterParameterConfiguration[] {
  const enableParameterDropdownsInStories = FeatureFlags.valueOrDefault(
    'enable_parameter_dropdowns_in_stories',
    false
  );
  return map(filterParameterConfigurations, (filterParameterConfiguration) => {
    if (filterParameterConfiguration.type === FilterItemType.FILTER) {
      // We only want to pass in the columns associated to the filter so we manually map the columns for each filter
      const filterColumns = {};
      forEach(filterParameterConfiguration.config.columns, (column) => {
        filterColumns[column.datasetUid] = find(columns[column.datasetUid], ['fieldName', column.fieldName]);
      });
      const newFilter = BaseFilter.reset(
        filterParameterConfiguration.config,
        filterColumns,
        resetOnlyVisibleFilters
      );
      return {
        type: FilterItemType.FILTER,
        config: newFilter
      };
    } else if (enableParameterDropdownsInStories && !filterParameterConfiguration.config.isHidden) {
      let newParameter = { ...filterParameterConfiguration.config };
      const resetValue = findClientContextVariableDefault(
        clientContextVariables,
        filterParameterConfiguration.config,
        editMode
      );
      newParameter = merge(newParameter, { overrideValue: resetValue });
      return {
        type: FilterItemType.PARAMETER,
        config: newParameter
      };
    } else {
      return filterParameterConfiguration;
    }
  });
}
