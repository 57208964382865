import React, { useEffect, useState } from 'react';

import { compact, find, get, isEmpty, isUndefined } from 'lodash';
import { none } from 'ts-option';

import { IMenuSelectEventData } from '@tylertech/forge';
import { ForgeIconButton, ForgeIcon, ForgeTooltip, ForgeMenu } from '@tylertech/forge-react';
import { FeatureFlags } from 'common/feature_flags';
import I18n from 'common/i18n';

import SoqlHelpers from 'common/visualizations/dataProviders/SoqlHelpers';
import MetadataProvider from 'common/visualizations/dataProviders/MetadataProvider';
import ExportModal, { QueryStringObject, TOGGLE_OPTIONS } from 'common/components/ExportModal';
import ExportFlannel from 'common/components/ExportFlannel';
import { Vif } from 'common/visualizations/vif';
import { ViewColumn } from 'common/types/viewColumn';
import { View } from 'common/types/view';
import { SERIES_TYPE_AG_GRID_TABLE } from 'common/visualizations/views/SvgConstants';
import './index.scss';
import { BaseVisualization } from '../types';
import { Hierarchy, HierarchyColumnConfig } from 'common/visualizations/vif';
import { getParameterOverrides } from 'common/visualizations/helpers/VifSelectors';
import eventBus from '../../agGridReact/helpers/EventBus';

enum SourceDataOption {
  EXPORT,
  VIEWSOURCE,
  PRINT
}

const ALLOWED_PRINT_TYPES = ['agTable'];

export interface SourceDataOptionsProps {
  vif: Vif;
  viz: BaseVisualization;
  seriesIndex?: number;
  viewSourceLinkLabel?: string;
  viewSourceLinkHref?: string;
  showExportDataOption?: boolean;
  showViewSourceDataLink?: boolean;
  vizUid?: string;
}

const getAllColumns = (vif: Vif, seriesIndex: number | undefined) => {
  return get(vif, `series[${seriesIndex}].dataSource.dimension.columns`, []);
};

export const getSelectedColumns = (
  vif: Vif,
  seriesIndex: number | undefined,
  columns: ViewColumn[]
): string[] | null => {
  const type = get(vif, `series[${seriesIndex}].type`);
  if (
    !FeatureFlags.value('enable_export_selected_columns_in_story_tables') ||
    type !== SERIES_TYPE_AG_GRID_TABLE
  ) {
    return null;
  }

  const hierarchies = get(vif, 'series[0].dataSource.hierarchies');
  const activeHierarchyId = get(vif, 'series[0].dataSource.activeHierarchyId');
  const activeHierarchyIndex = hierarchies.findIndex((h: Hierarchy) => h.id == activeHierarchyId);
  const activeHierarchyConfig = hierarchies[activeHierarchyIndex].columnConfigurations;

  const selectedColumns = compact(
    columns.map((column) => {
      // Find the columns, excluding the hidden ones which are stored in the current
      // hierarchy's column configurations
      const hierarchyColumn = find(
        activeHierarchyConfig,
        (config: HierarchyColumnConfig) => config.columnName === column.fieldName
      );
      if (hierarchyColumn && hierarchyColumn.hidden) {
        return; // Exclude hidden columns
      }
      return column.fieldName;
    })
  );
  return selectedColumns.length > 0 ? selectedColumns : null;
};

const getWhereClause = (vif: Vif, seriesIndex: number | undefined) => {
  // This is smelly. We shouldn't have to check things before calling a helper,
  // just because the helper might throw an error. But I also don't want to try/catch here.
  if (
    isUndefined(seriesIndex) ||
    isUndefined(vif.series[seriesIndex]) ||
    isEmpty(vif?.series[seriesIndex]?.dataSource?.filters)
  )
    return '';
  const maybeEmptyWhere = SoqlHelpers.whereClauseFilteringOwnColumn(vif, seriesIndex);
  return maybeEmptyWhere ? `where ${maybeEmptyWhere}` : '';
};

const constructQueryObject = (
  vif: Vif,
  seriesIndex: number | undefined,
  columns: ViewColumn[]
): QueryStringObject => {
  const selectedColumns = getSelectedColumns(vif, seriesIndex, columns);
  return {
    selectClause: selectedColumns ? `select ${selectedColumns.join(', ')}` : 'select *',
    odataSelectClause: selectedColumns ? `?$select=${selectedColumns.join(',')}` : undefined,
    whereClause: getWhereClause(vif, seriesIndex)
  };
};

export const SourceDataOptions = (props: SourceDataOptionsProps) => {
  const [metadata, setMetadata] = useState({} as View);
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [flannelOpen, setFlannelOpen] = useState(false);
  const { vif, viz, seriesIndex, showExportDataOption, showViewSourceDataLink, vizUid } = props;

  useEffect(() => {
    const getMetadata = async () => {
      const domain = get(vif, `series[${seriesIndex}].dataSource.domain`);
      const datasetUid = get(vif, `series[${seriesIndex}].dataSource.datasetUid`);
      if (!datasetUid) return;

      const metadataProvider = new MetadataProvider({ datasetUid, domain }, true);

      const datasetMetadataAndFederationStatus =
        await metadataProvider.getDatasetMetadataAndFederationStatus();
      const attributionDomain = await metadataProvider.getAttributionDomain(
        datasetMetadataAndFederationStatus
      );
      if (attributionDomain) {
        setMetadata(datasetMetadataAndFederationStatus.metadata);
      }
    };

    getMetadata();
  }, [vif, seriesIndex]);

  const exportText = I18n.t('shared.visualizations.charts.common.toolbar_options.export_data');
  const viewSourceText = I18n.t('shared.visualizations.charts.common.view_source_data');
  const printText = I18n.t('shared.visualizations.charts.common.print_table');

  const menuOptions = [];

  function exportDataFeatureFlagEnabled() {
    return FeatureFlags.available() && FeatureFlags.value('enable_export_data_for_viz_in_stories');
  }

  if (showExportDataOption && exportDataFeatureFlagEnabled()) {
    menuOptions.push({ value: SourceDataOption.EXPORT, label: exportText });
  }

  if (showViewSourceDataLink && FeatureFlags.value('enable_view_source_data_stories')) {
    menuOptions.push({ value: SourceDataOption.VIEWSOURCE, label: viewSourceText });
  }
  const vifType = get(vif, `series[${seriesIndex}].type`);

  if (ALLOWED_PRINT_TYPES.includes(vifType) && FeatureFlags.value('enable_table_print_prototype')) {
    menuOptions.push({ value: SourceDataOption.PRINT, label: printText });
  }

  const menuOptionBuilder = (option: { value: SourceDataOption; label: string }, listItem: any) => {
    if (option.value === SourceDataOption.VIEWSOURCE) {
      // forge-menu has internal logic that converts the menu item to a link
      // when href is provided. We want the item to be a link so that clicking it
      // does not trigger the browser's pop-up blocker.
      listItem.href = props.viewSourceLinkHref;
      listItem.target = '_blank';
      listItem.setAttribute('data-pendo', 'view-source-link');
      listItem.setAttribute('data-testid', 'view-source-link');
    } else if (option.value === SourceDataOption.EXPORT) {
      listItem.setAttribute('data-pendo', 'export-data-link');
      listItem.setAttribute('className', 'export-data-link');
      listItem.setAttribute('data-testid', 'export-data-link');
    } else if (option.value === SourceDataOption.PRINT) {
      // TODO: SourceDataOptions should not be responsible for the entire kebab. We need a generic
      // way to add buttons to this kebab, either from common, or from storyteller. That would
      // let us reference the component that the button is connected to, and not have to use jQuery here.
      // CC TODO_PRINT_TABLE: Issue for this feature, but not it's responsibility.
      listItem.setAttribute('data-pendo', 'print-preview');
      listItem.setAttribute('data-testid', 'print-preview');
    }

    return option.label;
  };

  const iconButtonDensityProps = FeatureFlags.valueOrDefault('enable_global_filters_measures', false)
    ? { dense: true, 'density-level': 3 }
    : {};

  const verticalMenuSelect = (
    <ForgeMenu
      placement="bottom-right"
      on-forge-menu-select={onMenuSelect}
      options={menuOptions}
      optionBuilder={menuOptionBuilder}
    >
      <ForgeIconButton {...iconButtonDensityProps}>
        <button
          type="button"
          data-testid="vertical-kabob"
          className="tyler-icons"
          aria-label={props.viewSourceLinkLabel}
        >
          <ForgeIcon name="more_vert" />
        </button>
      </ForgeIconButton>
    </ForgeMenu>
  );

  const handleOnClickExportButton = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setFlannelOpen(true);
  };

  const exportButton = (
    <ForgeIconButton {...iconButtonDensityProps}>
      <button
        type="button"
        aria-label={exportText}
        className="tyler-icons"
        onClick={(e) => {
          handleOnClickExportButton(e);
        }}
      >
        <ForgeIcon name="download" />
      </button>
      <ForgeTooltip position="bottom">{exportText}</ForgeTooltip>
    </ForgeIconButton>
  );

  function onMenuSelect(customEvent: CustomEvent<IMenuSelectEventData>): void {
    switch (customEvent.detail.value) {
      case SourceDataOption.EXPORT: {
        setIsExportModalOpen(true);
        break;
      }

      case SourceDataOption.PRINT: {
        eventBus.dispatch(`print-table-${vizUid}`);
        break;
      }
      case SourceDataOption.VIEWSOURCE: {
        window.open(props.viewSourceLinkHref, '_blank', 'noopener,noreferrer');
        break;
      }
    }
  }

  if (exportDataFeatureFlagEnabled()) {
    // metadata.apiFoundryUrl is not available outside of DatasetLandingPage, so we'll calculate it manually
    const foundryUrl = `https://dev.socrata.com/foundry/${window.location.host}/${metadata.id}`;
    const queryStringObject = constructQueryObject(vif, seriesIndex, getAllColumns(vif, seriesIndex));
    const isTableViz = vifType === 'agTable';
    return menuOptions.length > 0 ? (
      <>
        <div className="socrata-visualization-source-data-options">{verticalMenuSelect}</div>
        {isExportModalOpen && !isEmpty(metadata) ? (
          <ExportModal
            defaultToggleOption={TOGGLE_OPTIONS.DOWNLOAD_FILE}
            query={none} // this is a query AST object on explore_grid
            queryStringClause={queryStringObject}
            view={metadata}
            apiFoundryUrl={foundryUrl}
            bodyText={metadata.name}
            clientContextVariables={getParameterOverrides(vif)}
            fourfour={metadata.id}
            showDataToggles={true}
            isTableViz={isTableViz}
            onDismiss={() => setIsExportModalOpen(false)}
            vizUid={vizUid}
            isExportModalOpen={isExportModalOpen}
          />
        ) : null}
      </>
    ) : null;
  } else {
    const filtersPresent = !isEmpty(get(vif, 'series[0].dataSource.filters'));
    return (
      <>
        <div className="socrata-visualization-source-data-options">
          {showExportDataOption ? exportButton : null}
          {showViewSourceDataLink ? verticalMenuSelect : null}
        </div>
        {showExportDataOption && (
          <ExportFlannel
            classNames={['socrata-visualization-export-data-container']}
            whereClause={filtersPresent ? SoqlHelpers.whereClauseFilteringOwnColumn(vif, 0) : ''}
            exportFilteredData
            exportFormats={[
              'csv',
              'csv_for_excel',
              'csv_for_excel_europe',
              'rdf',
              'rss',
              'tsv_for_excel',
              'xml'
            ]}
            modalMode
            disableButton
            flannelOpen={flannelOpen}
            onClose={() => {
              setFlannelOpen(false);
            }}
            view={metadata}
          />
        )}
      </>
    );
  }
};
