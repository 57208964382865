import _ from 'lodash';
import { Vif } from 'common/visualizations/vif';

export function migrateVif3ToVif4(vifToMigrate: any) {
  const migratedVif = migrateCustomColorPalettes(vifToMigrate);
  migratedVif.format.version = 4;
  return migratedVif;
}

export function migrateCustomColorPalettes(vif: Vif) {
  const modifiedVif = _.cloneDeep(vif);
  const series = _.get(modifiedVif, 'series', []);
  const modifiedSeries = series.map((seriesItem) => {
    const quantificationMethod = _.get(seriesItem, 'mapOptions.colorByQuantificationMethod');
    if (quantificationMethod) {
      const customPalette = _.get(seriesItem, 'color.customPalette');

      _.forEach(customPalette, (colorPalette, columnName) => {
        const modifiedCustomPalette = _.cloneDeep(colorPalette);
        _.unset(seriesItem, `color.customPalette.${columnName}`);
        _.set(seriesItem, `color.customPalette.${columnName}.${quantificationMethod}`, modifiedCustomPalette);
      });
    }
    return seriesItem;
  });

  _.set(modifiedVif, 'series', modifiedSeries);

  return modifiedVif;
}
