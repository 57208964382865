import { isEqual, map, mapValues, noop, values } from 'lodash';
import React, { FunctionComponent, ComponentProps, useLayoutEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import FilterBar from 'common/components/FilterBar';

import Actions from 'Actions';
import { dispatcher as fluxDispatcher } from 'Dispatcher';
import { FilterParameterConfiguration } from 'common/types/reportFilters';
import { DataSourceCCVs } from 'common/types/reportFilters';
import { StorytellerState } from 'store/StorytellerReduxStore';

import {
  updateFilterParameterConfiguration,
  updateAllFilterParameterConfigurations
} from 'store/TopLevelActions';
import { selectors } from 'store/selectors/DataSourceSelectors';
import { isFlexibleStory } from 'lib/FlexibleLayoutUtils';

export interface FilterBarContainerProps {
  $element: JQuery;
  editMode: boolean;
}

const FilterBarContainer: FunctionComponent<FilterBarContainerProps> = ({ $element, editMode = false }) => {
  const metadata = useSelector(selectors.getDataSourceMetadata, isEqual);
  const filterParameterConfigurations = useSelector(selectors.getFilterParameterConfigurations, isEqual);
  const allDataSourceCCVs = useSelector<StorytellerState, DataSourceCCVs>(selectors.getAllDataSourceCCVs);
  const dispatch = useDispatch();

  const onUpdateAllFilterParameters = (updated: FilterParameterConfiguration[]): void => {
    if (updated) {
      if (editMode) {
        // Story saves can only be triggered by Flux right now
        fluxDispatcher.dispatch({
          action: Actions.ALL_FILTER_PARAMETER_CONFIGURATIONS_UPDATED,
          updatedConfigs: updated
        });
      } else {
        dispatch(
          updateAllFilterParameterConfigurations({
            updatedConfigs: updated
          })
        );
      }
    }
  };

  const onUpdateSingleFilterParameter = (updated: FilterParameterConfiguration, index: number): void => {
    if (updated) {
      if (editMode) {
        // Story saves can only be triggered by Flux right now
        fluxDispatcher.dispatch({
          action: Actions.FILTER_PARAMETER_CONFIGURATION_UPDATED,
          index: index,
          updatedConfig: { type: updated.type, config: updated.config }
        });
      } else {
        dispatch(
          updateFilterParameterConfiguration({
            index: index,
            updatedConfig: { type: updated.type, config: updated.config }
          })
        );
      }
    }
  };

  // In classic edit mode, story needs to be prompted to re-render
  useLayoutEffect(() => {
    if (!editMode || isFlexibleStory()) return;
    const expandControl = $element.find('.btn-expand-control');
    const rerenderStory = (): void => {
      // The setTimeout is required to ensure that the height change event
      // happens _after_ the filter bar is actually expanded
      setTimeout(() => $element.trigger('component::height-change'), 0);
    };

    expandControl && expandControl.on('click', rerenderStory);

    return (): void => {
      $element.off('click', rerenderStory);
    };
  }, [$element, editMode]);

  const filterBarProps: ComponentProps<typeof FilterBar> = {
    clientContextVariables: allDataSourceCCVs,
    onUpdate: noop,
    onUpdateAllFilterParameters,
    onUpdateSingleFilterParameter,
    columns: mapValues(metadata, ({ columns }) => values(columns)),
    computedColumns: [],
    onInEditFilterChange: noop,
    isReadOnly: !editMode,
    // If metadata has not loaded for all datasources yet, then the FilterBar will not render anything.
    dataSource: map(metadata, ({ domainCName, id }) => ({ datasetUid: id, domain: domainCName! })),
    filterParameterConfigurations,
    editMode,
    pendoIds: {
      filterParameterControlContainer: 'global-filter-container-dropdown',
      expandButton: 'global-filter-expand-control',
      resetAllFilters: 'reset-filters-gfb'
    }
  };

  return <FilterBar {...filterBarProps} />;
};

export default FilterBarContainer;
