import _ from 'lodash';
import React, { Component } from 'react';
import classNames from 'classnames';
import moment from 'moment';

import { ForgeCard } from '@tylertech/forge-react';
import I18n from 'common/i18n';
import SocrataIcon, { IconName } from 'common/components/SocrataIcon';
import { FeatureFlags } from 'common/feature_flags';

// @ts-expect-error
import { MeasureTitle } from './MeasureTitle';
import { RecentTargetsInfo } from './MeasureResultCard/RecentTargetsInfo';
// @ts-expect-error
import StatusBanner from './MeasureResultCard/StatusBanner';
import withComputedMeasure, { WithComputedMeasureInjectedProps } from './withComputedMeasure';
import { PeriodTypes, PeriodSizes, STATUS_ENDED } from '../lib/constants';
import * as ReportingPeriods from 'common/performance_measures/lib/reportingPeriods';
import { formatMeasureResultBigNumber, getMeasureCardTitle, hasMeasureEnded } from '../lib/measureHelpers';
import FilterableCardHeader from 'common/components/FilterableCardHeader';

import './measure-result-card.scss';
import { Measure } from '../types';
import { VerticalViewModeFilterBarProps } from 'common/components/FilterableCardHeader/VerticalViewModeFilterBar';
import { FilterItemType } from 'common/types/reportFilters';
import { getCurrentDomain } from 'common/currentDomain';

export interface MeasureResultCardProps extends WithComputedMeasureInjectedProps {
  customLink?: { href: string | null; text: string | null };
  /** Primarily used for stories to see if we are overriding the default links */
  isCustomLinkEnabled?: boolean;
  isInsituMeasure: boolean;
  // NOTE! Ideally we'd refactor withComputedMeasure to optionally
  // take a measure UID which it would use to automatically fetch
  // both the lens and the computedMeasure props.
  // For now, all usages of MeasureResultCard either don't need
  // lens info, or already have the lens data anyway.
  lens?: {
    name: string;
    id: string;
  };
  measure: Measure | null;
  /** Domain for "view measure" link. If null, will use a relative link. */
  measureDomain?: string;
  /** whether to show the Title and more options kebab */
  showMetadata?: boolean;
  showStatus?: boolean;
  /** Primarily used for embeds; sends user back to Measure page itself */
  showViewMeasureLink?: boolean;
}

const scope = 'shared.performance_measures';
/**
 * Calculates and displays a measure as a tile
 */
export class MeasureResultCard extends Component<MeasureResultCardProps> {
  static defaultProps = {
    computedMeasure: {
      result: {},
      errors: {}
    },
    showMetadata: false,
    showStatus: true,
    showViewMeasureLink: false,
    isCustomLinkEnabled: false,
    isInsituMeasure: false
  };

  getSubtitle() {
    const { measure, computedMeasure } = this.props;
    const { result } = computedMeasure;

    const {
      calculationNotConfigured,
      dataSourceNotConfigured,
      dividingByZero,
      noRecentValue,
      noReportingPeriodAvailable,
      noReportingPeriodConfigured,
      notEnoughData,
      dataSourcePermissionDenied
    } = computedMeasure.errors ?? {};

    const dataSourceLensUid = _.get(measure, 'dataSourceLensUid');
    const singularUnitLabel = _.get(measure, 'metricConfig.display.label', '');
    const pluralUnitLabel = _.get(measure, 'metricConfig.display.pluralLabel', '');

    // NOTE: The order of these error states is important
    if (dataSourceNotConfigured || !dataSourceLensUid) {
      return I18n.t('no_dataset', { scope });
    } else if (noReportingPeriodConfigured) {
      return I18n.t('no_reporting_period', { scope });
    } else if (calculationNotConfigured) {
      return I18n.t('no_calculation', { scope });
    } else if (noReportingPeriodAvailable || notEnoughData) {
      return I18n.t('not_enough_data', { scope });
    } else if (noRecentValue) {
      return I18n.t('no_recent_value', { scope });
    } else if (dividingByZero) {
      return I18n.t('measure.dividing_by_zero', { scope });
    } else if (dataSourcePermissionDenied) {
      return I18n.t('no_visualization', { scope });
    } else {
      return Number(result?.value) === 1 ? singularUnitLabel : pluralUnitLabel;
    }
  }

  renderTitle() {
    const { showMetadata, lens, measure } = this.props;

    if (!showMetadata) {
      return null;
    }

    return <MeasureTitle lens={lens} measure={measure} />;
  }

  renderResult() {
    const { computedMeasure, measure, showStatus } = this.props;
    const { calculationColumns } = computedMeasure;
    const result = _.get(computedMeasure, 'result.value');

    if (!result.isFinite()) {
      // TODO: Decide if we want to use 'warning' for the icon, instead of the default 'number'
      return this.renderPlaceholder();
    }
    const formattedValue = formatMeasureResultBigNumber(result, measure, calculationColumns);

    const isDateRange = _.get(measure, 'metricConfig.display.shouldDisplayDateRange', true);
    const enableGlobalFiltersMeasures = FeatureFlags.valueOrDefault('enable_global_filters_measures', false);

    if (!enableGlobalFiltersMeasures) {
      return (
        <div className="measure-result-value">
          {hasMeasureEnded(measure) ? this.renderMeasureEndBanner() : null}
          <div className="measure-result-value-top">
            <div className="measure-result-big-number">{formattedValue}</div>
            <div className="measure-result-subtitle">{this.getSubtitle()}</div>
          </div>
          <div className="measure-result-value-bottom">
            {isDateRange ? this.renderPeriod() : null}
            {showStatus && this.renderStatusBanner()}
            {this.renderTargets()}
          </div>
        </div>
      );
    }

    return (
      <div className="measure-result-value">
        <div className="measure-result-card-status-targets">
          <div className="measure-result-card-banners">
            {showStatus && this.renderStatusBanner()}
            {hasMeasureEnded(measure) && this.renderMeasureEndBanner()}
          </div>
          {this.renderTargets()}
        </div>
        <div className="measure-result-value-top">
          <div className="measure-result-big-number">{formattedValue}</div>
          <div className="measure-result-subtitle">{this.getSubtitle()}</div>
        </div>
        <div className="measure-result-value-bottom">{isDateRange && this.renderPeriod()}</div>
      </div>
    );
  }

  renderMeasureEndBanner() {
    if (!FeatureFlags.valueOrDefault('enable_global_filters_measures', false)) {
      return (
        <div className="measure-end-date-banner">
          <span>{I18n.t('measure.ended', { scope })}</span>
        </div>
      );
    }

    return <StatusBanner value={STATUS_ENDED} />;
  }

  renderStatusBanner() {
    const { measure, computedMeasure } = this.props;

    const status = _.get(measure, 'metricConfig.status') || {};
    const { labels, hasMeasureEndStatusOverride } = status;

    const ended = hasMeasureEnded(measure) && hasMeasureEndStatusOverride;

    const activeStatus = _.get(computedMeasure, 'result.status');

    return <StatusBanner ended={ended} value={activeStatus} labels={labels} />;
  }

  renderTargets() {
    const { computedMeasure, measure } = this.props;
    const { calculationColumns, date } = computedMeasure;
    const { reportingPeriod, targets } = _.get(measure, 'metricConfig') || {};

    if (!measure || _.isEmpty(reportingPeriod) || _.isEmpty(targets)) {
      return null;
    }

    return <RecentTargetsInfo calculationColumns={calculationColumns} date={date} measure={measure} />;
  }

  getMeasureLink() {
    const {
      lens,
      measure,
      showViewMeasureLink,
      isCustomLinkEnabled,
      isInsituMeasure,
      customLink,
      measureDomain
    } = this.props;
    const lensId = isInsituMeasure ? _.get(measure, 'dataSourceLensUid') : _.get(lens, 'id');

    let measureLink = `/d/${lensId}`;
    if (measureDomain) {
      measureLink = `https://${measureDomain}${measureLink}`;
    }
    let measureText = isInsituMeasure
      ? I18n.t('shared.visualizations.charts.common.view_source_data')
      : I18n.t('measure.view_measure_link', { scope });

    if (!showViewMeasureLink || !lensId) {
      return null;
    }

    if (isCustomLinkEnabled && customLink && customLink.href && customLink.text) {
      measureLink = customLink.href;
      measureText = customLink.text;
    }

    return { measureLink, measureText };
  }

  renderViewMeasureLink() {
    const { measureLink, measureText } = this.getMeasureLink() ?? {};

    if (!measureLink || !measureText) {
      return null;
    }

    return (
      <div className="view-measure-link">
        <a href={measureLink} target="_blank" rel="noopener noreferrer">
          <span>{measureText} </span>
          <span className="socrata-icon-external"></span>
        </a>
      </div>
    );
  }

  renderError() {
    const { dataSourceNotConfigured, dataSourcePermissionDenied } = this.props.computedMeasure.errors ?? {};

    if (dataSourceNotConfigured || dataSourcePermissionDenied) {
      return this.renderPlaceholder();
    }

    return (
      <div className="measure-result-value">
        <div className="measure-result-error measure-result-subtitle">{this.getSubtitle()}</div>
      </div>
    );
  }

  renderPlaceholder(icon = IconName.Number) {
    return (
      <div className="measure-result-value placeholder">
        <SocrataIcon name={icon} />
        <div className="measure-result-placeholder-text">{this.getSubtitle()}</div>
      </div>
    );
  }

  /**
   * Generates the start date message based upon all the different variations
   * of measures. In some scenarios, a blank string will be returned if there
   * is only one date/message. The getEndDateMessage will handle the message
   * in this case.
   */
  getStartDateMessage() {
    const { measure, computedMeasure } = this.props;
    const isCumulativeMath = _.get(measure, 'metricConfig.arguments.isCumulativeMath', false);
    const reportingPeriod = _.get(measure, 'metricConfig.reportingPeriod', {});
    const startDate = _.get(reportingPeriod, 'startDateConfig.date');
    const cumulativeStartDate = _.get(measure, 'metricConfig.arguments.cumulativeMathStartDate', startDate);
    const { date } = computedMeasure;

    if (isCumulativeMath) {
      return moment(cumulativeStartDate).format('M/D/YY');
    } else {
      if (reportingPeriod.size !== PeriodSizes.DAY) {
        return moment(date).format('M/D/YY');
      }
    }
    return '';
  }

  getDashMessage() {
    const { measure } = this.props;
    const reportingPeriod = _.get(measure, 'metricConfig.reportingPeriod', {});
    const isCumulativeMath = _.get(measure, 'metricConfig.arguments.isCumulativeMath', false);

    if (reportingPeriod.size === PeriodSizes.DAY) {
      if (
        (isCumulativeMath && hasMeasureEnded(measure)) ||
        (isCumulativeMath && reportingPeriod.type === PeriodTypes.LAST_REPORTED)
      ) {
        return ' - ';
      } else {
        return ' ';
      }
    } else {
      if (!hasMeasureEnded(measure) && reportingPeriod.type === PeriodTypes.OPEN) {
        return ' ';
      } else {
        return ' - ';
      }
    }
  }

  /**
   * Generates the end date message based upon all the different variations
   * of measures
   */
  getEndDateMessage() {
    const { measure, computedMeasure } = this.props;
    const isCumulativeMath = _.get(measure, 'metricConfig.arguments.isCumulativeMath', false);
    const reportingPeriod = _.get(measure, 'metricConfig.reportingPeriod', {});
    const { date } = computedMeasure;

    if (reportingPeriod.size === PeriodSizes.DAY) {
      switch (reportingPeriod.type) {
        case PeriodTypes.OPEN:
          return isCumulativeMath
            ? I18n.t('measure.today', { scope })
            : I18n.t('measure.daily_open_reporting_period', { scope });
        case PeriodTypes.CLOSED:
          return isCumulativeMath
            ? I18n.t('measure.yesterday', { scope })
            : I18n.t('measure.daily_closed_reporting_period', { scope });
        case PeriodTypes.LAST_REPORTED:
          return moment(date).format('M/D/YY');
        default:
          throw new Error(`Cannot calculate start date for ${reportingPeriod.type} type.`);
      }
    } else {
      switch (reportingPeriod.type) {
        case PeriodTypes.OPEN:
          if (hasMeasureEnded(measure)) {
            return moment(date).add(1, reportingPeriod.size).subtract(1, 'day').format('M/D/YY');
          } else {
            return I18n.t('measure.today', { scope });
          }
        case PeriodTypes.CLOSED:
        case PeriodTypes.LAST_REPORTED:
          return moment(date).add(1, reportingPeriod.size).subtract(1, 'day').format('M/D/YY');
        default:
          throw new Error(`Cannot calculate start date for ${reportingPeriod.type} type.`);
      }
    }
  }

  renderPeriod() {
    // Start of displayed date range should be:
    // - start of calculation date (cumulative math start date or start of last reporting period)
    const startOfCalculationDate = this.getStartDateMessage();
    const endOfCalculationDate = this.getEndDateMessage();
    const dash = this.getDashMessage();

    const latestReportingPeriodMessage = `${startOfCalculationDate}${dash}${endOfCalculationDate}`;

    return (
      <div className="reporting-period-type">
        <div className="reporting-period-latest">{latestReportingPeriodMessage}</div>
      </div>
    );
  }

  render() {
    const { measure, computedMeasure, dataRequestInFlight, showMetadata, showStatus, lens } = this.props;
    const { result } = computedMeasure;
    const reportingPeriod = _.get(measure, 'metricConfig.reportingPeriod', {});

    const enableGlobalFiltersMeasures = FeatureFlags.valueOrDefault('enable_global_filters_measures', false);

    const spinner = (
      <div className="measure-result-spinner-container">
        {/*
            This used to be a real spinner, but we ran into baffling IE behavior at the last minute
            (EN-22336). Due to time pressure, we replaced the spinner with static text. EN-22374 tracks
            the real fix.
         */}
        <div>{I18n.t('calculating', { scope })}</div>
      </div>
    );

    const activeStatus = _.get(computedMeasure, 'result.status');

    const busy = dataRequestInFlight || !measure;
    const statusClass = `status-${activeStatus}`;

    const rootClasses = classNames({
      'measure-result-card': enableGlobalFiltersMeasures,
      'pre-forge-measure-result-card': !enableGlobalFiltersMeasures,
      'with-metadata': showMetadata,
      status: showStatus && activeStatus,
      'measure-ended': measure && hasMeasureEnded(measure),
      [statusClass]: !!activeStatus
    });

    // Checks to see if there is a current reporting period. If there is not, don't render the
    // card. Solves issue with trying to reportingPeriod start in the future.
    const isStartDateValid = ReportingPeriods.isStartDateValid(reportingPeriod);

    let content = result && result.value && isStartDateValid ? this.renderResult() : this.renderError();
    content = busy ? spinner : content;

    if (!enableGlobalFiltersMeasures) {
      const title = busy ? null : this.renderTitle();
      const viewMeasureLink = busy ? null : this.renderViewMeasureLink();
      return (
        <div className={rootClasses}>
          {title}
          <div className="measure-result-card-content">
            {content}
            {viewMeasureLink}
          </div>
        </div>
      );
    }

    const { measureLink, measureText } = this.getMeasureLink() ?? {};

    let filterBarProps: VerticalViewModeFilterBarProps | undefined = undefined;

    if (measure?.dataSourceLensUid && measure?.metricConfig?.additionalFilters?.length) {
      filterBarProps = {
        columns: { [measure.dataSourceLensUid]: computedMeasure.columns ?? [] },
        // Measures cannot have region global filters
        computedColumns: [],
        dataSource: [
          {
            datasetUid: measure.dataSourceLensUid,
            domain: measure.domain ?? getCurrentDomain()
          }
        ],
        filterParameterConfigurations: measure.metricConfig.additionalFilters.map((filter) => ({
          type: FilterItemType.FILTER,
          config: filter
        })),
        // We only show global filters, which cannot be updated at the card level.
        onUpdate: _.noop,
        onUpdateAllFilterParameters: _.noop,
        onUpdateSingleFilterParameter: _.noop
      };
    }

    return (
      <ForgeCard className={rootClasses}>
        {showMetadata && (
          <FilterableCardHeader
            title={getMeasureCardTitle(measure, lens)}
            viewSourceDataLink={measureLink}
            viewSourceDataText={measureText}
            filterBarProps={filterBarProps}
          />
        )}
        {content}
      </ForgeCard>
    );
  }
}

// because we're only displaying the last period, lastPeriodOnly tells
// withComputedMeasure not to bother calculating the previous periods
export default withComputedMeasure({ lastPeriodOnly: true })(MeasureResultCard);
