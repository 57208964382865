// Linter migration tech debt.
/* eslint-disable padded-blocks */
/* eslint-disable no-use-before-define */
/* eslint-disable @typescript-eslint/explicit-function-return-type */

import _ from 'lodash';
import { Dispatcher } from 'flux';
import { ActionCreators as UndoActionCreators } from 'redux-undo';

import StorytellerUtils from 'lib/StorytellerUtils';
import { tocComponentIndex } from 'lib/TableOfContentsUtils.js';

import { BlockComponent, FluxPayload, StorySerialized } from 'types';
import Store from 'Store';
import Actions from 'Actions';
import { isLayoutEqual } from 'lib/FlexibleLayoutUtils';
import { selectors as storySelectors } from 'store/selectors/StorySelectors';

import { StorytellerReduxStore } from 'store/StorytellerReduxStore';
import {
  deleteStoryBlock,
  moveStoryBlockUp,
  setPublishedStory,
  setStoryDescription,
  setStoryTitle,
  updateStory,
  setStoryTileConfig,
  setStoryPermissions,
  moveStoryBlockDown,
  toggleStoryBlockPresentationVisibility,
  insertStoryBlock,
  insertStoryTableOfContents,
  insertBlockComponent,
  deleteBlockComponent,
  updateBlockComponent,
  updateBlockComponentLayout,
  updateBlockColor,
  updateStoryTheme,
  updateStoryLayout,
  resetComponent,
  copyComponent,
  pasteComponent,
  chosenMoveComponentDestination
} from 'store/reducers/StoryReducer';
import {
  createStory,
  replaceStoryFromJsonImport,
  prepareStoryForTemplate,
  replaceStoryFromTemplate,
  updateStoryDataSource,
  removeStoryDataSource,
  addFilterParameterConfiguration,
  deleteFilterParameterConfiguration,
  updateFilterParameterConfiguration,
  updateAllFilterParameterConfigurations
} from 'store/TopLevelActions';
import { selectors as digestSelectors } from 'store/selectors/DigestSelectors';
import { createBlockConfirmation } from 'store/reducers/BlockRemovalConfirmationReducer';
import { saveStory } from 'store/TopLevelActions';
import { storyReducerStoriesSelector, storyReducerBlocksSelector } from 'store/TopLevelSelector';
import { storySavedAfterNewSourcesAdded } from 'store/reducers/DataSourceReducer';

// @ts-expect-error TS(7009) FIXME: 'new' expression, whose target lacks a construct s... Remove this comment to see the full error message
export const storyStore = StorytellerUtils.export(new StoryStore(), 'storyteller.storyStore');

/**
 * A store for story data.
 * Each story is comprised of blocks, which in turn
 * are comprised of one or more component.
 *
 * For the purposes of the store API, blocks are represented by client-side IDs that
 * remain constant until page reload. These IDs are not to be confused with the IDs
 * in the database, which are not exposed via any API. Those server-side IDs change
 * every time the story is saved, and no effort is expended attempting to sync client
 * and server block IDs.
 */
export default function StoryStore(dispatcher?: Dispatcher<any>): void {
  // TODO. Not sure how to express this kind of prototype inheritance in TS.
  // ts-migrate FIXME: no ignore required
  _.extend(this, new Store(dispatcher));

  const self = this; // eslint-disable-line @typescript-eslint/no-this-alias

  this.register(function (payload: FluxPayload) {
    switch (payload.action) {
      case Actions.STORY_CREATE: {
        StorytellerReduxStore.dispatch(createStory(payload));
        const blockIds = self.getStoryBlockIds(payload.data.uid);

        // Create a list of blocks that need the confirmation
        // dialog for the newly created story
        StorytellerReduxStore.dispatch(createBlockConfirmation(blockIds));
        self._emitChange();
        break;
      }
      case Actions.STORY_REPLACE_FROM_JSON_IMPORT: {
        StorytellerReduxStore.dispatch(replaceStoryFromJsonImport(payload));
        self._emitChange();
        break;
      }
      case Actions.STORY_REPLACE_FROM_TEMPLATE: {
        StorytellerReduxStore.dispatch(prepareStoryForTemplate(payload));
        self._emitChange();
        StorytellerReduxStore.dispatch(replaceStoryFromTemplate(payload));
        break;
      }
      case Actions.STORY_UPDATED: {
        StorytellerReduxStore.dispatch(updateStory(payload));
        self._emitChange();
        break;
      }
      case Actions.STORY_SAVED: {
        StorytellerReduxStore.dispatch(saveStory(payload));
        self._emitChange();
        break;
      }
      case Actions.STORY_SET_TITLE: {
        StorytellerReduxStore.dispatch(setStoryTitle(payload));
        self._emitChange();
        break;
      }
      case Actions.STORY_SET_DESCRIPTION: {
        StorytellerReduxStore.dispatch(setStoryDescription(payload));
        self._emitChange();
        break;
      }
      case Actions.STORY_SET_TILE_CONFIG: {
        StorytellerReduxStore.dispatch(setStoryTileConfig(payload));
        self._emitChange();
        break;
      }
      case Actions.STORY_SET_PERMISSIONS: {
        StorytellerReduxStore.dispatch(setStoryPermissions(payload));
        self._emitChange();
        break;
      }
      case Actions.STORY_SET_PUBLISHED_STORY: {
        StorytellerReduxStore.dispatch(setPublishedStory(payload));
        self._emitChange();
        break;
      }
      case Actions.STORY_MOVE_BLOCK_UP: {
        StorytellerReduxStore.dispatch(moveStoryBlockUp(payload));
        self._emitChange();
        break;
      }
      case Actions.STORY_MOVE_BLOCK_DOWN: {
        StorytellerReduxStore.dispatch(moveStoryBlockDown(payload));
        self._emitChange();
        break;
      }
      case Actions.STORY_TOGGLE_BLOCK_PRESENTATION_VISIBILITY: {
        StorytellerReduxStore.dispatch(toggleStoryBlockPresentationVisibility(payload));
        self._emitChange();
        break;
      }
      case Actions.STORY_DELETE_BLOCK: {
        StorytellerReduxStore.dispatch(deleteStoryBlock(payload));
        self._emitChange();
        break;
      }
      case Actions.STORY_INSERT_BLOCK: {
        StorytellerReduxStore.dispatch(insertStoryBlock(payload));
        if (tocComponentIndex(payload.blockContent) === -1) {
          self._emitChange();
        }
        break;
      }
      case Actions.STORY_INSERT_TABLE_OF_CONTENTS: {
        StorytellerReduxStore.dispatch(insertStoryTableOfContents(payload));
        self._emitChange();
        break;
      }
      case Actions.BLOCK_INSERT_COMPONENT: {
        StorytellerReduxStore.dispatch(insertBlockComponent(payload));
        self._emitChange();
        break;
      }
      case Actions.BLOCK_DELETE_COMPONENT: {
        StorytellerReduxStore.dispatch(deleteBlockComponent(payload));
        self._emitChange();
        break;
      }
      case Actions.BLOCK_UPDATE_COMPONENT: {
        const { blockId, componentIndex, type, value, layout } = payload;
        const { component } = storySelectors.getBlockAndComponent(
          StorytellerReduxStore.getState(),
          blockId,
          componentIndex
        );
        StorytellerReduxStore.dispatch(updateBlockComponent(payload));

        // Makes sure we only update if the component has actually changed
        // This is kind of a bandaid fix for the fact that we are dispatching
        // this action way too often.
        if (
          !_.isEqual(component.type, type) ||
          !_.isEqual(component.value, value) ||
          !isLayoutEqual(component.layout, layout)
        ) {
          self._emitChange();
        }
        break;
      }
      case Actions.BLOCK_UPDATE_COMPONENTS_LAYOUT: {
        StorytellerReduxStore.dispatch(updateBlockComponentLayout(payload));
        self._emitChange();
        break;
      }
      case Actions.BLOCK_UPDATE_COLOR: {
        StorytellerReduxStore.dispatch(updateBlockColor(payload));
        self._emitChange();
        break;
      }
      case Actions.HISTORY_UNDO: {
        StorytellerReduxStore.dispatch(UndoActionCreators.undo());
        self._emitChange();
        break;
      }
      case Actions.HISTORY_REDO: {
        StorytellerReduxStore.dispatch(UndoActionCreators.redo());
        self._emitChange();
        break;
      }
      case Actions.STORY_UPDATE_THEME: {
        StorytellerReduxStore.dispatch(updateStoryTheme(payload));
        self._emitChange();
        break;
      }
      case Actions.STORY_UPDATE_LAYOUT: {
        StorytellerReduxStore.dispatch(updateStoryLayout(payload));
        self._emitChange();
        break;
      }
      case Actions.STORY_DATA_SOURCE_UPDATED: {
        StorytellerReduxStore.dispatch(updateStoryDataSource(payload));
        self._emitChange();
        break;
      }
      case Actions.STORY_DATA_SOURCE_REMOVED: {
        StorytellerReduxStore.dispatch(removeStoryDataSource(payload));
        self._emitChange();
        break;
      }
      case Actions.FILTER_PARAMETER_CONFIGURATION_ADDED: {
        StorytellerReduxStore.dispatch(addFilterParameterConfiguration(payload));
        self._emitChange();
        break;
      }
      case Actions.FILTER_PARAMETER_CONFIGURATION_DELETED: {
        StorytellerReduxStore.dispatch(deleteFilterParameterConfiguration(payload));
        self._emitChange();
        break;
      }
      case Actions.FILTER_PARAMETER_CONFIGURATION_UPDATED: {
        StorytellerReduxStore.dispatch(updateFilterParameterConfiguration(payload));
        self._emitChange();
        break;
      }
      case Actions.ALL_FILTER_PARAMETER_CONFIGURATIONS_UPDATED: {
        StorytellerReduxStore.dispatch(updateAllFilterParameterConfigurations(payload));
        self._emitChange();
        break;
      }
      case Actions.RESET_COMPONENT: {
        payload.type = 'assetSelector';
        payload.value = {};
        StorytellerReduxStore.dispatch(resetComponent(payload));
        self._emitChange();
        break;
      }
      case Actions.COPY_COMPONENT: {
        StorytellerReduxStore.dispatch(copyComponent(payload));
        self._emitChange();
        break;
      }
      case Actions.PASTE_COMPONENT: {
        // TODO fix types
        // @ts-expect-error TS(2769) FIXME: No overload matches this call.
        StorytellerReduxStore.dispatch(pasteComponent(payload));
        self._emitChange();
        break;
      }
      case Actions.MOVE_COMPONENT_DESTINATION_CHOSEN: {
        StorytellerReduxStore.dispatch(chosenMoveComponentDestination(payload));
        self._emitChange();
        break;
      }
      case Actions.GLOBAL_FILTER_SAVE_STORY_AFTER_NEW_SOURCES_ADDED: {
        StorytellerReduxStore.dispatch(storySavedAfterNewSourcesAdded());
        self._emitChange();
        break;
      }
      default:
        break;
    }
  });

  /**
   * Public methods
   */

  this.doesStoryExist = (storyUid: string) => storySelectors.doesStoryExist(reduxState(), storyUid);
  this.isStoryPublic = (storyUid: string) => storySelectors.isStoryPublic(reduxState(), storyUid);
  this.isStoryInternal = (storyUid: string) => storySelectors.isStoryInternal(reduxState(), storyUid);
  this.getStoryPublishedStory = (storyUid: string) =>
    storySelectors.getStoryPublishedStory(reduxState(), storyUid);
  this.isDraftUnpublished = (storyUid: string) => storySelectors.isDraftUnpublished(reduxState(), storyUid);
  this.draftHasChanges = (storyUid: string) => storySelectors.draftHasChanges(reduxState(), storyUid);
  this.getStoryTitle = (storyUid: string) => storySelectors.getStoryTitle(reduxState(), storyUid);
  this.getStory = (storyUid: string) => storySelectors.getStory(reduxState(), storyUid);
  this.getStoryDescription = (storyUid: string) => storySelectors.getStoryDescription(reduxState(), storyUid);
  this.getStoryTileTitle = (storyUid: string) => storySelectors.getStoryTileTitle(reduxState(), storyUid);
  this.getStoryTileDescription = (storyUid: string) =>
    storySelectors.getStoryTileDescription(reduxState(), storyUid);
  this.getStoryTheme = (storyUid: string) => storySelectors.getStoryTheme(reduxState(), storyUid);
  this.getStoryLayout = (storyUid: string) => storySelectors.getStoryLayout(reduxState(), storyUid);
  this.getStoryUpdatedAt = (storyUid: string) => storySelectors.getStoryUpdatedAt(reduxState(), storyUid);
  this.getStoryPermissions = (storyUid: string) => storySelectors.getStoryPermissions(reduxState(), storyUid);

  this.getStoryBlockIds = (storyUid: string) => storySelectors.getStoryBlockIds(reduxState(), storyUid);
  this.getStoryBlockAtIndex = (storyUid: string, index: number) =>
    storySelectors.getStoryBlockAtIndex(reduxState(), storyUid, index);
  this.getStoryBlockIdAtIndex = (storyUid: string, index: number) =>
    storySelectors.getStoryBlockIdAtIndex(reduxState(), storyUid, index);
  this.getBlockLayout = (blockId: string) => storySelectors.getBlockLayout(reduxState(), blockId);
  this.getBlockComponents = (blockId: string): BlockComponent[] =>
    storySelectors.getBlockComponents(reduxState(), blockId);
  this.isBlockPresentable = (blockId: string) => storySelectors.isBlockPresentable(reduxState(), blockId);
  this.getBlockBackgroundColor = (blockId: string) =>
    storySelectors.getBlockBackgroundColor(reduxState(), blockId);
  this.getBlockComponentAtIndex = (blockId: string, index: number): BlockComponent =>
    _.cloneDeep(storySelectors.getBlockComponentAtIndex(reduxState(), blockId, index));

  this.serializeStory = (storyUid: string): StorySerialized =>
    storySelectors.serializeStory(reduxState(), storyUid);

  this.isBlockRestrictedToMeasureCardsOnly = (blockId: string) =>
    storySelectors.isBlockRestrictedToMeasureCardsOnly(reduxState(), blockId);

  /**
   * Private methods
   */

  const reduxState = () => StorytellerReduxStore.getState();
}
