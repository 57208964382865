import React from 'react';
import { IMenuSelectEventData } from '@tylertech/forge';
import { ForgeToolbar } from '@tylertech/forge-react';

import TruncatedText from '../TruncatedText';
import MoreOptionsMenu from './MoreOptionsMenu';
import VerticalViewModeFilterBar, { VerticalViewModeFilterBarProps } from './VerticalViewModeFilterBar';

import './filterable-card-header.scss';

/**
 * This component renders a header for a filterable card, such as a visualization or a measure.
 * The card must be filterable by the common FilterBar component.
 * @returns
 */
const FilterableCardHeader = ({
  description,
  title,

  filterBarProps,

  additionalOptions,
  kebabMenuOptionBuilder,
  onKebabMenuSelect,
  viewSourceDataLink,
  viewSourceDataText
}: {
  /** Description for the card */
  description?: string;
  /** Title for the card */
  title?: string;

  /** The smallest set of filter bar props needed to render in view mode. */
  filterBarProps?: VerticalViewModeFilterBarProps;

  /** Additional options to put into the kebab menu */
  additionalOptions?: unknown[];
  kebabMenuOptionBuilder?: (option: { value: string; label: string }, listItem: any) => void;
  /** Called when an option in kebab menu is selected */
  onKebabMenuSelect?: (customEvent: CustomEvent<IMenuSelectEventData>) => void;
  viewSourceDataLink?: string;
  viewSourceDataText?: string;
}) => {
  return (
    <ForgeToolbar className="filterable-card-header" no-border>
      <div slot="start" className="filterable-card-header-metadata">
        {title && <TruncatedText className="filterable-card-header-title" text={title} lines={2} />}
        {description && (
          <TruncatedText className="filterable-card-header-description" text={description} lines={1} />
        )}
      </div>
      <div slot="end">
        {filterBarProps && <VerticalViewModeFilterBar {...filterBarProps} />}
        <MoreOptionsMenu
          additionalOptions={additionalOptions}
          onKebabMenuSelect={onKebabMenuSelect}
          kebabMenuOptionBuilder={kebabMenuOptionBuilder}
          viewSourceDataLink={viewSourceDataLink}
          viewSourceDataText={viewSourceDataText}
        />
      </div>
    </ForgeToolbar>
  );
};

export default FilterableCardHeader;
