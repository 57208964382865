import React, { ReactElement } from 'react';
import isEmpty from 'lodash/isEmpty';
import { ForgeIcon } from '@tylertech/forge-react';
import classNames from 'classnames';
import { PicklistOption } from 'common/components/Picklist';
import Dropdown from 'common/components/Dropdown';
import I18n from 'common/i18n';
import { isFlexibleStory, shouldUseReactComponentBase } from 'lib/FlexibleLayoutUtils';

import { ComponentType } from 'types';
import { COMPONENT_ACTION_TYPES } from 'lib/Constants';
import Actions from 'Actions';
import Environment from 'StorytellerEnvironment';
import { dispatcher } from 'Dispatcher';
import { actionComponentStore } from 'editor/stores/ActionComponentStore';
import { storyStore } from 'editor/stores/StoryStore';
import { BlockComponent } from 'types';

export interface ComponentEditMenuProps {
  blockId: string;
  componentIndex: number;
  componentData: BlockComponent;
  editMode: boolean;
}

/**
 * Renders a block component menu that dispatches several component-level
 * actions, such as editing the component, and moving/swapping components.
 *
 * This can be located in the UI by dropping a media block in the story, configuring
 * it, and then mousing over the result. Buttons anchored on the top-right of the
 * component container will appear.
 */
const ComponentEditMenu: React.FC<ComponentEditMenuProps> = (props) => {
  const { blockId, componentData, componentIndex, editMode } = props;

  if (!editMode) {
    return null;
  }

  const options = (): PicklistOption[] => {
    const o = [
      {
        title: I18n.t('editor.components.edit_controls.move'),
        group: I18n.t('editor.components.edit_controls.groups.actions'),
        icon: 'cursor_move',
        value: 'move',
        render: renderKebabPicklistOption
      }
    ];

    if (isNotAssetSelector()) {
      o.push({
        title: I18n.t('editor.components.edit_controls.copy'),
        group: I18n.t('editor.components.edit_controls.groups.actions'),
        icon: 'content_copy',
        value: 'copy',
        render: renderKebabPicklistOption
      });
    }

    if (shouldUsePasteButton()) {
      o.push({
        title: I18n.t('editor.components.edit_controls.paste'),
        group: I18n.t('editor.components.edit_controls.groups.actions'),
        icon: 'content_paste',
        value: 'paste',
        render: renderKebabPicklistOption
      });
    }

    if (isNotAssetSelector()) {
      o.push({
        title: I18n.t('editor.components.edit_controls.reset'),
        group: I18n.t('editor.components.edit_controls.groups.actions'),
        icon: 'close',
        value: 'reset',
        render: renderKebabPicklistOption
      });
    }

    return o;
  };

  const shouldUsePasteButton = (): boolean => {
    try {
      return !storyStore.isBlockRestrictedToMeasureCardsOnly(blockId);
    } catch (e) {
      // This mainly happens in tests, where we render components without the whole story.
      return true;
    }
  };

  const isNotAssetSelector = (): boolean => {
    return componentData.type !== ComponentType.ASSET_SELECTOR;
  };

  const clickEditButton = (): void => {
    dispatcher.dispatch({
      action: Actions.ASSET_SELECTOR_EDIT_EXISTING_ASSET_EMBED,
      blockId,
      componentIndex
    });
  };

  const clickUpdateTableButton = (): void => {
    const storyUid = Environment.STORY_UID;

    dispatcher.dispatch({
      action: Actions.STORY_INSERT_TABLE_OF_CONTENTS,
      storyUid: storyUid,
      blockWithToCId: blockId
    });
  };

  const selectKebabPicklistItem = (option: PicklistOption): void => {
    switch (option.value) {
      case 'reset':
        dispatcher.dispatch({
          action: Actions.RESET_COMPONENT,
          blockId,
          componentIndex
        });
        break;
      case 'move':
        dispatcher.dispatch({
          action: Actions.ACTION_COMPONENT_START,
          blockId,
          componentIndex,
          actionType: COMPONENT_ACTION_TYPES.MOVE
        });
        break;
      case 'copy':
        dispatcher.dispatch({
          action: Actions.COPY_COMPONENT,
          blockId,
          componentIndex
        });
        break;
      case 'paste':
        dispatcher.dispatch({
          action: Actions.PASTE_COMPONENT,
          blockId,
          componentIndex
        });
        break;
      default:
        break;
    }
  };

  const renderKebabPicklistOption = (option: PicklistOption): ReactElement => {
    return (
      <div>
        <ForgeIcon name={option.icon} />
        <span>{option.title}</span>
      </div>
    );
  };

  const renderKebabPicklist = (): ReactElement => {
    const attributes = {
      onSelection: selectKebabPicklistItem,
      options: options(),
      placeholder: renderKebabButton,
      skipPositionPicklist: true
    };

    return <Dropdown {...attributes} />;
  };

  const renderKebabButton = (): ReactElement => {
    const attributes = {
      className: 'component-edit-controls-btn component-edit-controls-kebab-btn'
    };
    return (
      <button {...attributes} data-testid="component-edit-controls-kebab-btn">
        <span className="icon-kebab" />
      </button>
    );
  };

  const renderEditButton = (): ReactElement => {
    return (
      <button
        className="component-edit-controls-btn component-edit-controls-edit-btn"
        onClick={clickEditButton}
      >
        {I18n.t('editor.components.edit_controls.button')}
      </button>
    );
  };

  const renderUpdateTableButton = (): ReactElement => {
    return (
      <button
        className="component-edit-controls-btn component-edit-controls-update-table-btn"
        onClick={clickUpdateTableButton}
      >
        {I18n.t('editor.components.table_of_contents.update_table')}
      </button>
    );
  };

  const isEditButtonSupported = () => {
    if (componentData.type === ComponentType.HERO) {
      return editMode && !isEmpty(componentData.value) && !isEmpty(componentData.value.url);
    }

    return (
      editMode &&
      ![
        ComponentType.ASSET_SELECTOR,
        ComponentType.AUTHOR,
        ComponentType.HORIZONTAL_RULE,
        ComponentType.HTML,
        ComponentType.HTML_TABLE_OF_CONTENTS,
        ComponentType.GLOBAL_FILTER
      ].includes(componentData.type)
    );
  };

  const updateTableButtonSupported = componentData.type === ComponentType.HTML_TABLE_OF_CONTENTS;

  // NOTE: The same criteria apply for a component's ability to be reset, so
  // the ActionComponentStore check is doing double duty.
  const kebabEnabled = actionComponentStore.isComponentValidMoveSource(componentData.type);
  const kebabButton = kebabEnabled ? renderKebabPicklist() : null;
  const editButton = isEditButtonSupported() ? renderEditButton() : null;
  const updateTableButton = updateTableButtonSupported ? renderUpdateTableButton() : null;

  if (!editButton && !updateTableButton && !kebabButton) {
    return null;
  }

  const renderInnerComponentEditMenu = (): ReactElement => {
    return (
      <div className="component-edit-controls" data-testid="component-edit-controls">
        {editButton}
        {updateTableButton}
        {kebabButton}
      </div>
    );
  };

  const classes = classNames('component-edit-controls-container component-base', {
    'with-dragger': isFlexibleStory() && componentData.type !== ComponentType.GLOBAL_FILTER
  });

  return shouldUseReactComponentBase() ? (
    <div className={classes}>{renderInnerComponentEditMenu()}</div>
  ) : (
    renderInnerComponentEditMenu()
  );
};

export default ComponentEditMenu;
