import { debounce, isUndefined } from 'lodash';
import { ListenerMiddleware } from '@reduxjs/toolkit';
import Environment from 'StorytellerEnvironment';
import StorytellerUtils from 'lib/StorytellerUtils';
import { AUTOSAVE_DEBOUNCE_TIME_IN_MILLISECONDS } from 'lib/Constants';
import StoryDraftCreator from 'editor/StoryDraftCreator';
import FeatureFlags from 'common/feature_flags';
import { isStorySaveInProgress } from 'store/selectors/StorySaveStatusSelectors';
import { StorytellerState } from 'store/StorytellerReduxStore';
import Actions from 'Actions';
import { userSessionStore } from 'editor/stores/UserSessionStore';
import { selectors } from 'store/selectors/StorySelectors';
import {
  deleteBlockComponent,
  insertBlockComponent,
  pasteComponent,
  updateBlockColor,
  updateBlockComponent,
  updateBlockComponentLayout,
  chosenMoveComponentDestination,
  resetComponent,
  deleteStoryBlock,
  insertStoryBlock,
  insertStoryTableOfContents,
  moveStoryBlockDown,
  moveStoryBlockUp,
  setStoryDescription,
  setStoryPermissions,
  setStoryTileConfig,
  setStoryTitle,
  toggleStoryBlockPresentationVisibility,
  updateStoryLayout,
  updateStoryTheme
} from 'store/reducers/StoryReducer';
import {
  addFilterParameterConfiguration,
  deleteFilterParameterConfiguration,
  updateFilterParameterConfiguration,
  removeGlobalFilter,
  updateAllFilterParameterConfigurations,
  removeStoryDataSource,
  updateStoryDataSource,
  replaceStoryFromJsonImport,
  replaceStoryFromTemplate
} from 'store/TopLevelActions';
import { storySavedAfterNewSourcesAdded } from 'store/reducers/DataSourceReducer';
import { ActionCreators } from 'redux-undo';

// Autosave actions are opt in. If you want to add an action to the list of actions that trigger an autosave, add it here.
const AUTOSAVE_ACTIONS = [
  ActionCreators.redo()?.type,
  ActionCreators.undo()?.type,
  Actions.STORY_SAVE_METADATA,
  addFilterParameterConfiguration?.type,
  deleteFilterParameterConfiguration?.type,
  chosenMoveComponentDestination?.type,
  deleteBlockComponent?.type,
  deleteStoryBlock?.type,
  insertBlockComponent?.type,
  insertStoryBlock?.type,
  insertStoryTableOfContents?.type,
  moveStoryBlockDown?.type,
  moveStoryBlockUp?.type,
  pasteComponent?.fulfilled?.type,
  removeGlobalFilter?.type,
  removeStoryDataSource?.type,
  replaceStoryFromJsonImport?.type,
  replaceStoryFromTemplate?.type,
  resetComponent?.type,
  setStoryDescription?.type,
  setStoryPermissions?.type,
  setStoryTileConfig?.type,
  setStoryTitle?.type,
  storySavedAfterNewSourcesAdded?.type,
  toggleStoryBlockPresentationVisibility?.type,
  updateAllFilterParameterConfigurations?.type,
  updateBlockColor?.type,
  updateBlockComponent?.type,
  updateBlockComponentLayout?.type,
  updateFilterParameterConfiguration?.type,
  updateStoryDataSource?.type,
  updateStoryLayout?.type,
  updateStoryTheme?.type
];

const autosaveMiddleware: ListenerMiddleware = (store) => (next) => (action) => {
  // Order of this does matter. Needs to happen before the saveOnceSettled call.
  next(action);

  if (isUndefined(action.type)) return;

  const state = store.getState();

  if (AUTOSAVE_ACTIONS.includes(action.type) && Environment.EDIT_MODE) {
    saveOnceSettled(state);
    userSessionStore.addChangeListener(onReconnect);
    window.addEventListener('online', () => onReconnect(state as StorytellerState), { once: true });
  }
};

const isAutosaveDisabled = () => StorytellerUtils.queryParameterMatches('autosave', 'false');
let disabled = isAutosaveDisabled();
let autosaveLock = false;
const autosaveDebounceMsec = AUTOSAVE_DEBOUNCE_TIME_IN_MILLISECONDS;
const saveOnceSettled = debounce((state) => saveASAP(state as StorytellerState), autosaveDebounceMsec);

const saveASAP = (state: StorytellerState) => {
  const storyUid = Environment.STORY_UID;

  if (disabled || !selectors.isStorySavePossible(state)) {
    // Give up.
    return;
  }

  if (isStorySaveInProgress(state) || autosaveLock) {
    // Try again in a bit.
    saveOnceSettled(state);
    return;
  }

  // Ensure that only one autosave invocation is running at a time.
  autosaveLock = true;

  // Make up to three attempts to save the story, then give up.
  // TODO: EN-65103: we should re-examine if there is a way to make this code simplier
  let savePromise: Promise<any> = Promise.reject();
  const createRetryDelay = (error: Error) => {
    return new Promise(function (resolve, reject) {
      setTimeout(reject.bind(null, error), autosaveDebounceMsec);
    });
  };
  for (let attempt = 1, limit = 3; attempt <= limit; attempt++) {
    savePromise = savePromise
      .catch(() => {
        if (storyUid) {
          return StoryDraftCreator.saveDraft(storyUid);
        }
        return Promise.reject(new Error('Invalid storyUid'));
      })
      .catch(createRetryDelay);
  }
  savePromise
    .catch(function (error) {
      // If this case is reached, it implies either that the user's session has
      // been lost or that another user has edited the story and poisoned the
      // local state. Abort all further autosave invocations until either the
      // user session has been restored (see below) or the user reloads the page
      // for a fresh local state.
      disabled = true;
      console.error('Autosave failed due to conflicting edits or missing user session.', error);
    })
    .then(function () {
      // Release the lock so that a new autosave invocation can proceed.
      autosaveLock = false;
    });
};

const onReconnect = (state: StorytellerState) => {
  // When the user session is re-established, reset the disabled flag and
  // trigger autosave.
  if (userSessionStore.isSessionValid()) {
    disabled = isAutosaveDisabled();
    saveOnceSettled(state);
  }
};

export default autosaveMiddleware;
