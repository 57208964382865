import { ClientContextVariable } from 'common/types/clientContextVariable';

export const mergeClientContextVariables = (
  overrides: ClientContextVariable[],
  all: ClientContextVariable[]
): ClientContextVariable[] => {
  return all.map((variable) => {
    const override = overrides.find(
      ({ name, viewId }) => name === variable.name && viewId === variable.viewId
    );
    if (override?.overrideValue) {
      return { ...variable, overrideValue: override.overrideValue };
    }
    return variable;
  });
};
