// Vendor Imports
import _ from 'lodash';
import React, { Component } from 'react';

// Project Imports
import I18n from 'common/i18n';
import Button, { SIZES, VARIANTS } from 'common/components/Button';
import { resetAllFiltersToDefaults } from 'common/components/FilterBar/lib/allFilters';
import { SoqlFilter } from './SoqlFilter';
import { FilterBarColumns } from './index';
import { FilterBarPendoIds } from './types';
import { DataSourceCCVs, FilterParameterConfiguration, FilterItemType } from 'common/types/reportFilters';
import { findClientContextVariableDefault } from 'common/components/FilterBar/lib/allFilters';
import FeatureFlags from 'common/feature_flags';

// Constants
const scope = 'shared.components.filter_bar';

export interface ResetFiltersButtonProps {
  clientContextVariables: DataSourceCCVs | undefined;
  columns: FilterBarColumns;
  editMode: boolean;
  filterParameterConfigurations?: FilterParameterConfiguration[];
  isReadOnly?: boolean;
  onReset: (updated: SoqlFilter[]) => void;
  onResetAll: (updated: FilterParameterConfiguration[]) => void;
  pendoIds?: FilterBarPendoIds;
}

export class ResetFiltersButton extends Component<ResetFiltersButtonProps> {
  onClick = () => {
    const {
      clientContextVariables,
      columns,
      editMode,
      filterParameterConfigurations,
      isReadOnly,
      onReset,
      onResetAll
    } = this.props;
    if (filterParameterConfigurations) {
      const resetFilters = resetAllFiltersToDefaults(
        filterParameterConfigurations,
        columns,
        clientContextVariables,
        editMode,
        isReadOnly
      );
      onResetAll(resetFilters);

      // This is needed for visualization & measures filters to be reset
      // with the onUpdate callback
      onReset(
        resetFilters.map((filter) => {
          return filter.config as SoqlFilter;
        })
      );
    }
  };

  isDisabled = () => {
    const { clientContextVariables, editMode, filterParameterConfigurations, isReadOnly } = this.props;
    const enableParameterDropdownsInStories = FeatureFlags.valueOrDefault(
      'enable_parameter_dropdowns_in_stories',
      false
    );
    return !_.some(filterParameterConfigurations, (item) => {
      if (item.type === FilterItemType.FILTER) {
        const isViewable = !isReadOnly || !item.config.isHidden;
        const hasValue = !_.isEmpty(item.config.arguments);
        const canValueBeChanged = !item.config.isOverridden;
        return isViewable && hasValue && canValueBeChanged;
      } else if (enableParameterDropdownsInStories && !item.config.isHidden) {
        const defaultValue = findClientContextVariableDefault(clientContextVariables, item.config, editMode);
        return defaultValue !== item.config.overrideValue;
      }
      return false;
    });
  };

  render() {
    const { pendoIds } = this.props;
    return (
      <div
        className="reset-filters"
        id={pendoIds?.resetAllFilters}
        data-testid="filter-bar-reset-all-filters"
      >
        <Button
          variant={VARIANTS.LINK}
          size={SIZES.SMALL}
          inverse
          className="btn-reset-filters"
          disabled={this.isDisabled()}
          onClick={this.onClick}
        >
          {I18n.t('clear_all_filters', { scope })}
        </Button>
      </div>
    );
  }
}

export default ResetFiltersButton;
