import $ from 'jquery';
import _ from 'lodash';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import { assert, assertHasProperties } from 'common/assertions';

import { COMPONENT_ACTION_TYPES } from 'lib/Constants';
import { isFlexibleStory } from 'lib/FlexibleLayoutUtils';
import { ComponentType } from 'types';
import { ComponentProps } from '../types';
import './componentResizable';
import './withLayoutHeightFromComponentData';
import ComponentEditMenu from './ComponentEditMenu';
import ComponentActionOverlay from './componentActionOverlay';
import ComponentDraggerControl, { DraggerControlProps } from 'editor/components/ComponentDraggerControl';
import { StorytellerReduxStore } from 'store/StorytellerReduxStore';

// TODO EN-41251: We should make it possible to do component renderers in React
// so we don't need to decide whether to update ourselves.
$.fn.componentBase = componentBase;

/*
 * Supported props:
 *
 * componentData (required)
 * blockId (required)
 * componentIndex (required)
 *
 * isComponentValidMoveDestination
 * isUserChoosingMoveDestination
 * isComponentBeingMoved
 *
 * editMode
 * resizeSupported
 * resizeOptions
 * defaultHeight
 */

export default function componentBase(props: ComponentProps): JQuery {
  assert(arguments.length === 1, 'Invalid invocation of componentBase');
  assertHasProperties(props, 'componentData', 'blockId', 'componentIndex');

  props = _.extend(
    {
      // Note that it isn't possible to switch between edit
      // and non-edit modes (not that it would be hard to add,
      // we just don't need it now).
      editMode: false,

      resizeSupported: false,
      resizeOptions: {},

      // If not blank, establishes a default height for the component.
      // It is used if value.layout.height is not defined in componentData.
      defaultHeight: undefined,

      isComponentValidMoveDestination: false,
      isUserChoosingMoveDestination: false,
      isComponentBeingMoved: false
    },
    props
  );

  const {
    blockId,
    componentIndex,
    componentData,
    editMode,
    resizeSupported,
    resizeOptions,
    defaultHeight,
    isUserChoosingMoveDestination
  } = props;

  this.data('props', props);

  $(document.body).removeClass('action-overlay-active');

  this.toggleClass('editing', editMode);

  // TODO: EN-46001 - Disable withLayoutHeightFromComponentData in View Mode too
  // as heights will be determined by RGL height
  if (!isFlexibleStory()) {
    this.withLayoutHeightFromComponentData(componentData, defaultHeight);
  }

  if (editMode) {
    renderMoveActionOverlay(this);

    $(document.body).toggleClass('action-overlay-active', isUserChoosingMoveDestination);
  }

  if (editMode) {
    // Non-flex components use componentResizable
    // Flex components use ComponentDraggerControl below
    const needsComponentDragger = isFlexibleStory() && componentData.type !== ComponentType.GLOBAL_FILTER;
    const editControlsSelector = needsComponentDragger
      ? 'component-edit-controls-container with-dragger'
      : 'component-edit-controls-container';

    // for flexible stories, add ComponentDraggerControl bar before ComponentEditMenu
    if (needsComponentDragger) {
      let draggerContainer = this.find('.component-dragger-container');
      if (draggerContainer.length === 0) {
        draggerContainer = $('<div>', { class: 'component-dragger-container component-base' }).appendTo(this);
      }

      const draggerControlProps: DraggerControlProps = {
        blockId,
        componentIndex
      };
      // ComponentDraggerControl UI rendered here because it's tightly coupled with ComponentEditMenu css
      ReactDOM.render(
        <Provider store={StorytellerReduxStore}>
          <ComponentDraggerControl {...draggerControlProps} />
        </Provider>,
        draggerContainer[0]
      );
    }

    let container = this.find('.component-edit-controls-container.component-base');
    if (container.length === 0) {
      container = $('<div>', { class: `${editControlsSelector} component-base` }).appendTo(this);
    }

    const editMenuProps = {
      blockId: blockId as string,
      componentIndex: componentIndex as number,
      componentData,
      editMode
    };

    ReactDOM.render(<ComponentEditMenu {...editMenuProps} />, container[0]);
  }

  // TODO: Remove resizeSupported prop from componentBase and component renderers
  // once feature flag enable_flexible_story_layout is removed
  if (editMode && resizeSupported && !isFlexibleStory()) {
    this.componentResizable(resizeOptions);
  }

  return this;
}

function renderOverlayContainer(element: JQuery, action: string): JQuery {
  let $overlayContainer = element.find(`.component-edit-${action}-overlay-container`);

  if ($overlayContainer.length === 0) {
    $overlayContainer = $('<div>', { class: `component-edit-${action}-overlay-container` });
    element.append($overlayContainer);
  }

  return $overlayContainer;
}

function renderMoveActionOverlay(element: JQuery): void {
  const $overlayContainer = renderOverlayContainer(element, COMPONENT_ACTION_TYPES.MOVE);
  const { blockId, componentIndex } = element.data('props');

  const actionOverlayProps = {
    blockId,
    componentIndex
  };

  ReactDOM.render(<ComponentActionOverlay {...actionOverlayProps} />, $overlayContainer[0]);
}
