import React from 'react';
import { useDispatch } from 'react-redux';
import FeatureFlag from 'common/feature_flags';

import { deleteBlockComponent } from 'store/reducers/StoryReducer';
import { ForgeIcon, ForgeIconButton, ForgeTooltip } from '@tylertech/forge-react';

import I18n from 'common/i18n';
import Environment from 'StorytellerEnvironment';

import './index.scss';

export interface DraggerControlProps {
  blockId: string;
  componentIndex: number;
}

/**
 * The ComponentDraggerControl component will render the controls within a
 * React component or appended to DOM element
 */
const ComponentDraggerControl: React.FC<DraggerControlProps> = ({ blockId, componentIndex }) => {
  const dispatch = useDispatch();
  const enableReactComponentBase = FeatureFlag.valueOrDefault('enable_component_base_react', false);

  const onRemove = (): void => {
    dispatch(
      deleteBlockComponent({ storyUid: Environment.STORY_UID, blockId, componentIndex: componentIndex })
    );
  };

  const renderInnerComponentDragger = (): JSX.Element => {
    return (
      <div className="component-dragger-bar">
        <ForgeIconButton
          className="component-remove"
          onClick={onRemove}
          aria-label={I18n.t('shared.components.filter_bar.remove')}
        >
          <ForgeIcon name="delete"></ForgeIcon>
        </ForgeIconButton>
        <ForgeTooltip
          target={'.component-remove'}
          text={I18n.t('shared.components.filter_bar.remove')}
          position={'right'}
        />
        <ForgeIconButton className="component-dragger" aria-hidden>
          <ForgeIcon className="grab-handle" name="drag_indicator"></ForgeIcon>
        </ForgeIconButton>
      </div>
    );
  };

  return enableReactComponentBase ? (
    <div className="component-dragger-container component-base">{renderInnerComponentDragger()}</div>
  ) : (
    renderInnerComponentDragger()
  );
};

export default ComponentDraggerControl;
