import _ from 'lodash';
import React, { FunctionComponent } from 'react';
import { actionComponentStore } from 'editor/stores/ActionComponentStore';
import Actions from 'Actions';
import { dispatcher } from 'Dispatcher';
import { selectors } from 'store/selectors/ActionComponentSelectors';
import { StorytellerReduxStore } from 'store/StorytellerReduxStore';

import { COMPONENT_ACTION_TYPES } from 'lib/Constants';
import classnames from 'classnames';
import { shouldUseReactComponentBase } from 'lib/FlexibleLayoutUtils';

import I18n from 'common/i18n';

export interface ComponentActionOverlayProps {
  blockId?: string;
  componentIndex?: number;
}

const ComponentActionOverlay: FunctionComponent<ComponentActionOverlayProps> = ({
  blockId,
  componentIndex
}) => {
  const isUserChoosingMoveDestination = actionComponentStore.isUserChoosingMoveDestination();
  const isComponentBeingMoved = actionComponentStore.isComponentBeingMoved(blockId, componentIndex);
  const isComponentValidMoveDestination = actionComponentStore.isComponentValidMoveDestination(
    blockId,
    componentIndex
  );

  const isActive = isUserChoosingMoveDestination;
  const shouldShowActionButton = isComponentValidMoveDestination;
  const shouldShowCancelButton = isComponentBeingMoved;
  const actionButtonText = I18n.t('editor.components.edit_controls.swap_here');

  if (!isActive) {
    return null;
  }

  const overlayClasses = classnames('component-edit-action-overlay', {
    'action-target': shouldShowActionButton,
    'action-source': shouldShowCancelButton
  });

  const onActionClick = (): void => {
    const sourceComponent = {
      blockId: selectors.getSourceBlockId(StorytellerReduxStore.getState()),
      componentIndex: selectors.getSourceComponentIndex(StorytellerReduxStore.getState())
    };

    dispatcher.dispatch({
      action: Actions.MOVE_COMPONENT_DESTINATION_CHOSEN,
      blockId,
      componentIndex: componentIndex,
      sourceComponent
    });
  };

  const onCancelAction = (): void => {
    dispatcher.dispatch({
      action: Actions.ACTION_COMPONENT_CANCEL
    });
  };

  const overlayProps = {
    className: overlayClasses,
    onClick: shouldShowActionButton ? onActionClick : _.noop
  };

  const renderInnerComponentOverlay = (): JSX.Element => {
    return (
      <div {...overlayProps}>
        {shouldShowActionButton && (
          <button className="btn btn-alternate-2 btn-action-target">{actionButtonText}</button>
        )}
        {shouldShowCancelButton && (
          <button className="btn btn-simple btn-action-cancel" onClick={onCancelAction || _.noop}>
            {I18n.t('editor.components.edit_controls.cancel')}
          </button>
        )}
      </div>
    );
  };

  return shouldUseReactComponentBase() ? (
    <div className={`component-edit-${COMPONENT_ACTION_TYPES.MOVE}-overlay-container`}>
      {renderInnerComponentOverlay()}
    </div>
  ) : (
    renderInnerComponentOverlay()
  );
};

export default ComponentActionOverlay;
