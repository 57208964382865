import { IPopupComponent } from '@tylertech/forge';

import { ViewColumn } from 'common/types/viewColumn';
import { SoqlFilter } from './SoqlFilter';
import { ParameterConfiguration } from 'common/types/reportFilters';
import * as BaseFilter from './lib/Filters/BaseFilter';

export type Filter = SoqlFilter;
export type Filters = Filter[];

/**
 * WARNING: these are not raw columns from view metadata
 *
 * This list will be used to construct the list of filters available for use.
 * Users are able to add at most one filter for each column.
 }*
 * The best place to get columns in the right format is via `MetadataProvider.getDisplayableFilterableColumns`
 */
export interface FilterBarColumn extends ViewColumn {
  rangeMin?: number | string;
  rangeMax?: number | string;
}

export interface DataProvider {
  domain?: string;
  datasetUid: string;
}

export interface FilterDataSource {
  datasetUid: string;
  domain: string; // See EN-28544
}

export interface FilterEditorProps {
  allFilters: Filter[];
  allParameters: ParameterConfiguration[];
  appliedFilter: SoqlFilter;
  columns: BaseFilter.FilterColumnsMap;
  constraints?: {
    geoSearch: {
      boundary: number[];
    };
  };
  dataProvider: DataProvider[];
  isReadOnly?: boolean;
  onRemove: () => void;
  onUpdate: (filter: Filter, options?: { shouldCloseControl?: boolean }) => void;
  popupRef: React.RefObject<IPopupComponent & HTMLElement>;
  relativeDateOptions: RelativeDatePeriod[];
  showNullsAsFalse?: boolean;
  showRemoveButtonInFooter?: boolean;
}

export interface RelativeDatePeriod {
  period?: string;
  type?: string;
  value: string;
  label: any;
}

export enum FilterDataType {
  CHECKBOX = 'checkbox',
  COMPUTED = 'computed_column',
  DATE = 'date',
  NUMBER = 'number',
  RADIUS = 'radius',
  TEXT = 'text',
  UNKNOWN = 'unknown'
}

export interface FilterBarPendoIds {
  filterParameterControlContainer?: string;
  expandButton?: string;
  resetAllFilters?: string;
}
