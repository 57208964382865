import _ from 'lodash';
import { ColDef } from '@ag-grid-community/core';
import { OrderConfig } from 'common/visualizations/vif';
import { NormalRow, Row } from './type';
import { aggregateRows } from './gridDataAggregator';

export const groupRows = (
  rows: NormalRow[],
  groupByFields: string[],
  columnDefs: ColDef[],
  sortDefs: OrderConfig[] = [],
  showSubTotal = false
): Row[] => {
  const sortColumns = _.map(sortDefs, 'columnName');
  const sortOrders = _.map(sortDefs, (s) => (s.ascending ? 'asc' : 'desc'));
  const sortedRows = sortOrders.length > 0 ? _.orderBy(rows, sortColumns, sortOrders) : rows;

  if (groupByFields.length <= 0) return sortedRows;

  const processGrouping = (childRows: any[], currentGroupByFields: string[]): Row[] => {
    const result = _.chain(childRows)
      .groupBy((row) => _.chain(row).pick(currentGroupByFields).values().value().join('-'))
      .map((groupedRows) => {
        const groupBys = _.pick(groupedRows[0], currentGroupByFields);
        const aggregations = aggregateRows(groupedRows, columnDefs);
        return {
          ...groupBys,
          ...aggregations,
          _headerRow: buildHeaderRow(groupBys, currentGroupByFields),
          _rows: groupedRows,
          _subTotalRow: buildSubTotalRow(groupBys, aggregations, currentGroupByFields)
        };
      })
      .orderBy(sortColumns, sortOrders)
      .flatMap((group) => [group._headerRow, ...group._rows, showSubTotal ? group._subTotalRow : null])
      .compact()
      .value();

    if (currentGroupByFields.length <= 1) return result;
    return processGrouping(result, currentGroupByFields.slice(0, -1));
  };

  return processGrouping(sortedRows, groupByFields);
};

const buildHeaderRow = (groupBys: any, groupByFields: string[]) => ({
  ...groupBys,
  _rowType: 'header',
  _groupLevel: groupByFields.length - 1
});

const buildSubTotalRow = (groupBys: any, aggregations: any, groupByFields: string[]) => ({
  ...groupBys,
  ...aggregations,
  _rowType: 'subTotal',
  _groupLevel: groupByFields.length - 1
});
