import React from 'react';
import LinesEllipsis from 'react-lines-ellipsis';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';
import { ForgeTooltip } from '@tylertech/forge-react';

const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);

/**
 * Truncate any plain text to the specified number of lines with an ellipsis. The full text
 * is available as a tooltip on hover, and an aria-described-by is automatically added
 * for screenreaders. The lines are responsive if the screen width changes.
 */
const TruncatedText = ({
  className = '',
  text,
  lines
}: {
  className?: string;
  text: string;
  lines: number;
}) => {
  return (
    <div className={'truncated-text ' + className}>
      <ResponsiveEllipsis maxLine={lines} trimRight text={text} />
      <ForgeTooltip text={text} />
    </div>
  );
};

export default TruncatedText;
