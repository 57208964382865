// Vendor Imports
import { union } from 'lodash';
import React from 'react';
import { ForgeButton, ForgeList, ForgeListItem } from '@tylertech/forge-react';
import { IListItemSelectEventData } from '@tylertech/forge/esm/list';

// Project Imports
import { DataSourceCCVs, ParameterConfiguration } from 'common/types/reportFilters';
import { SpecificValue } from 'common/types/clientContextVariable';
import FilterFooter, { FilterFooterProps } from './FilterFooter';

export interface ParameterDropdownProps {
  clientContextVariables?: DataSourceCCVs;
  parameter: ParameterConfiguration;
  onUpdate: (value: string) => void;
  onReset: () => void;
}

const ParameterDropdown = (props: ParameterDropdownProps) => {
  const { clientContextVariables, onReset, onUpdate, parameter } = props;

  const onSelectParameterOverride = (event: CustomEvent<IListItemSelectEventData>) => {
    onUpdate(event.detail.value);
  };

  const onResetParameter = (event: React.MouseEvent) => {
    onReset();
  };

  const dropdownValue = (value: SpecificValue) => {
    return (
      <ForgeListItem
        key={value.value}
        value={value.value}
        data-testid={`${value.value}-parameter-option`}
        on-forge-list-item-select={onSelectParameterOverride}
      >
        {value.displayName}
      </ForgeListItem>
    );
  };

  const findAllSpecificValues = (): SpecificValue[] => {
    let allSpecificValues: SpecificValue[] = [];
    parameter.paramIds.forEach((linkedParam) => {
      if (clientContextVariables) {
        const dataSourceCCVs = clientContextVariables[linkedParam.datasetUid];
        if (dataSourceCCVs) {
          const ccvForParameter = dataSourceCCVs.find((ccv) => ccv.name === linkedParam.name);
          if (ccvForParameter) {
            allSpecificValues = union(allSpecificValues, ccvForParameter?.suggestedValues?.valueList);
          }
        }
      }
    });
    return allSpecificValues;
  };

  const allValues = findAllSpecificValues();
  const dropdownValues = allValues.map((value) => dropdownValue(value));

  const footerProps: FilterFooterProps = {
    onClickReset: onResetParameter,
    showApplyButton: false
  };

  return (
    <div className="parameter-dropdown-options">
      <ForgeList dense selectedValue={parameter.overrideValue}>
        {dropdownValues}
      </ForgeList>
      <FilterFooter {...footerProps} />
    </div>
  );
};

export default ParameterDropdown;
