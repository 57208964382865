import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import I18n from 'common/i18n';
import { FeatureFlags } from 'common/feature_flags';
import ColorPicker from 'common/components/ColorPicker';
import { COLORS_WITH_EMPTY_TRANSPARENT_COLOR } from 'common/authoring_workflow/constants';

import Actions from 'Actions';
import { dispatcher } from 'Dispatcher';

import { removeGlobalFilter } from 'store/TopLevelActions';
import { getBlocksThatNeedConfirmation } from 'store/selectors/BlockRemovalConfirmationSelectors';
import { selectors as storySelectors } from 'store/selectors/StorySelectors';
import { StorytellerState } from 'store/StorytellerReduxStore';
import { ComponentType } from 'types';

interface Props {
  storyUid: string;
  blockId: string;
  color: string;
  blockIndex: number;
  blockCount: number;
  parentRef: React.RefObject<HTMLDivElement>;
}

const updateBlockBackgroundColor = (blockId: string, newColor: string) => {
  dispatcher.dispatch({
    action: Actions.BLOCK_UPDATE_COLOR,
    blockId,
    color: newColor
  });
};
const moveBlock = (event: React.MouseEvent<HTMLElement>, storyUid: string, blockId: string) => {
  // @ts-expect-error TS(2339) FIXME: Property 'getAttribute' does not exist on type 'Ev... Remove this comment to see the full error message
  const attribute = event.target ? event.target.getAttribute('data-block-move-action') : null;
  dispatcher.dispatch({
    action: attribute,
    storyUid: storyUid,
    blockId: blockId
  });
};
const deleteBlock = (storyUid: string, blockId: string) => {
  dispatcher.dispatch({
    action: Actions.STORY_DELETE_BLOCK,
    storyUid: storyUid,
    blockId: blockId
  });
};
const toggleIsPresentable = (blockId: string) => {
  dispatcher.dispatch({
    action: Actions.STORY_TOGGLE_BLOCK_PRESENTATION_VISIBILITY,
    blockId: blockId
  });
};

const BlockEditControls = ({ storyUid, blockId, color, blockIndex, blockCount, parentRef }: Props) => {
  const scope = 'editor.block_edit_controls';
  const colorPickerRef = useRef<HTMLDivElement>(null);

  const [showUpButtonFlyout, setShowUpButtonFlyout] = useState(false);
  const [showDownButtonFlyout, setShowDownButtonFlyout] = useState(false);
  const [showBackgroundColorFlyout, setShowBackgroundColorFlyout] = useState(false);
  const [showPresentableFlyout, setShowPresentableFlyout] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState(color || 'transparent');

  const confirmationNeededBlocks = useSelector(getBlocksThatNeedConfirmation);
  const isGlobalFilterBar = useSelector((state: StorytellerState) => {
    const components = storySelectors.getBlockComponents(state, blockId);
    return components[0].type === ComponentType.GLOBAL_FILTER;
  });
  const isPresentable = useSelector((state: StorytellerState) =>
    storySelectors.isBlockPresentable(state, blockId)
  );

  const dispatch = useDispatch();

  function handleSetBlockBackgroundColor(newColor: string): void {
    updateBlockBackgroundColor(blockId, newColor);

    setBackgroundColor(newColor);
  }

  const templateFlyout = (className: string, translation: string) => {
    const classNames = `${className} flyout`;
    return (
      <div className={classNames}>
        <section className="flyout-content">{I18n.t(translation, { scope })}</section>
      </div>
    );
  };

  const renderMoveUpButton = () => {
    const classes = `block-edit-controls-move-up-btn btn btn-alternate-2 socrata-icon-arrow-up ${
      blockIndex === 0 ? 'btn-disabled' : ''
    }`;
    const moveUpButton = (
      <span
        className={classes}
        data-block-id={blockId}
        data-block-move-action={Actions.STORY_MOVE_BLOCK_UP}
        onMouseEnter={() => setShowUpButtonFlyout(true)}
        onMouseLeave={() => setShowUpButtonFlyout(false)}
        onClick={handleBlockMove}
      />
    );

    return moveUpButton;
  };

  const renderMoveDownButton = () => {
    const classes = `block-edit-controls-move-down-btn btn btn-alternate-2 socrata-icon-arrow-down ${
      blockIndex === blockCount - 1 ? 'btn-disabled' : ''
    }`;
    const moveDownButton = (
      <span
        className={classes}
        data-block-id={blockId}
        data-block-move-action={Actions.STORY_MOVE_BLOCK_DOWN}
        onMouseEnter={() => setShowDownButtonFlyout(true)}
        onMouseLeave={() => setShowDownButtonFlyout(false)}
        onClick={handleBlockMove}
      />
    );
    return moveDownButton;
  };

  const getMagicCalculatedScrollOffset = (parentBlock: HTMLDivElement) => {
    // This is a fragile bit of code to get the total height above the story area.
    // We directly query the height of the USDS header, so we know if it's there and if it's expanded.
    // We also add an arbitrary 200px, which includes the omnibar, AAB, RTE controls, and some padding.
    // The padding matches classic. Thankfully, these heights don't change :crossed_fingers:
    // Check these numbers and code against site_chrome.jsx, and `enable_usds_global_header` feature flag.
    // TODO: This should be rewritten when the USDS header is rewritten.
    const usdsHeaderHeight = $('.usa-banner').height() || 0;
    const magicCalcHeaderHeight = usdsHeaderHeight + 200;
    return parentBlock.getBoundingClientRect().top + window.pageYOffset - magicCalcHeaderHeight;
  };

  const handleBlockMove = (event: React.MouseEvent<HTMLElement>) => {
    const savedScrollPosition = window.pageYOffset;
    moveBlock(event, storyUid, blockId);
    setTimeout(() => {
      if (parentRef.current) {
        // This is a total hack (sigh)
        // We're using flexbox order to position the blocks, but order doesn't implement transitions.
        // When moving the block up, its very hard to tell anything changed, because the scroll position
        // is already at the new position of the block.
        // So we do an instant scroll to the old position, before a gentle scroll to the new position.
        // See https://stackoverflow.com/questions/18846481/use-transition-on-flexbox-order
        // and https://medium.com/developers-writing/animating-the-unanimatable-1346a5aab3cd
        window.scrollTo(0, savedScrollPosition);
        window.scrollTo({ top: getMagicCalculatedScrollOffset(parentRef.current), behavior: 'smooth' });
      }
    }, 200); // This also matches classic. Wait for the block to finish moving, then move to it. Looks nicer.
  };

  const handleBlockDelete = () => {
    let shouldDelete = true;

    if (confirmationNeededBlocks[blockId]) {
      shouldDelete = confirm(I18n.t('editor.remove_block_confirmation')); // eslint-disable-line no-alert
    }

    if (shouldDelete) {
      if (isGlobalFilterBar) {
        dispatch(removeGlobalFilter());
      }

      deleteBlock(storyUid, blockId);
    }
  };

  const handlePresentationToggle = () => {
    toggleIsPresentable(blockId);
  };

  const renderBackgroundColorButton = () => {
    const colorPickerProps = {
      handleColorChange: (newColor: string) => handleSetBlockBackgroundColor(newColor),
      value: backgroundColor,
      palette: COLORS_WITH_EMPTY_TRANSPARENT_COLOR,
      ref: colorPickerRef
    };

    const handleClick = () => {
      if (colorPickerRef.current) {
        // @ts-expect-error TS(2339) FIXME: Property 'onClickColorFrame' does not exist on typ... Remove this comment to see the full error message
        colorPickerRef.current.onClickColorFrame();
      }
    };

    const backgroundColorButton = (
      <span
        className="block-edit-controls-background-color-btn btn btn-alternate-2 socrata-icon-color"
        data-block-id={blockId}
        onMouseEnter={() => setShowBackgroundColorFlyout(true)}
        onMouseLeave={() => setShowBackgroundColorFlyout(false)}
        onClick={() => handleClick()}
      >
        <ColorPicker {...colorPickerProps} />
      </span>
    );

    return backgroundColorButton;
  };

  const renderPresentationToggleButton = () => {
    const togglePresentationClassNames =
      'block-edit-controls-toggle-presentation-btn btn btn-alternate-2 ' +
      `socrata-icon-eye-blocked${isPresentable ? '' : ' active'}`;

    return (
      <span
        className={togglePresentationClassNames}
        data-block-id={blockId}
        data-block-presentation-action={Actions.STORY_TOGGLE_BLOCK_PRESENTATION_VISIBILITY}
        data-flyout="block-edit-controls-presentation-flyout"
        onMouseEnter={() => setShowPresentableFlyout(true)}
        onMouseLeave={() => setShowPresentableFlyout(false)}
        onClick={handlePresentationToggle}
      />
    );
  };

  const renderDeleteButton = () => {
    return (
      <span
        className="block-edit-controls-delete-btn btn btn-alternate-2 socrata-icon-close-2"
        data-block-id={blockId}
        data-block-delete-action={Actions.STORY_DELETE_BLOCK}
        onClick={handleBlockDelete}
      />
    );
  };

  return (
    <div className="block-edit-controls">
      {renderMoveUpButton()}
      {renderMoveDownButton()}
      {FeatureFlags.value('enable_background_colors_stories') &&
        !isGlobalFilterBar &&
        renderBackgroundColorButton()}
      {renderPresentationToggleButton()}
      {renderDeleteButton()}
      {showUpButtonFlyout && templateFlyout('block-edit-controls-move-up-flyout', 'move_block_up_flyout')}
      {showDownButtonFlyout &&
        templateFlyout('block-edit-controls-move-down-flyout', 'move_block_down_flyout')}
      {showBackgroundColorFlyout &&
        templateFlyout('block-edit-controls-background-color-flyout', 'background_color_flyout')}
      {showPresentableFlyout &&
        templateFlyout(
          'block-edit-controls-presentation-flyout',
          isPresentable ? 'presentation_hide_flyout' : 'presentation_show_flyout'
        )}
    </div>
  );
};

export default BlockEditControls;
