export async function createReportWorker() {
  // Use direct path to worker
  // @ts-ignore
  const worker = new Worker(new URL('./report.worker.ts', import.meta.url));

  worker.addEventListener('error', (e) => {
    console.error('Worker error:', e);
  });

  return { worker };
}
