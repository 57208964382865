import _ from 'lodash';

export function migrateVif2ToVif3(vifToMigrate: any) {
  const migratedVif = _.cloneDeep(vifToMigrate);
  const restructureKeysPath = (oldPath: string, newPath: string) => {
    if (_.has(migratedVif, oldPath)) {
      // copy values from oldPath into newPath
      _.set(migratedVif, newPath, _.get(migratedVif, oldPath));

      // Remove properties at old path
      _.unset(migratedVif, oldPath);
    }
  };

  // group all the map properties into basemapOptions
  if (_.get(migratedVif, 'series[0].type') === 'map') {
    const keysToMoveIntoConfiguration = [
      'clusterRadius',
      'maxClusteringZoomLevel',
      'maxClusterSize',
      'pointThreshold',
      'searchBoundaryUpperLeftLatitude',
      'searchBoundaryUpperLeftLongitude',
      'searchBoundaryLowerRightLatitude',
      'searchBoundaryLowerRightLongitude',
      'stackRadius'
    ];

    _.each(keysToMoveIntoConfiguration, (property) => {
      restructureKeysPath(`series[0].mapOptions.${property}`, `configuration.basemapOptions.${property}`);
    });

    restructureKeysPath('configuration.baseMapStyle', 'configuration.basemapOptions.basemapStyle');
    restructureKeysPath('configuration.baseMapOpacity', 'configuration.basemapOptions.basemapOpacity');
    restructureKeysPath('configuration.geoCoderControl', 'configuration.basemapOptions.geoCoderControl');
    restructureKeysPath('configuration.geoLocateControl', 'configuration.basemapOptions.geoLocateControl');
    restructureKeysPath('configuration.navigationControl', 'configuration.basemapOptions.navigationControl');
    restructureKeysPath('series[0].mapOptions.pointMapPointSize', 'series[0].mapOptions.pointSize');
  }

  // Move series specific properties out on configuration
  restructureKeysPath('configuration.computedColumnName', 'series[0].computedColumnName');
  restructureKeysPath('configuration.pointOpacity', 'series[0].mapOptions.pointOpacity');
  restructureKeysPath('configuration.pointSize', 'series[0].mapOptions.pointSize');
  restructureKeysPath('configuration.showLegend', 'series[0].showLegend');
  restructureKeysPath('configuration.shapefile.uid', 'series[0].shapefile.uid');
  restructureKeysPath('configuration.shapefile.primaryKey', 'series[0].shapefile.primaryKey');
  restructureKeysPath('configuration.shapefile.geometryLabel', 'series[0].shapefile.geometryLabel');
  restructureKeysPath('configuration.shapefile.columns', 'series[0].shapefile.columns'); // To support legacy choropleth maps
  // Remove empty shapefile: {} from configuration
  _.unset(migratedVif, 'configuration.shapefile');

  migratedVif.format.version = 3;

  return migratedVif;
}
