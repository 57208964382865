import { cloneDeep, each, every, get, isEmpty, noop, set } from 'lodash';
import $ from 'jquery';
import React from 'react';
import ReactDOM from 'react-dom';

import { getCurrentDomain } from 'common/currentDomain';
import * as VifHelpers from 'common/visualizations/helpers/VifHelpers';
import { SoqlFilter } from 'common/components/FilterBar/SoqlFilter';
import { VerticalFilterBar, VerticalFilterBarProps } from 'common/components/FilterBar/VerticalFilterBar';
import { isMapVisualization } from 'common/visualizations/helpers/VifSelectors';
import { BaseVisualization } from './types';
import { assertIsNotNil } from 'common/assertions';
import { FilterDataSource } from 'common/components/FilterBar/types';

/**
 * - Render the Filter Bar UI
 * - Various helpers to manage Filter Bar state and UI
 */

export const template = (): JQuery => {
  return $('<div>', { class: 'socrata-visualization-filter-bar-container' });
};

export const renderFilterBar = (self: BaseVisualization) => {
  const seriesIndex = self.getSeriesIndex();
  const dataSource = get(self.getVif(), `series[${seriesIndex}].dataSource`, {});

  const $filterBarContainer = self.$container.find('.socrata-visualization-filter-bar-container');
  const filters = dataSource.filters || [];
  const disabled = self.filterBarDisabled();
  const disabledMessage = self.filterBarDisabledMessage();
  const isReadOnly = !self.filterBarIsEditable();
  const filterConstraints = VifHelpers.getFilterConstraints(self.getVif());

  const allHidden = every(filters, (filter) => filter.isHidden);
  const hasNoVisibleFilters = filters.length === 0 || allHidden;
  const filterBarClassName = 'socrata-visualization-filter-bar';

  const hasNoColumns = isEmpty(self.getColumns());
  const shouldNotDisplayFilterBar = !self.shouldDisplayFilterBar();
  const isReadOnlyAndHasNoVisibleFilters = isReadOnly && hasNoVisibleFilters;

  if (hasNoColumns || shouldNotDisplayFilterBar || isReadOnlyAndHasNoVisibleFilters) {
    if ($filterBarContainer[0]) {
      ReactDOM.unmountComponentAtNode($filterBarContainer[0]);
    }
    self.$container.removeClass(filterBarClassName);
    return;
  }

  self.$container.addClass(filterBarClassName);

  const filterColumns = { [dataSource.datasetUid]: self.getColumns() };

  // Until we support multi-source computedColumns, this should never nest the columns in the datasetUid.
  const filterComputedColumns = self.getComputedColumns();

  const filtersDataSource: FilterDataSource[] = [
    {
      datasetUid: dataSource.datasetUid,
      // Warning: EN-28544 means we need to always hit the *primary* cname, not an alias.
      // We don't always know what the primary cname is, especially if we've been embedded into a 3rd-party site
      // There's no reliable way to get this today, best fix is to address the issue at the API level.
      domain:
        // Conspicuously missing: viewMetadata.domainCName. We don't have the view metadata here.
        // Wouldn't matter if we could look at data source domain or default domain, see JIRA ticket above.
        dataSource.domain || // Won't work if data source is federated.
        getCurrentDomain()
    }
  ];
  const newFilterParameterConfigurations = {
    filterParameterConfigurations: VifHelpers.getSanitizeFilterParameterConfig(filters, self.getVif())
  };

  /**
   * This code is basically duplicated from the following files:
   * - frontend/public/javascripts/visualizationCanvas/components/FilterBar.js
   * - common/authoring_workflow/components/FilterBar.js
   */

  const props = {
    columns: filterColumns,
    computedColumns: filterComputedColumns,
    constraints: filterConstraints,
    disabled,
    disabledMessage,
    editMode: false,
    ...newFilterParameterConfigurations,
    isReadOnly,
    dataSource: filtersDataSource,
    onInEditFilterChange: self.getOptions()?.onInEditFilterChange || noop,
    onToggle: (isShown: boolean) => {
      self.isFilterBarShown = isShown;
      $filterBarContainer.toggleClass('expanded', self.isFilterBarShown);
    },
    onUpdate: onFiltersChange(self),
    showFilterBar: self.isFilterBarShown
  };

  /**
   * TODO typescript-thread-pull: the type assertion `props as type` does hide several issues.
   * 1. onUpdateAllFilterParameters should be ? in FilterBarProps
   * 2. can't differentiate between columns: [] and columns: {datasetUid: []}
   * 3. _.get further up makes dataSource `any`, which screws up a lot
   * 4. can't differentiate between SoqlDataSource and InlineDataSource
   * 5. constraints.geoSearch should be ? in FilterBarProps
   * Will be easier to thread-pull after removing SingleSource and running ts-migrate
   */
  ReactDOM.render(
    React.createElement(VerticalFilterBar, props as VerticalFilterBarProps),
    $filterBarContainer[0]
  );
  self.$container.trigger('SOCRATA_VISUALIZATION_INVALIDATE_SIZE');
};

/**
 * This is a function that returns a function so that it can be bound to the
 * BaseVisualization state and also be used as an event handler
 */
export const onFiltersChange = (self: BaseVisualization) => (newFilters: SoqlFilter[]) => {
  const newVif = cloneDeep(self.getVif());
  const seriesIndex = self.getSeriesIndex();

  if (isMapVisualization(self.getVif())) {
    // Map visualizations always pass in the `onFilterChange` option
    self.getOptions()!.onFilterChange!(newFilters);
    // Every series object in the vif has different dataSource and different filters. Only the
    // series object whose filters are rendered, should be updated with the new filters.
    set(newVif, `series[${seriesIndex}].dataSource.filters`, newFilters);
  } else {
    // All the series objects in the vif hold the same filters and new filters should be updated
    // in all of them.
    each(newVif.series, (series) => {
      set(series, 'dataSource.filters', newFilters);
    });
  }

  const $parent = self?.$element?.parent();
  assertIsNotNil($parent);

  // Sets the parents data rendered vif in order to keep temporary values in the quick filter bar, #EN-51200
  $parent.attr('data-rendered-vif', JSON.stringify(newVif));
  self.emitVifEvent(newVif);
};
