import React, { FunctionComponent, ReactNode, useEffect, useMemo, useState } from 'react';
import get from 'lodash/get';
import { useQuery } from 'react-query';
import { ForgeIconButton, ForgeIcon, ForgeMenu } from '@tylertech/forge-react';

import I18n from 'common/i18n';
import airbrake from 'common/airbrake';
import { assign } from 'common/window_location';
import { checkStatus, defaultHeaders } from 'common/http';
import { getCurrentUser } from 'common/current_user';
import { hasGrantRight } from 'common/views/has_rights';
import { hasBeenPublished } from 'common/views/publicationHelpers';
import { GuidanceSummaryV2 } from 'common/types/approvals';
import { AudienceScope, View, ViewPermissions } from 'common/types/view';
import { mapPermissionScopeToTargetAudience, withGuidanceV2 } from 'common/core/approvals/index_new';
import { isMeasure, isStory, isVisualizationCanvas } from 'common/views/view_types';
import { AssetStatus, assetStatusFor } from 'common/views/asset_status';
import {
  showToastNow,
  showToastOnPageReload,
  ToastType
} from 'common/components/ToastNotification/Toastmaster';
import AccessManager from 'common/components/AccessManager';
import { MODES as ACCESS_MANAGER_MODES } from 'common/components/AccessManager/Constants';
import {
  publishUrl,
  savePermissions,
  shouldPublishOnSave,
  shouldUpdateViewOnPublish
} from 'common/components/AccessManager/Util';
import ManageFederationsModal from 'common/components/ManageFederationsModal';

import SubmitForApprovalButton from 'common/components/AssetActionBar/components/submit_for_approval_button';
import { AllowedSecondaryActions } from 'common/components/AssetActionBar/lib/secondary_actions';
import { PrimaryAction } from 'common/components/AssetActionBar/lib/primary_action';
import { launchCopyAssetModal } from 'common/components/AssetActionBar/components/copy_asset_modal';
import { launchDeleteAssetModal } from 'common/components/AssetActionBar/components/delete_asset_modal';
import StoryEditButton from 'common/components/AssetActionBar/components/edit_button/story_edit_button';
import EditButton from 'common/components/AssetActionBar/components/edit_button/edit_button';
import { explicitWithdrawApprovalModal } from 'common/components/AssetActionBar/components/confirmation_dialog/utils';

import { PublishButton } from './publish_button';
import WatchAssetManager from 'common/components/WatchAssetManager';
import JsxToHtmlElementService from 'common/tyler_forge/js_utilities/jsxToHtmlElementService/JsxToHtmlElementService';
import optionallyLocalizeUrls from 'common/site_chrome/app/assets/javascripts/socrata_site_chrome/utils/optionally_localize_urls';
import { handlePostConfirmationError, requireApprovalRequestWithdrawal } from './utils';
import { FeatureFlags } from 'common/feature_flags';
import { localizeLink } from 'common/locale';
import { checkIsMetadataValid } from 'common/views/helpers';
import { FixMetadataMessage } from '../fix_metadata_message';
import { onLockAsset } from 'common/components/AssetBrowser/components/action_dropdown/action_items/lock_asset_action_item';

/** List of actions that can show up in the asset action bar */
enum PublicationActions {
  RevertToPublished = 'revert-to-published',
  RevertChildView = 'revert-child-view',
  ViewPublished = 'view-published',
  ChangeAudience = 'change-audience',
  ManageCollaborator = 'manage-collaborator',
  TransferOwnership = 'transfer-ownership',
  WithdrawApprovalRequest = 'withdraw-approval-request',
  ViewDraft = 'view-draft',
  Copy = 'copy',
  DeleteDataset = 'delete-dataset',
  LockAsset = 'lock-asset',
  WatchAsset = 'watch-asset',
  Federate = 'federate',
  EditMetadata = 'edit-metadata'
}

/** Option rendered in the dropdown */
interface PublicationActionDropdownItem {
  title: string;
  value: PublicationActions;
  danger?: boolean;
}

const fetchOptions: RequestInit = {
  credentials: 'same-origin',
  headers: defaultHeaders
};

export const requireApprovalRequestWithdrawalBeforeAction = (
  approvalsGuidance: GuidanceSummaryV2,
  action: () => void
) => {
  requireApprovalRequestWithdrawal(approvalsGuidance).then((confirmed: boolean) => {
    if (confirmed) {
      action();
    }
  });
};

async function pollForView(assetUid: string, ticket: string): Promise<View> {
  const resp = await fetch(`/api/views/${assetUid}/publication.json?ticket=${ticket}`, {
    headers: defaultHeaders
  });
  const status = resp.status;
  if (status === 202) {
    await sleep(1000);
    return await pollForView(assetUid, ticket);
  } else {
    return (await resp.json()) as View;
  }
}

function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export type RenderPrimaryButtonFunction = (options: {
  approvalsGuidance: GuidanceSummaryV2;
  view: View;
  previouslyPublished: boolean;
  onClick: () => void;
  publishing?: boolean;
}) => ReactNode;

interface PublicationActionProps {
  approvalsGuidance: GuidanceSummaryV2;
  view: View;
  editDraftButton: ReactNode;
  publishedViewUid: string;
  renderPrimaryButton?: RenderPrimaryButtonFunction;
  onPublish?: (permissions?: ViewPermissions) => void;
  onCopy?: (name: string) => void;
  copyBasedOn?: string;
  primaryAction: PrimaryAction;
  allowedActions: AllowedSecondaryActions;
  customSaveFunc?: () => void;
}

const PublicationAction: FunctionComponent<PublicationActionProps> = (props: PublicationActionProps) => {
  const i18nScope = 'shared.components.asset_action_bar.publication_action';
  const jsxToHtmlElementService = useMemo(() => new JsxToHtmlElementService(), []);
  const [watchListVisible, setWatchListVisible] = useState(
    new URLSearchParams(window.location.search).getAll('modal').includes('WatchAssetManager')
  );
  const [accessManagerMode, setAccessManagerMode] = useState<ACCESS_MANAGER_MODES | null>(null);
  const [publishing, setPublishing] = useState(false);
  const [federateModalVisible, setFederateModalVisible] = useState(false);

  const actionTypeCaresAboutMetadataValidity = (primaryAction: PrimaryAction) =>
    [PrimaryAction.PUBLISH_OR_EDIT_DRAFT, PrimaryAction.SUBMIT_TO_APPROVAL_OR_EDIT_DRAFT].includes(
      primaryAction
    );

  const assetTypeCaresAboutMetadataValidity = (view: View) => isMeasure(view) || isVisualizationCanvas(view);

  const { status: validityStatus, data: metadataIsValid } = useQuery(
    `check-metadata-validity-${props.view.id}`,
    async () => {
      if (
        !FeatureFlags.value('enable_consistent_metadata') ||
        !actionTypeCaresAboutMetadataValidity(props.primaryAction) ||
        !assetTypeCaresAboutMetadataValidity(props.view)
      )
        return Promise.resolve(true);
      return await checkIsMetadataValid(props.view.id);
    },
    {
      // keep the value cached for 30 seconds
      staleTime: 30 * 1000,
      refetchOnWindowFocus: false
    }
  );

  // prevents memory leaks due to lingering elements after component unmounts
  useEffect(() => {
    return () => {
      jsxToHtmlElementService.deleteAll();
    };
  }, [jsxToHtmlElementService]);

  const saveViewChanges = () => {
    if (props.customSaveFunc) return props.customSaveFunc();
    const gridViewDataset = get(window, 'blist.dataset');
    if (!gridViewDataset) {
      throw new Error('Tried to save child view changes but blist.dataset is not available');
    }

    // saveView already has onSuccess and onFailure as parameters
    return new Promise(gridViewDataset.saveView.bind(gridViewDataset));
  };

  const handleMoreActions = (option: PublicationActionDropdownItem) => {
    const { value } = option;
    const { approvalsGuidance, view, publishedViewUid, onCopy, copyBasedOn } = props;

    const publishedViewLink = () => {
      if (publishedViewUid) {
        return isStory(view)
          ? localizeLink(`/stories/s/${publishedViewUid}`)
          : localizeLink(`/d/${publishedViewUid}`);
      }
    };

    const redirectAfterDeletion = () => {
      return publishedViewLink() || optionallyLocalizeUrls('/profile');
    };

    switch (value) {
      case PublicationActions.Copy: {
        // eslint-disable-next-line no-undef
        if (view.viewType === 'story' && window.storyteller && window.storyteller.reduxStore) {
          // Reach into Storyteller's brain. Gross.
          window.storyteller.reduxStore.dispatch({ type: 'storyCopierSlice/openCopyStoryModal' });
        } else {
          launchCopyAssetModal(view.name, view.assetType, onCopy, copyBasedOn);
        }
        break;
      }
      case PublicationActions.RevertToPublished:
        // TODO someday. Currently postponed.
        console.warn('revert-to-published option not implemented');
        break;
      case PublicationActions.RevertChildView:
        // "revert" here meaning just reload the page which will re-fetch the view
        window.location.reload();
        break;
      case PublicationActions.ViewPublished:
        assign(publishedViewLink());
        break;
      case PublicationActions.ViewDraft:
        // only for stories currently
        window.location.assign(`/stories/s/${view.id}/preview`);
        break;
      case PublicationActions.ChangeAudience:
        const openAccessManager = () => setAccessManagerMode(ACCESS_MANAGER_MODES.CHANGE_AUDIENCE);
        requireApprovalRequestWithdrawal(approvalsGuidance).then((confirmed: boolean) => {
          if (confirmed) {
            openAccessManager();
          }
        });
        break;
      case PublicationActions.TransferOwnership:
        setAccessManagerMode(ACCESS_MANAGER_MODES.CHANGE_OWNER);
        break;
      case PublicationActions.ManageCollaborator:
        setAccessManagerMode(ACCESS_MANAGER_MODES.MANAGE_COLLABORATORS);
        break;
      case PublicationActions.DeleteDataset:
        // if revisionSeq exists, this will delete the revision
        const revisionSeq = window.initialState?.revisionSeq;
        launchDeleteAssetModal(view, redirectAfterDeletion, revisionSeq);
        break;
      case PublicationActions.LockAsset:
        onLockAsset(view);
        break;
      case PublicationActions.WatchAsset:
        setWatchListVisible(true);
        break;
      case PublicationActions.WithdrawApprovalRequest:
        explicitWithdrawApprovalModal().then((confirmResolution) => {
          if (confirmResolution.confirmed) {
            withGuidanceV2(approvalsGuidance)
              .withdraw()
              .then(checkStatus)
              .then(() => {
                toastLater(ToastType.SUCCESS, 'withdraw_approval_request_success', { name: view.name });
                window.location.reload();
              })
              .catch(() => {
                handlePostConfirmationError();
              });
          }
        });
        break;
      case PublicationActions.Federate:
        setFederateModalVisible(true);
        break;
      case PublicationActions.EditMetadata:
        assign(`/d/${view.id}/edit_metadata`);
        break;
    }
  };

  const toastLater = (toastType: ToastType, translationKey: string, translationOptions: any) => {
    showToastOnPageReload({
      type: toastType,
      content: I18n.t(translationKey, { ...translationOptions, scope: i18nScope })
    });
  };

  const openAccessManagerForPublish = () => {
    setAccessManagerMode(ACCESS_MANAGER_MODES.PUBLISH);
  };

  const closeAccessManager = () => {
    setAccessManagerMode(null);
  };

  // when the user clicks "publish", present a dialog for audience selection or just publish
  const publishButtonOnClick = async () => {
    const { approvalsGuidance, view } = props;

    if (!(await requireApprovalRequestWithdrawal(approvalsGuidance))) {
      return;
    }

    const skipAudienceSelection =
      hasBeenPublished(view) || // has been previously published doesn't change audience
      !hasGrantRight(view);

    if (skipAudienceSelection) {
      setPublishing(true);

      // This basically simulates the params returned by the modal, which is a bit awkward
      try {
        // Don't change the permissions from whatever they currently are
        await saveChanges(ACCESS_MANAGER_MODES.PUBLISH);
      } catch (error) {
        await sleep(1000);
        setPublishing(false);
        showToastNow({
          type: ToastType.ERROR,
          content: I18n.t('shared.components.asset_action_bar.publication_action.unknown_error')
        });
      }
    } else {
      openAccessManagerForPublish();
    }
  };

  /**
   * Called when the user has made changes we want to persist
   * Will handle saving the permissions and publishing the view
   */
  const saveChanges = async (
    mode: ACCESS_MANAGER_MODES,
    assetUid?: string,
    accessManagerPermissions?: ViewPermissions
  ) => {
    const { view, approvalsGuidance } = props;
    // We invoke different modes of the access manager, and not all of them involve publishing
    if (shouldPublishOnSave(mode)) {
      // TODO: We are slowly converging on The One Function To Publish Everything.
      // When this finally happens, please factor this out. The main holdout is DSMP (aka DSMUI),
      // which passes in a completely custom onPublish callback.

      if (typeof props.onPublish === 'function') {
        try {
          await props.onPublish(accessManagerPermissions);
        } catch (e) {
          showToastNow({
            type: ToastType.ERROR,
            content: I18n.t('shared.components.asset_action_bar.publication_action.unknown_error')
          });
          airbrake.notify({ error: e, context: { component: 'PublicationAction' } });
        } finally {
          closeAccessManager();
        }
      } else {
        // if we're publishing and the grid view has unsaved changes... save them
        if (shouldUpdateViewOnPublish()) {
          await saveViewChanges();
        }

        try {
          const isStoryView = isStory(view);
          const resp = await fetch(publishUrl(view, true), {
            ...fetchOptions,
            headers: defaultHeaders,
            ...(isStoryView && { body: JSON.stringify({ digest: window.STORY_DATA.digest }) }),
            method: 'POST'
          });
          const status = resp.status;
          const publishedView = await resp.json().then(async (body) => {
            let pubView;
            if (status === 202) {
              pubView = await pollForView(view.id, body.ticket);
            } else {
              pubView = body;
            }
            return pubView;
          });

          // Hit the permissions API after the asset has been published.
          // This ensures that when assets are published from private/site scope to public
          // the permissions endpoint knows whether it needs to go through the approvals workflow
          // and know which Eurybates events to emit
          if (accessManagerPermissions) {
            await savePermissions(view.id, accessManagerPermissions);
          }

          // Storyteller returns an internal id in `id`, which we don't want.
          // Fortunately, it also returns `uid` which is what we want.
          const id = publishedView.uid || publishedView.id;

          if (!id) {
            const errorMessage = publishedView.message || 'Error publishing asset';
            throw new Error(errorMessage);
          }

          // accessManagerPermissions is passed in by access manager (ie on first publish or access updates)
          // view permissions should be used if there are no accessManagerPermissions (which means the permissions scope is not changing)
          const permissions = accessManagerPermissions || view?.permissions;
          // redirect to the published asset, showing a toast about publish success
          const targetAudience = mapPermissionScopeToTargetAudience(permissions?.scope);
          const toastText =
            targetAudience && withGuidanceV2(approvalsGuidance).willEnterApprovalQueue(targetAudience)
              ? 'shared.components.asset_action_bar.publication_action.submitted_asset_for_approval'
              : `shared.site_chrome.access_manager.${mode}.success_toast`;
          showToastOnPageReload({
            type: ToastType.SUCCESS,
            content: I18n.t(toastText)
          });
          window.location.href = optionallyLocalizeUrls(`/d/${id}`); // eslint-disable-line require-atomic-updates
        } catch (e) {
          const errorMessage = e.message || 'Error publishing asset.';
          throw new Error(errorMessage);
        }
      }
    } else {
      if (accessManagerPermissions) {
        await savePermissions(view.id, accessManagerPermissions);
      }
      // permissions have been saved; hide the access manager and show a toast
      // since (generally) things in here are changing various parts of the asset that will
      // most likely not be reflected, we reload the whole page
      buildToastMessage(mode, accessManagerPermissions);
      window.location.reload();
    }
  };

  const buildToastMessage = (mode: ACCESS_MANAGER_MODES, accessManagerPermissions?: ViewPermissions) => {
    const { approvalsGuidance, view } = props;
    // accessManagerPermissions is passed in by access manager (ie on first publish or access updates)
    // view permissions should be used if there are no accessManagerPermissions (which means the permissions scope is not changing)
    const permissions = accessManagerPermissions || view.permissions;
    const targetAudience = mapPermissionScopeToTargetAudience(permissions?.scope);

    // re: the const below
    // - we should never be showing the "submitted asset" toast except on audience changes.
    //    - Publication can show a "submit" toast, but publication doesn't flow through here.
    //    - Also, we explicitly check accessManagerPermissions.scope to make sure the user has selected an audience value,
    //    otherwise we can get a false positive from willEnterApprovalQueue
    // - An asset can downgrade to private, thus we should only check if it will enter the approvals queue
    //    if it isn't changing to private. Otherwise, willEnterApprovalQueue could return a false positive
    const willEnterQueue =
      accessManagerPermissions &&
      accessManagerPermissions?.scope !== AudienceScope.Private &&
      mode === ACCESS_MANAGER_MODES.CHANGE_AUDIENCE &&
      targetAudience &&
      withGuidanceV2(approvalsGuidance).willEnterApprovalQueue(targetAudience);

    // redirect to the published asset, showing a toast about publish success
    const toastText = willEnterQueue
      ? 'shared.components.asset_action_bar.publication_action.submitted_asset_for_approval'
      : `shared.site_chrome.access_manager.${mode}.success_toast`;

    showToastOnPageReload({
      type: ToastType.SUCCESS,
      content: I18n.t(toastText)
    });
  };

  const renderMoreActionsDropdownItems = () => {
    const actions: PublicationActionDropdownItem[] = [];
    const { approvalsGuidance, view } = props;
    const assetStatus = assetStatusFor(view, approvalsGuidance);

    if (props.allowedActions.editMetadata && (isVisualizationCanvas(view) || isMeasure(view))) {
      if (FeatureFlags.value('enable_consistent_metadata')) {
        actions.push({
          title: I18n.t('edit_metadata', { scope: i18nScope }),
          value: PublicationActions.EditMetadata
        });
      }
    }

    if (props.allowedActions.revert) {
      actions.push({
        title: I18n.t('revert_published', { scope: i18nScope }),
        value: PublicationActions.RevertToPublished
      });
    }

    if (props.allowedActions.revertChildView) {
      actions.push({
        title: I18n.t('revert_child_view', { scope: i18nScope }),
        value: PublicationActions.RevertChildView
      });
    }

    if (props.allowedActions.viewPublished) {
      actions.push({
        title: I18n.t('view_published', { scope: i18nScope }),
        value: PublicationActions.ViewPublished
      });
    }

    if (props.allowedActions.manageCollaborators) {
      actions.push({
        title: I18n.t('manage_access', { scope: i18nScope }),
        value: PublicationActions.ManageCollaborator
      });
    }

    if (props.allowedActions.changeAudience) {
      actions.push({
        title: I18n.t('change_audience', { scope: i18nScope }),
        value: PublicationActions.ChangeAudience
      });
    }

    if (props.allowedActions.federate) {
      actions.push({
        title: I18n.t('federate', { scope: i18nScope }),
        value: PublicationActions.Federate
      });
    }

    if (props.allowedActions.transferOwnership) {
      actions.push({
        title: I18n.t('transfer_ownership', { scope: i18nScope }),
        value: PublicationActions.TransferOwnership
      });
    }

    if (props.allowedActions.withdrawApprovalRequest) {
      actions.push({
        title: I18n.t('withdraw_approval_request', { scope: i18nScope }),
        value: PublicationActions.WithdrawApprovalRequest
      });
    }

    if (props.allowedActions.viewDraft) {
      actions.push({
        title: I18n.t('view_draft', { scope: i18nScope }),
        value: PublicationActions.ViewDraft
      });
    }

    // The "Watch" button allows a user to create and manage subscriptions to published Stories, powered by
    // notifications-and-alerts. Currently, this subscription emails the story as a static screenshot.
    if (props.allowedActions.watchView) {
      actions.push({
        title: I18n.t('watch', { scope: i18nScope }),
        value: PublicationActions.WatchAsset
      });
    }

    if (props.allowedActions.copy) {
      actions.push({
        title: I18n.t('copy_asset', { scope: i18nScope }),
        value: PublicationActions.Copy
      });
    }

    if (props.allowedActions.lockAsset) {
      actions.push({
        title: view.locked
          ? I18n.t('unlock_asset', { scope: i18nScope })
          : I18n.t('lock_asset', { scope: i18nScope }),
        value: PublicationActions.LockAsset
      });
    }

    if (props.allowedActions.deleteDataset) {
      actions.push({
        title:
          assetStatus === AssetStatus.Draft
            ? I18n.t('discard_draft', { scope: i18nScope })
            : I18n.t('delete_this_asset', { scope: i18nScope }),
        value: PublicationActions.DeleteDataset,
        danger: true
      });
    }

    return actions;
  };

  const renderMoreActionButton = () => {
    const entries = renderMoreActionsDropdownItems();

    if (entries.length === 0) {
      return null;
    }

    const label = I18n.t('shared.components.asset_action_bar.publication_action.more_actions');
    const listItems = renderMoreActionsDropdownItems();
    const optionBuilder = (item: PublicationActionDropdownItem, i: number) => {
      let response: string | HTMLElement = item.title;

      if (item.danger) {
        response = jsxToHtmlElementService.wrapJsx(
          <span className={item.danger ? 'more-action-danger' : ''}>{item.title}</span>,
          `more_actions_list_item_${i}`
        );
      } else if (item.value === PublicationActions.EditMetadata) {
        response = `<span data-testid="publication-action-edit-metadata-button">${item.title}</span>`;
      }

      return response;
    };
    const placeholder = () => {
      const response = (
        <ForgeMenu
          options={listItems}
          optionBuilder={optionBuilder}
          on-forge-menu-select={({ detail }: CustomEvent<PublicationActionDropdownItem>) =>
            handleMoreActions(detail)
          }
        >
          <ForgeIconButton className="forge-popup-host">
            <button type="button" aria-label={label} className="more-actions-button">
              <ForgeIcon name="more_vert" />
            </button>
          </ForgeIconButton>
        </ForgeMenu>
      );

      return response;
    };

    return <>{placeholder()}</>;
  };

  const renderPrimaryActionButton = () => {
    const { approvalsGuidance, view, editDraftButton, renderPrimaryButton, primaryAction } = props;

    // these two are only used for the Publish and SubmitForReview buttons
    const disableUpdateDueToMetadata = validityStatus !== 'success' || !metadataIsValid;
    const disabledReason = disableUpdateDueToMetadata ? (
      <FixMetadataMessage
        fetchValidityStatus={validityStatus}
        fixMetadataLink={`/d/${view.id}/edit_metadata`}
      />
    ) : undefined;

    const storyEditButton = () => view.viewType === 'story' && <StoryEditButton key="edit" />;
    switch (primaryAction) {
      case PrimaryAction.NONE:
        return null;
      case PrimaryAction.SHOW_DSMP_EDITS:
        const openRevisions = get(window, 'initialState.view.openRevisions', []);
        const revisionSeq = openRevisions
          .map((rev: { revision_seq: string }) => rev.revision_seq)
          .sort()
          .reverse()[0];
        const link = `/d/${view.id}/revisions/${revisionSeq}`;
        return (
          <a href={link} id="aab-view-edit-btn" className="btn btn-primary btn-dark">
            {I18n.t('shared.components.asset_action_bar.publication_action.view_edits')}
          </a>
        );
      case PrimaryAction.SHOW_PRIMER:
        return (
          <a href={`/d/${view.id}`} className="btn btn-primary btn-dark">
            {I18n.t('shared.components.asset_action_bar.publication_action.view_published')}
          </a>
        );
      case PrimaryAction.DELEGATE_BUTTON_TO_APP:
        return renderPrimaryButton
          ? renderPrimaryButton({
              approvalsGuidance,
              view,
              previouslyPublished: hasBeenPublished(view),
              onClick: () => publishButtonOnClick(),
              publishing
            })
          : null;
      case PrimaryAction.SUBMIT_TO_APPROVAL_OR_EDIT_DRAFT:
        const disabled = isStory(view) && !window.DRAFT_HAS_CHANGES;
        const submitToApprovalButton = (
          <SubmitForApprovalButton
            approvalsGuidance={approvalsGuidance}
            disabled={disabled || disableUpdateDueToMetadata}
            disabledReason={disabledReason}
            key="approval"
            view={view}
          />
        );
        return [editDraftButton, submitToApprovalButton];
      case PrimaryAction.GENERIC_EDIT_BUTTON:
        return <EditButton currentView={view} approvalsGuidance={approvalsGuidance} />;
      case PrimaryAction.PUBLISH_OR_EDIT_DRAFT:
        const publishButton = (
          <PublishButton
            key="publish"
            previouslyPublished={hasBeenPublished(view)}
            onClick={() => publishButtonOnClick()}
            disabled={disableUpdateDueToMetadata}
            disabledReason={disabledReason}
          />
        );
        return [storyEditButton(), editDraftButton, publishButton];
      case PrimaryAction.EDIT_DRAFT:
        return [editDraftButton, storyEditButton()];
      default:
        throw new Error(`Unsupported primary action: ${primaryAction}`);
    }
  };

  const { approvalsGuidance, view } = props;
  const currentUser = getCurrentUser();

  const closeWatchAssetModal = () => {
    setWatchListVisible(false);
    window.history.pushState({}, document.title, window.location.pathname);
  };
  return (
    <div className="publication-action">
      {renderPrimaryActionButton()}
      {renderMoreActionButton()}
      {accessManagerMode !== null && (
        <AccessManager
          mode={accessManagerMode}
          approvalsGuidance={approvalsGuidance}
          view={view}
          onConfirm={saveChanges}
          onDismiss={() => closeAccessManager()}
        />
      )}
      {watchListVisible && currentUser && (
        <WatchAssetManager onDismiss={closeWatchAssetModal} view={view} currentUser={currentUser} />
      )}
      {federateModalVisible && (
        <ManageFederationsModal view={view} onDismiss={() => setFederateModalVisible(false)} />
      )}
    </div>
  );
};

export default PublicationAction;
