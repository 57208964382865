import _ from 'lodash';
import React, { Component, ReactElement } from 'react';
import classNames from 'classnames';

import I18n from 'common/i18n';
import { components } from 'common/visualizations';
import SocrataIcon, { IconName } from 'common/components/SocrataIcon';

import { ComputedMeasureSeries, MeasureVizBaseProps } from '../../types';
import { TimelineScopes } from '../../lib/constants';
import { generateVifFromMeasure } from './generateVifFromMeasure';
import withComputedMeasure, {
  WithComputedMeasureInjectedProps
} from 'common/performance_measures/components/withComputedMeasure';
import { hasMeasureEnded } from '../../lib/measureHelpers';

export interface ComputedMeasureChartProps extends MeasureVizBaseProps {
  showChartTitle?: boolean;
  /** Metadata included: Title. */
  showMetadata?: boolean;
  timelineScope: TimelineScopes;
}

interface Props extends ComputedMeasureChartProps, WithComputedMeasureInjectedProps {}

// A dumb chart that takes all data from props. The parent MeasureChart component
// (defined in index.js) is probably what you should be using.
//
// This separation (MeasureChart vs ComputedMeasureChart) exists to allow the timeline scope
// options exposed to the user to simply modify the Vif, letting withComputedMeasure do all
// the hard work of re-fetching the data when appropriate.
export class ComputedMeasureChart extends Component<Props> {
  static defaultProps = {
    computedMeasure: {
      result: {},
      errors: {}
    },
    showMetadata: false
  };

  static i18nScope = 'shared.performance_measures';

  renderChart(series?: ComputedMeasureSeries): ReactElement | null {
    if (!series) {
      return null;
    }

    const vif = generateVifFromMeasure(this.props);

    if (!vif) {
      return null;
    }

    return <components.Visualization vif={vif} options={{ isMeasure: true, displayFilterBar: true }} />;
  }

  renderPlaceholder(): ReactElement {
    return (
      <div className="measure-result-placeholder">
        <SocrataIcon name={IconName.LineChart} />
        <div className="measure-result-placeholder-text">
          {I18n.t('no_visualization', { scope: ComputedMeasureChart.i18nScope })}
        </div>
      </div>
    );
  }

  render(): ReactElement | null {
    const { measure, computedMeasure, dataRequestInFlight, showMetadata, children } = this.props;
    const { series } = computedMeasure;
    const onlyNullValues = _.chain(series)
      .map((pairs) => pairs[1])
      .pull(null)
      .isEmpty()
      .value();

    const spinner = (
      <div className="measure-result-spinner-container">
        {/*
            This used to be a real spinner, but we ran into baffling IE behavior at the last minute
            (EN-22336). Due to time pressure, we replaced the spinner with static text. EN-22374 tracks
            the real fix.
         */}
        <div>{I18n.t('calculating', { scope: ComputedMeasureChart.i18nScope })}</div>
      </div>
    );

    const busy = dataRequestInFlight || !measure;

    const rootClasses = classNames('computed-measure-chart', {
      'measure-ended': !busy && hasMeasureEnded(measure),
      busy
    });

    // TODO: Ideally we can render a blank timeline chart with the date range applied, however the
    // current implementation of SvgTimelineChart does not play well with no data (error states, flyouts),
    // so we'll have to carefully introduce that capability later
    // For now, prevent the metric viz chart from rendering if only null data is available
    let content = onlyNullValues ? this.renderPlaceholder() : this.renderChart(series);
    content = busy ? spinner : content;

    return (
      <div className={rootClasses}>
        {!busy && children}
        {content}
      </div>
    );
  }
}

export default withComputedMeasure()(ComputedMeasureChart);
