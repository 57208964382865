// src/components/PdfPreviewModal/index.tsx
import React, { useState, useEffect, useRef } from 'react';
import { PreviewPane } from './PreviewPane';
import { GridApi } from '@ag-grid-community/core';
import { Dataset, ReportMetadata } from './types';
import { createReportWorker } from './worker/createWorker';
import { ExportData } from 'common/visualizations/views/agGridReact/types';
import { ForgeDialog } from '@tylertech/forge-react';
import { OrderConfig } from 'common/visualizations/vif';
import { TableColumnFormat } from 'common/authoring_workflow/reducers/types';
import { ColumnAggregation } from 'common/visualizations/dataProviders/MetadataProvider';

interface PdfPreviewModalProps {
  dataset: Dataset;
  getExportData?: (selectedFiltered: boolean) => Promise<ExportData>;
  gridApi: GridApi | null;
  onClose: () => void;
  reportMetadata: ReportMetadata;
  nonStandardAggregations: ColumnAggregation[] | null;
  columnFormats: { [key: string]: TableColumnFormat };
  vifOrderConfig: OrderConfig[];
  showSubTotal: boolean;
  showTotal: boolean;
}

export const PdfPreviewModal: React.FC<PdfPreviewModalProps> = ({
  dataset,
  getExportData,
  gridApi,
  onClose,
  reportMetadata,
  columnFormats,
  nonStandardAggregations,
  vifOrderConfig,
  showSubTotal,
  showTotal
}) => {
  const options = { fullscreen: true };
  return (
    <ForgeDialog open={true} options={options} onDismiss={onClose}>
      {gridApi && getExportData && (
        <PreviewPane
          dataset={dataset}
          getExportData={getExportData}
          gridApi={gridApi}
          onDismiss={onClose}
          nonStandardAggregations={nonStandardAggregations}
          columnFormats={columnFormats}
          reportMetadata={reportMetadata}
          vifOrderConfig={vifOrderConfig}
          showSubTotal={showSubTotal}
          showTotal={showTotal}
        />
      )}
    </ForgeDialog>
  );
};
