import classNames from 'classnames';
import { Either, left as buildLeft, right as buildRight } from 'common/either';
import {
  Expr,
  FunCall,
  isBooleanLiteral,
  isColumnRef,
  isFunCall,
  isLiteral,
  isNullLiteral,
  isNumberLiteral,
  isParameter,
  isStringLiteral,
  isTypedBooleanLiteral,
  isTypedColumnRef,
  isTypedFunCall,
  isTypedNullLiteral,
  isTypedNumberLiteral,
  isTypedParameter,
  isTypedStringLiteral,
  Literal,
  Scope,
  SoQLType,
  TypedExpr,
  TypedSoQLFunCall,
  getTypedLiteral,
  UnAnalyzedJoin
} from 'common/types/soql';
import { ProjectionInfo, ProjectionInfoNA, ViewColumnColumnRef } from '../lib/selectors';
import { castTo } from '../lib/soql-helpers';
import * as _ from 'lodash';
import React, { ReactNode } from 'react';
import { none, Option } from 'ts-option';
import '../styles/visual-expression-editor.scss';
import RemoveNode from './RemoveNode';
import CompilerError, { getCompilerError } from './visualNodes/CompilerError';
import EditColumnRef from './visualNodes/EditColumnRef';
import EditFunCall from './visualNodes/EditFunction';
import {
  EditBooleanLiteral,
  EditEmptyNode,
  EditNumberLiteral,
  EditStringLiteral
} from './visualNodes/EditLiteral';
import KebabMenu from './visualNodes/KebabMenu';
import EditParameter from './visualNodes/EditParameter';
import { ClientContextVariable } from 'common/types/clientContextVariable';
import { EditableExpression, EditableExpressionNA, Eexpr, EexprNA, FilterType, UnEditableExpression, UnEditableExpressionNA } from '../types';

export function isEditable<E>(eexpr: EexprNA<E>): eexpr is EditableExpressionNA<E>;
export function isEditable<U, T>(eexpr: Eexpr<U, T>): eexpr is EditableExpression<U, T>;
export function isEditable<U, T, E>(eexpr: Eexpr<U, T> | EexprNA<E>) {
  return !('error' in eexpr);
}
export function isEditableNA<E>(eexpr: EexprNA<E>): eexpr is EditableExpressionNA<E> {
  return !('error' in eexpr);
}

export function matchEexpr<U, T, L, R>(
  eexpr: Eexpr<U, T>,
  editable: (e: EditableExpression<U, T>) => L,
  uneditable: (u: UnEditableExpression<U>) => R
) {
  if (isEditable(eexpr)) {
    return editable(eexpr);
  } else {
    return uneditable(eexpr);
  }
}
export function matchEexprNA<E, L, R>(
  eexpr: EexprNA<E>,
  editable: (e: EditableExpressionNA<E>) => L,
  uneditable: (u: UnEditableExpressionNA<E>) => R
) {
  if (isEditableNA(eexpr)) {
    return editable(eexpr);
  } else {
    return uneditable(eexpr);
  }
}

export interface ExprProps<U, T, E = T> {
  update: (newExpr: Either<Expr, TypedExpr>) => void;
  remove: (name?: string) => void;
  unAnalyzedJoin?: UnAnalyzedJoin;
  eexpr: Either<Eexpr<U, T>, EexprNA<E>>;
  columns: ViewColumnColumnRef[];
  parameters: ClientContextVariable[];
  scope: Scope;
  isTypeAllowed: (st: SoQLType) => boolean;
  showAddExpr?: boolean;
  addFilterType?: FilterType;
  layer?: number;
  layerCount?: number;
  querySucceeded?: boolean;
  showKebab?: boolean;
  showRemove?: boolean;
  forceShowSuccess?: boolean;
  projectionInfo?: Either<ProjectionInfo, ProjectionInfoNA>;
  hasGroupOrAggregate?: boolean;
  hideBooleanOption?: boolean;
  formatFunctionName?: (functionName: string, translatedName: string) => string;
  // this test id only overrides the default one for the function picker. This could be made to be a more generic testid override but that
  // would mean updating all the many components that can be rendered here
  functionBadgeTestId? : string;
}

const defaultExprProps = { showRemove: true, showKebab: false };

type NodeType = 'literal' | 'column' | 'function' | null;
enum HoverStyle {
  noHover = 'none',
  grey = 'grey',
  red = 'red'
}
interface AstNodeState {
  nodeType: Option<NodeType>;
  hover: HoverStyle;
}
type AstNodeProps = ExprProps<Expr | null, TypedExpr | null> & {
  className: string;
  changeableElement?: number;
  removable: boolean;
  showSuccess?: boolean;
};

export class AstNode extends React.Component<AstNodeProps, AstNodeState> {
  state = {
    nodeType: none,
    hover: HoverStyle.noHover
  };

  updateHoverStyle = (style: HoverStyle) => {
    this.setState({ hover: style });
  };

  wrapInTypeDropdown = (toWrap: ReactNode) => {
    return <KebabMenu {...this.props} toWrap={toWrap} />;
  };

  classFor = (style: HoverStyle): string => {
    switch (style) {
      case HoverStyle.red:
        return 'red-hover';
      case HoverStyle.grey:
        return 'grey-hover';
      case HoverStyle.noHover:
        return '';
    }
  };

  render() {
    const expr = this.props.eexpr.fold(eexpr => eexpr.untyped, eexpr => eexpr.expr);
    let id = undefined;
    if (expr && expr.position) {
      id = `ast-node-${expr.position.line}-${expr.position.column}`;
    }

    const compilerError = getCompilerError(this.props.eexpr);
    const hasError = compilerError.isDefined;
    const error = compilerError
      .map<JSX.Element | null>((ce) => <CompilerError key="quiet-eslint" error={ce} />)
      .getOrElseValue(null);
    const isTopLayer =
      this.props.layer !== undefined &&
      this.props.layer > 0 &&
      (this.props.className.includes('operator-and') || this.props.className.includes('operator-or'));
    const changeableNodeClassNames = classNames('changeable-node', this.props.className, {
      'compilation-failure-for-block' : hasError,
      'removable' : this.props.removable,
      'showSuccess' : this.props.showSuccess
    });
    const mouseoverWrapperClassNames = classNames('mouseover-wrapper', this.classFor(this.state.hover), {
      'wrap-removeable': isTopLayer
    });

    return (
      <div className={changeableNodeClassNames} id={id} data-testid={id}>
        <div className={mouseoverWrapperClassNames}
          onMouseEnter={() => {if (!isTopLayer) this.updateHoverStyle(HoverStyle.grey);}}
          onMouseLeave={() => {if (!isTopLayer) this.updateHoverStyle(HoverStyle.noHover);}}>
          { isTopLayer ? (
            <div className="positioning-div">
              {React.Children.map(this.props.children, (child, index) => {
                if (index === this.props.changeableElement) {
                  return this.wrapInTypeDropdown(child);
                }
                return child;
              })}
            </div>
          ) : (
            React.Children.map(this.props.children, (child, index) => {
              if (index === this.props.changeableElement) {
                return this.wrapInTypeDropdown(child);
              }
              return child;
            })
          )}
          {this.props.removable && (
            <RemoveNode
              onClick={this.props.remove}
              onMouseEnter={() => this.updateHoverStyle(HoverStyle.red)}
              onMouseLeave={() => this.updateHoverStyle(HoverStyle.noHover)}
            />
          )}
          {this.props.showSuccess && <span className="socrata-icon-checkmark-alt vee-query-success" />}
        </div>
        {error}
      </div>
    );
  }
}

// DO NOT USE THIS ON NEW ANALYZER PATH
function shimImplicitCastIntoUntypedExpr(
  untyped: Literal,
  typed: TypedSoQLFunCall,
  props: ExprProps<Expr | null, TypedExpr | null>
) {
  // When you do a SoQL query like
  //   select * where some_date_column > '2019-07-31T19:06:22'
  // the SoQL compiler will insert casts on the literal values to make it typecheck properly,
  // so the user doesn't have to explicitly cast things
  // this means if you do a simple zip of the untyped and typed expression trees like we
  // do with the eexpr, you may end up
  const shimmed: FunCall = castTo(getTypedLiteral(untyped), typed.soql_type);
  return <ExpressionEditor {...props} eexpr={buildLeft({ untyped: shimmed, typed })} />;
}

function isEmpty(props: ExprProps<Expr | null, TypedExpr | null>) {
  const untyped = props.eexpr.fold(eexpr => eexpr.untyped, eexpr => eexpr.expr);
  return untyped === null || isNullLiteral(untyped);
}

export default function ExpressionEditor<T>(props: ExprProps<Expr | null, TypedExpr | null>) {
  const mergedProps = _.assign({}, defaultExprProps, props);

  const inner = (): JSX.Element | null => {
    // there's very likely a prettier way to do this downcast, but, i'm not really
    // sure how to achieve it
    return mergedProps.eexpr.fold(
      eexpr => matchEexpr(
        eexpr,
        ({ untyped, typed }) => {
          // the extra ors are just to appease typescript
          if (isEmpty(mergedProps) || untyped === null || typed === null)
            return <EditEmptyNode {...mergedProps} eexpr={buildLeft({ untyped: null, typed: null })} />;
          else if (isStringLiteral(untyped) && isTypedStringLiteral(typed))
            return <EditStringLiteral {...mergedProps} eexpr={buildLeft({ untyped, typed })} />;
          else if (isNumberLiteral(untyped) && isTypedNumberLiteral(typed))
            return <EditNumberLiteral {...mergedProps} eexpr={buildLeft({ untyped, typed })} />;
          else if (isBooleanLiteral(untyped) && isTypedBooleanLiteral(typed))
            return <EditBooleanLiteral {...mergedProps} eexpr={buildLeft({ untyped, typed })} />;
          else if (isNullLiteral(untyped) && isTypedNullLiteral(typed))
            return <EditEmptyNode {...mergedProps} eexpr={buildLeft({ untyped, typed })} />;
          else if (isColumnRef(untyped) && isTypedColumnRef(typed))
            return <EditColumnRef {...mergedProps} eexpr={buildLeft({ untyped, typed })} />;
          else if (isFunCall(untyped) && isTypedFunCall(typed))
            return <EditFunCall {...mergedProps} eexpr={buildLeft({ untyped, typed })} />;
          else if (isLiteral(untyped) && isTypedFunCall(typed))
            return shimImplicitCastIntoUntypedExpr(untyped, typed, mergedProps);
          // calculated columns
          else if (isColumnRef(untyped) && isTypedStringLiteral(typed))
            return <EditColumnRef {...mergedProps} eexpr={buildLeft({ untyped, typed })} />;
          else if (isColumnRef(untyped) && isTypedNumberLiteral(typed))
            return <EditColumnRef {...mergedProps} eexpr={buildLeft({ untyped, typed })} />;
          else if (isColumnRef(untyped) && isTypedBooleanLiteral(typed))
            return <EditColumnRef {...mergedProps} eexpr={buildLeft({ untyped, typed })} />;
          else if (isColumnRef(untyped) && isTypedNullLiteral(typed))
            return <EditColumnRef {...mergedProps} eexpr={buildLeft({ untyped, typed })} />;
          else if (isColumnRef(untyped) && isTypedFunCall(typed))
            return <EditColumnRef {...mergedProps} eexpr={buildLeft({ untyped, typed })} />;
          else if (isParameter(untyped))
            return <EditParameter {...mergedProps} eexpr={buildLeft({ untyped, typed})}/>;
          console.error(mergedProps.eexpr);
          throw new Error(`Unexpected expr ${untyped}`);
        },
        (uneditable) => {
          const { untyped } = uneditable;
          if (isEmpty(mergedProps) || untyped === null)
            return <EditEmptyNode {...mergedProps} eexpr={buildLeft({ untyped: null, typed: null })} />;
          else if (isStringLiteral(untyped))
            return <EditStringLiteral {...mergedProps} eexpr={buildLeft({ ...uneditable, untyped })} />;
          else if (isNumberLiteral(untyped))
            return <EditNumberLiteral {...mergedProps} eexpr={buildLeft({ ...uneditable, untyped })} />;
          else if (isBooleanLiteral(untyped))
            return <EditBooleanLiteral {...mergedProps} eexpr={buildLeft({ ...uneditable, untyped })} />;
          else if (isNullLiteral(untyped))
            return <EditEmptyNode {...mergedProps} eexpr={buildLeft({ ...uneditable, untyped })} />;
          else if (isColumnRef(untyped))
            return <EditColumnRef {...mergedProps} eexpr={buildLeft({ ...uneditable, untyped })} />;
          else if (isFunCall(untyped))
            return <EditFunCall {...mergedProps} eexpr={buildLeft({ ...uneditable, untyped })} />;
          else if (isParameter(untyped))
          //TODO: Make a real representation of the parameter
            return <></>;
          console.error(mergedProps.eexpr);
          throw new Error(`Unexpected expr ${untyped}`);
        }
      ),
      // This is different from the old-analyzer version because you can't have mismatched pairs
      // in the new-analyzer world.
      eexprNA => matchEexprNA(
        eexprNA,
        ({ expr }) => {
          // FIXME: These should be doing the Typed version of the type-narrowing guards, but I don't want to
          // rewrite the test fixtures in a complicated handle-both-states kind of way right now. That should
          // be done as part of the cleanup phase.
          if (isEmpty(mergedProps) || expr === null)
            return <EditEmptyNode {...mergedProps} eexpr={buildRight({ expr: null })} />;
          else if (isStringLiteral(expr))
            return <EditStringLiteral {...mergedProps} eexpr={buildRight({ expr })} />;
          else if (isNumberLiteral(expr))
            return <EditNumberLiteral {...mergedProps} eexpr={buildRight({ expr })} />;
          else if (isBooleanLiteral(expr))
            return <EditBooleanLiteral {...mergedProps} eexpr={buildRight({ expr })} />;
          else if (isNullLiteral(expr))
            return <EditEmptyNode {...mergedProps} eexpr={buildRight({ expr })} />;
          else if (isColumnRef(expr))
            return <EditColumnRef {...mergedProps} eexpr={buildRight({ expr })} />;
          else if (isFunCall(expr))
            return <EditFunCall {...mergedProps} eexpr={buildRight({ expr })} />;
          else if (isParameter(expr))
            return <EditParameter {...mergedProps} eexpr={buildRight({ expr })} />;
          console.error(mergedProps.eexpr);
          throw new Error(`Unexpected expr ${expr}`);
        },
        (uneditable) => {
          const { expr } = uneditable;
          if (isEmpty(mergedProps) || expr === null)
            return <EditEmptyNode {...mergedProps} eexpr={buildRight({ expr: null })} />;
          else if (isTypedStringLiteral(expr))
            return <EditStringLiteral {...mergedProps} eexpr={buildRight({ ...uneditable, expr })} />;
          else if (isTypedNumberLiteral(expr))
            return <EditNumberLiteral {...mergedProps} eexpr={buildRight({ ...uneditable, expr })} />;
          else if (isTypedBooleanLiteral(expr))
            return <EditBooleanLiteral {...mergedProps} eexpr={buildRight({ ...uneditable, expr })} />;
          else if (isTypedNullLiteral(expr))
            return <EditEmptyNode {...mergedProps} eexpr={buildRight({ ...uneditable, expr })} />;
          else if (isTypedColumnRef(expr))
            return <EditColumnRef {...mergedProps} eexpr={buildRight({ ...uneditable, expr })} />;
          else if (isTypedFunCall(expr))
            return <EditFunCall {...mergedProps} eexpr={buildRight({ ...uneditable, expr })} />;
          else if (isTypedParameter(expr))
            return <EditParameter {...mergedProps} eexpr={buildRight({ ...uneditable, expr })} />;
          console.error(mergedProps.eexpr);
          throw new Error(`Unexpected expr ${expr}`);
        }
      )
    );
  };

  return <div className="expr" data-testid="visual-expression-editor">{inner()}</div>;
}
