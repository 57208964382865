import $ from 'jquery';
import { assert, assertHasProperty } from 'common/assertions';
import StorytellerUtils from 'lib/StorytellerUtils';
import './shared/componentBase';

/**
 * @function componentHorizontalRule
 * @desc Renders out a <hr>.
 * @param {object} componentData - An object with a type and value attribute
 * @returns {jQuery} - The rendered horizontal rule jQuery element
 */
$.fn.componentHorizontalRule = componentHorizontalRule;

export default function componentHorizontalRule(props) {
  const $this = $(this);
  const { componentData } = props;

  assertHasProperty(componentData, 'type');
  assert(
    componentData.type === 'horizontalRule',
    `componentHorizontalRule: Unsupported component type ${componentData.type}`
  );
  assert($this.length === 1, 'Selection must have exactly one element.');

  $this
    .empty()
    .addClass(StorytellerUtils.typeToClassesForComponentType(componentData.type))
    .addClass('typeset') // Needed to make custom theme rules work
    .append($('<hr>'));

  return $this;
}
