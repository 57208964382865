import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import * as SocrataVisualizations from 'common/visualizations';
import StoryComponentRenderer from 'editor/renderers/StoryComponentRenderer';
import 'lib/components/block-component-renderers/shared/componentBase';
import 'lib/components/block-component-renderers';
import 'lib/components/Modal';
import { BlockComponent } from 'types';
import { ComponentType } from 'types';

interface Props {
  component: BlockComponent;
  blockId: string;
  componentIndex: number;
}

// TODO: why is this here and also in view/index?
SocrataVisualizations.views.RowInspector.setup();

const ViewBlockItem: FunctionComponent<Props> = (props) => {
  const { component, componentIndex, blockId } = props;

  const additionalClasses = classNames(`col${component.layout.w}`, {
    'flexible-component': component.type !== ComponentType.GLOBAL_FILTER
  });

  return (
    <StoryComponentRenderer
      blockId={blockId}
      componentIndex={componentIndex}
      additionalClasses={additionalClasses}
      editMode={false}
    />
  );
};

export default ViewBlockItem;
