import { FeatureFlags } from 'common/feature_flags';
import type { GridApi } from '@ag-grid-community/core';

// TODO_PRINT_TABLE: Don't check the URL like this. Should ref from StorytellerEnvironment.
// Add a `PRINT_MODE` constant to _storyteller_environment.html.erb
// Can probably use `should_load_print_styles` from `stories_helper.rb`
// Also need to make StorytellerEnvironment available in common.
export const isPrintTableEnabled = (): boolean => {
  return (
    FeatureFlags.valueOrDefault('enable_enhanced_table_print', false) &&
    new URLSearchParams(window.location.search).get('print') === 'true'
  );
};

export const getItemIdToPrint = () => {
  return new URLSearchParams(window.location.search).get('itemId');
};

// TODO_PRINT_TABLE: Basically, everything below here should be in a React component.
// That component should probably be connected to a new version of `print=true` in Rails.
// That might mean fiddling more in the show.html.erb and attaching React to a new Div there.
// Making a new rails page and ENV stuff that high up should make a lot of this feature easier,
// because you can override props and the VIF and such up in view/index.

// TODO_PRINT_TABLE: Make an actual header for this.

let printButtonListenerAdded = false; // Flag to track listener addition
const afterPrintListenerAdded = false; // Flag to track listener addition
let printTimeout: any = null;
let fallbackTimeout: any = null;
let printScheduled = false; // Flag to avoid multiple print calls
let resizeObserver: ResizeObserver | null = null; // Cache the observer instance

export const renderPrintTablePseudoComponent = (api: GridApi) => {
  const backgroundFilterBar: HTMLElement | null = document.querySelector('.background-filter-bar');
  const setPrintEventListeners = () => {
    const showLoadingMessage = () => {
      let message = document.querySelector('#loading-message') as HTMLElement | null;

      if (!message) {
        // Create a new div element as HTMLElement
        message = document.createElement('div') as HTMLElement;
        message.id = 'loading-message';
        message.innerText = 'We are constructing your PDF...';
        message.style.cssText = `
          position: fixed;
          top: 20px;
          left: 50%;
          transform: translateX(-50%);
          background-color: rgba(0, 0, 0, 0.8);
          color: white;
          padding: 10px 20px;
          border-radius: 5px;
          z-index: 10000;
        `;
        document.body.appendChild(message);
      } else {
        message.style.display = 'block';
      }
    };

    const handleShowGlobalFiltersBar = () => {
      if (backgroundFilterBar) {
        backgroundFilterBar.style.display = 'block';
      }
    };

    const handleHideGlobalFiltersBar = () => {
      const printFilterElement: HTMLElement | null = document.querySelector('.filter-name-print');
      if (!printFilterElement && backgroundFilterBar) {
        backgroundFilterBar.style.display = 'none';
      }
    };

    const hideLoadingMessage = () => {
      const message: HTMLElement | null = document.querySelector('#loading-message');
      if (message) message.style.display = 'none';
    };

    if (!printButtonListenerAdded) {
      document.querySelector('#printButton')?.addEventListener('click', (event) => {
        event.preventDefault();
        showLoadingMessage();
        handleHideGlobalFiltersBar();
        delayedPrint();
      });
      printButtonListenerAdded = true;
    }

    function handleDataLoadComplete(event: any) {
      if (printTimeout) clearTimeout(printTimeout); // Clear previous timeout
      if (!printScheduled) return; // Prevent multiple triggers

      // Schedule a new print 5 seconds after the last data load start
      clearFallback(); // Clear fallback timer if it's still running
      //delay the print for 5 seconds to allow the grid to render data
      printTimeout = setTimeout(() => {
        hideLoadingMessage();
        window.print(); // Trigger the print dialog manually
        adjustAgGridHeight(false); // Stop observing resize events
        printScheduled = false; // Reset the flag
        clearTimeout(printTimeout); // Clear the timeout
      }, 5000);
    }

    function triggerPrint() {
      const element = document.querySelector(
        'div.common-viz-react.socrata-visualization-renderer.socrata-paginated-table'
      );
      if (element) {
        const $elementx = $(element);
        printScheduled = true;
        $elementx.on('SOCRATA_VISUALIZATION_DATA_LOAD_COMPLETE', handleDataLoadComplete);
        startFallbackTimer();
      }
    }

    function startFallbackTimer() {
      fallbackTimeout = setTimeout(() => {
        hideLoadingMessage();
        window.print(); // Trigger the print dialog manually
        printScheduled = false; // Reset
        adjustAgGridHeight(false); // Stop observing resize events
        clearFallback();
      }, 8000); // 8-second fallback
    }

    function clearFallback() {
      if (fallbackTimeout) {
        clearTimeout(fallbackTimeout);
      }
    }
    function delayedPrint() {
      if (printScheduled) return; // Prevent multiple triggers
      printScheduled = true;
      triggerPrint();
      document.body.classList.add('no-scroll');
      api.updateGridOptions({ domLayout: 'print' });
      adjustAgGridHeight();
    }

    // Intercepting Ctrl + P and disabling native print dialog
    window.addEventListener('keydown', function (event) {
      if ((event.ctrlKey || event.metaKey) && event.key === 'p') {
        event.preventDefault(); // Prevent native print action
        delayedPrint(); // Trigger the delayed print
        if (printTimeout) clearTimeout(printTimeout); // Clear the timeout
        clearFallback(); // Clear fallback timer
        showLoadingMessage();
      }
    });

    // Handling afterprint to reset layout
    if (!afterPrintListenerAdded) {
      window.addEventListener('afterprint', function () {
        api.updateGridOptions({ domLayout: 'normal' });
        adjustAgGridHeight(false); // Stop observing resize events
        clearFallback();
        document.body.classList.remove('no-scroll');
        handleShowGlobalFiltersBar();
        hideLoadingMessage();
      });
    }
  };

  const adjustAgGridHeight = (observe = true) => {
    const targetElement: HTMLElement | null = document.querySelector('.socrata-visualization-ag-grid');
    const observedElement: HTMLElement | null = document.querySelector('.ag-root-wrapper');

    if (!targetElement || !observedElement) return; // Safeguard

    // Create a new ResizeObserver only if it doesn't already exist
    if (!resizeObserver) {
      resizeObserver = new ResizeObserver((entries) => {
        for (const entry of entries) {
          if (entry.target === observedElement) {
            const observedElementHeight = observedElement.clientHeight;
            targetElement.style.height = `${observedElementHeight + 150}px`; // Adjust height dynamically
          }
        }
      });
    }

    if (observe) {
      // Start observing the element's height
      resizeObserver.observe(observedElement);

      // Set initial height with 150px added
      const observedElementHeight = observedElement.clientHeight;
      targetElement.style.height = `${observedElementHeight + 150}px`;
    } else {
      // Stop observing and set default height to 100px
      if (resizeObserver) {
        resizeObserver.disconnect();
        resizeObserver = null; // Reset the observer
      }
      targetElement.style.height = '1000px';
    }
  };

  const createPrintHeader = () => {
    const element = document.querySelector('header');
    if (element) {
      return; // If a header already exists, do nothing
    }
    const header = document.createElement('header');
    header.id = 'print-header';
    // Create an image element
    const div = document.createElement('div');
    div.className = 'print-header-image';

    // Append the image to the header
    header.appendChild(div);

    // Insert the header at the top of the body
    document.body.insertBefore(header, document.body.firstChild);
  };

  setPrintEventListeners();
  adjustAgGridHeight(false);
  createPrintHeader();
};
