// src/components/PdfPreviewModal/PreviewPane/index.tsx
import React, { useEffect, useState, useRef } from 'react';
import { GridApi } from '@ag-grid-community/core';
import { createReportWorker } from '../worker/createWorker';
import { Dataset, PreviewSettings, ReportMetadata } from '../types';
import { PDFPreview } from './PdfPreview';
import { Settings } from './Settings';
import { Modal, ModalHeader } from '../../Modal';
import '../PdfPreviewModal.scss';
import { AgGridDataProcessor } from '../processors/GridDataProcessor';
import { ExportData } from 'common/visualizations/views/agGridReact/types';
import { OrderConfig } from 'common/visualizations/vif';
import { AG_SOQL_EXPORT_QUERY_LIMIT } from 'common/visualizations/helpers/TableDataHelpers';
import { ColumnAggregation } from 'common/visualizations/dataProviders/MetadataProvider';
import { TableColumnFormat } from 'common/authoring_workflow/reducers/types';

interface PreviewPaneProps {
  dataset: Dataset;
  gridApi: GridApi;
  reportMetadata: ReportMetadata;
  vifOrderConfig: OrderConfig[];
  nonStandardAggregations: ColumnAggregation[] | null;
  columnFormats: { [key: string]: TableColumnFormat };
  onError?: (error: string) => void;
  onDismiss: () => void;
  getExportData: (selectedFiltered: boolean) => Promise<ExportData>;
  showSubTotal: boolean;
  showTotal: boolean;
}

const getDefaultSettings = (gridApi: GridApi): PreviewSettings => ({
  title: '',
  pageSize: 'Letter',
  orientation: 'landscape',
  scale: 1,
  monetaryUnit: 'thousands',
  template: gridApi.getRowGroupColumns().length > 0 ? 'grouped-aggregate' : 'transaction-table',
  pageBreak: 'standard',
  textOverflow: 'linebreak',
  columnWidth: 'bestfit'
});

const dataProcessor = new AgGridDataProcessor();

export const PreviewPane: React.FC<PreviewPaneProps> = ({
  dataset,
  gridApi,
  reportMetadata,
  vifOrderConfig,
  getExportData,
  columnFormats,
  nonStandardAggregations,
  onDismiss,
  onError,
  showSubTotal,
  showTotal
}) => {
  const [settings, setSettings] = useState<PreviewSettings>({ ...getDefaultSettings(gridApi) });
  const [pdfData, setPdfData] = useState<string | null>(null);

  // TODO: Use state based rather than multiple flags.
  // State => initializing | loading | working | tooManyRows | done
  const [hasTooMayRows, setHasTooManyRows] = useState(false);
  const [loading, setLoading] = useState(false);
  const [workerReady, setWorkerReady] = useState(false);
  // -------

  const workerRef = useRef<Worker | null>(null);
  const currentBlobUrl = useRef<string | null>(null);

  // TODO: On settings change, previous worker should stop its previos work and get.
  // Worker initialization effect
  useEffect(() => {
    async function initWorker() {
      try {
        const { worker } = await createReportWorker();
        workerRef.current = worker;

        worker.onmessage = (e) => {
          const { type, data, error } = e.data;

          switch (type) {
            case 'preview':
              // Revoke previous URL if it exists
              if (currentBlobUrl.current) {
                URL.revokeObjectURL(currentBlobUrl.current);
              }
              // Create and store new URL
              const blobUrl = URL.createObjectURL(data.content);
              currentBlobUrl.current = blobUrl;
              // Set the PDF data to state
              setPdfData(blobUrl);
              //setPdfData(data.content);
              setLoading(false);

              break;

            case 'error':
              onError?.(error);
              setLoading(false);
              break;
          }
        };

        setWorkerReady(true);
        return () => {
          worker.terminate();
          setWorkerReady(false);
        };
      } catch (error) {
        onError?.(error instanceof Error ? error.message : 'Worker initialization failed');
        return () => {};
      }
    }

    const cleanup = initWorker();
    return () => {
      cleanup.then((cleanupFn) => cleanupFn());
    };
  }, [onError]);

  useEffect(() => {
    // Cleanup function to revoke URL
    return () => {
      if (currentBlobUrl.current) {
        URL.revokeObjectURL(currentBlobUrl.current);
      }
    };
  }, []); // Component cleanup effect

  // Effect to generate preview when settings or data change
  useEffect(() => {
    if (!workerReady || !workerRef.current) {
      return;
    }

    // Revoke previous URL before creating new one
    if (currentBlobUrl.current) {
      URL.revokeObjectURL(currentBlobUrl.current);
      currentBlobUrl.current = null;
    }

    setLoading(true);
    setHasTooManyRows(false);
    const extraoptions = {
      dataset,
      vifOrderConfig,
      columnFormats,
      nonStandardAggregations,
      showSubTotal,
      showTotal
    };

    dataProcessor
      .processGridData(gridApi, getExportData, extraoptions)
      .then((processedData) => {
        // We limit and fetch only `AG_SOQL_EXPORT_QUERY_LIMIT` no of rows. So
        // if we get the same number of rows are fetched, we conclude that there
        // are too many records to print or preview.
        if (processedData.processedRowCount >= AG_SOQL_EXPORT_QUERY_LIMIT) {
          setHasTooManyRows(true);
          setLoading(false);
          return;
        }

        setHasTooManyRows(false);
        if (workerRef.current) {
          workerRef.current.postMessage({
            type: 'generatePreview',
            data: processedData,
            settings,
            reportMetadata
          });
        } else {
          console.error('Worker is no longer available');
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error('Error processing grid data:', error);
        // Handle error appropriately
      });
  }, [gridApi, settings, workerReady]); // Added workerReady to dependencies

  const handleSettingsChange = (newSettings: PreviewSettings) => {
    setSettings(newSettings);
  };

  return (
    <Modal onDismiss={onDismiss} fullScreen>
      <div className="pdf-preview-modal__container">
        <ModalHeader onDismiss={onDismiss}>
          <h2>Print Preview</h2>
        </ModalHeader>
        <div className="pdf-preview-modal__content">
          <Settings settings={settings} onChange={handleSettingsChange} />

          <div className="pdf-preview-modal__preview">
            <div className="pdf-preview-modal__preview-container">
              {!workerReady ? (
                <div className="pdf-preview-modal__loading">
                  <div className="pdf-preview-modal__loading-spinner" />
                  <span>Initializing preview...</span>
                </div>
              ) : (
                <PDFPreview pdfData={pdfData} loading={loading} hasTooMayRows={hasTooMayRows} />
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
