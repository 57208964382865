import React, { useRef, useEffect, forwardRef, useImperativeHandle } from 'react';
import { isEqual } from 'lodash';
import { useSelector } from 'react-redux';

import Environment from 'StorytellerEnvironment';
import { selectors as dataSourceSelectors } from 'store/selectors/DataSourceSelectors';
import { selectors as storySelectors } from 'store/selectors/StorySelectors';
import { StorytellerState } from 'store/StorytellerReduxStore';
import { selectors as actionComponentSelectors } from 'store/selectors/ActionComponentSelectors';
import { ComponentType } from 'types';

import ComponentEditControls from 'editor/components/ComponentEditControls';
import { shouldUseReactComponentBase } from 'lib/FlexibleLayoutUtils';

export interface StoryComponentRendererProps {
  blockId: string;
  componentIndex: number;
  additionalClasses?: string;
  // This is only used for view mode
  editMode: boolean;
}

const StoryComponentRenderer = forwardRef<HTMLDivElement, StoryComponentRendererProps>(
  ({ blockId, componentIndex, additionalClasses = '', editMode }, ref) => {
    const componentRef = useRef(null);
    useImperativeHandle(ref, () => componentRef.current!, [componentRef]);
    const theme = useSelector((state: StorytellerState) =>
      storySelectors.getStoryTheme(state, Environment.STORY_UID!)
    );
    const additionalFilters = useSelector(dataSourceSelectors.getGlobalFilters, isEqual);
    const parameterOverrides = useSelector(dataSourceSelectors.getParameterOverrides, isEqual);
    const componentData = useSelector((state: StorytellerState) => {
      return storySelectors.getBlockComponentAtIndex(state, blockId, componentIndex);
    }, isEqual);

    useSelector(actionComponentSelectors.isActionComponentStoreActive);

    const jQueryRendererToRun = getComponentRenderer(componentData.type, editMode);
    const currentRenderer = useRef('');

    const componentEditControlsProps = {
      blockId,
      componentIndex,
      componentData,
      editMode
    };

    const pluginProps = {
      componentData,
      theme,
      editMode: editMode,
      blockId,
      componentIndex,
      additionalFilters,
      parameterOverrides
    };

    // CBR-TODO: maybe pull the jQuery into SharedRenderingUtils?
    useEffect(() => {
      if (componentRef?.current && jQueryRendererToRun) {
        if (jQueryRendererToRun === 'NO_RENDER') return;
        // Adapted from what storyComponentRenderer originally did
        const needToChangeRenderer = currentRenderer.current !== jQueryRendererToRun;
        const $componentContainer = $(componentRef.current);
        let $componentContent = $componentContainer.children().eq(0);

        if (needToChangeRenderer) {
          currentRenderer.current = jQueryRendererToRun;

          $componentContent = $('<div>', { class: 'component' });
          if (editMode) {
            $componentContent.addClass('editing');
          }
          $componentContainer.find('.component').trigger('destroy');
          $componentContainer.empty().append($componentContent);
        }

        // @ts-ignore
        $componentContent[jQueryRendererToRun](pluginProps);
      }
      // No dependency array, this runs every render, including on every keypress.
      // According to my current performance testing, this is no longer slow.
    });

    /**
     * TODOs:
     * 1. Honestly, its just refactoring. There's a LOT that could be done better, so I'll go over
     *    everything again tomorrow.
     */

    const renderComponentContainer = () => (
      <div
        className={`component-container ${additionalClasses}`}
        ref={componentRef}
        data-component-index={componentIndex}
        data-component-renderer-name={jQueryRendererToRun}
      />
    );

    // Once we get classic layout working with ReactComponentBase we can remove the isFlexibleStory check
    return shouldUseReactComponentBase() && editMode ? (
      <div
        className={`component-base-container ${additionalClasses}`}
        data-component-renderer-name={jQueryRendererToRun}
      >
        <ComponentEditControls {...componentEditControlsProps} />
        {renderComponentContainer()}
      </div>
    ) : (
      renderComponentContainer()
    );
  }
);

export default StoryComponentRenderer;

const getComponentRenderer = (type: ComponentType, editMode: boolean): string => {
  switch (type) {
    case ComponentType.HTML:
    case ComponentType.HTML_TABLE_OF_CONTENTS:
      return 'componentHTML';
    case ComponentType.SPACER:
      return 'componentSpacer';
    case ComponentType.HORIZONTAL_RULE:
      return 'componentHorizontalRule';
    case ComponentType.ASSET_SELECTOR: {
      if (editMode) {
        return 'componentAssetSelector';
      } else {
        return 'NO_RENDER';
      }
    }
    case ComponentType.AUTHOR:
      return 'componentAuthor';
    case ComponentType.IMAGE:
      return 'componentImage';
    case ComponentType.HERO:
      return 'componentHero';
    case ComponentType.GLOBAL_FILTER:
      return 'componentGlobalFilter';
    case ComponentType.STORY_TILE:
    case ComponentType.STORY_WIDGET:
      return 'componentStoryTile';
    case ComponentType.MEASURE_CARD:
    case ComponentType.MEASURE_CHART:
      return 'componentMeasure';
    case ComponentType.YOUTUBE:
      return 'componentYoutubeVideo';
    case ComponentType.CLASSIC_VISUALIZATION:
      return 'componentSocrataVisualizationClassic';
    case ComponentType.REGION_MAP:
      return 'componentSocrataVisualizationRegionMap';
    case ComponentType.CHOROPLETH_MAP: // legacy
      return 'componentSocrataVisualizationRegionMap';
    case ComponentType.CALENDAR:
    case ComponentType.COLUMN_CHART:
    case ComponentType.SCATTER_CHART:
    case ComponentType.COMBO_CHART:
    case ComponentType.BAR_CHART:
    case ComponentType.PIE_CHART:
    case ComponentType.TIMELINE_CHART:
    case ComponentType.HISTOGRAM:
      return 'componentSocrataVisualizationInSitu';
    case ComponentType.TABLE:
    case ComponentType.AG_TABLE:
      return 'componentSocrataVisualizationTable';
    case ComponentType.FEATURE_MAP:
      return 'componentSocrataVisualizationFeatureMap';
    case ComponentType.MAP:
      return 'componentSocrataVisualizationMap';
    case ComponentType.VIZ_CANVAS:
      return 'componentSocrataVisualizationVizCanvas';
    case ComponentType.EMBEDDED_HTML:
      return 'componentEmbeddedHtml';
    default:
      throw new Error(`No component renderer found for type: ${type}`);
  }
};
