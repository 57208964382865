enum Actions {
  // {Object} view: Core view representation of Story
  CORE_VIEW_UPDATED = 'CORE_VIEW_UPDATED',

  // {Object} data: Story data Object.
  STORY_CREATE = 'STORY_CREATE',

  // TODO EN-57629: Remove once fully converted to Redux
  UPDATE_STORY_COLORS = 'UPDATE_STORY_COLORS',

  // {String} updatedAt: the updatedAt field from a draft story
  // {String} storyUid
  STORY_UPDATED = 'STORY_UPDATED',

  // {Object} data: Story data Object.
  STORY_REPLACE_FROM_JSON_IMPORT = 'STORY_REPLACE_FROM_JSON_IMPORT',

  // {Object} data: Story data Object.
  STORY_PREPARE_FOR_TEMPLATE = 'STORY_PREPARE_FOR_TEMPLATE',

  // {Object} data: Story data Object.
  STORY_REPLACE_FROM_TEMPLATE = 'STORY_REPLACE_FROM_TEMPLATE',

  // {String} storyUid
  // {String} description
  STORY_SET_DESCRIPTION = 'STORY_SET_DESCRIPTION',

  // {String} storyUid
  // {String} title
  STORY_SET_TITLE = 'STORY_SET_TITLE',

  // {String} storyUid
  // {Object} tile
  STORY_SET_TILE_CONFIG = 'STORY_SET_TILE_CONFIG',

  // {String} storyUid
  // {String} theme
  STORY_UPDATE_THEME = 'STORY_UPDATE_THEME',

  // {String} storyUid
  // {Object} publishedStory
  STORY_SET_PUBLISHED_STORY = 'STORY_SET_PUBLISHED_STORY',

  // {String} storyUid
  // {String} layout
  STORY_UPDATE_LAYOUT = 'STORY_UPDATE_LAYOUT',

  // {String} storyUid
  // {String} blockId
  STORY_DELETE_BLOCK = 'STORY_DELETE_BLOCK',

  // {String} storyUid
  // {String} blockId
  STORY_MOVE_BLOCK_DOWN = 'STORY_MOVE_BLOCK_DOWN',

  // {String} storyUid
  // {String} blockId
  STORY_MOVE_BLOCK_UP = 'STORY_MOVE_BLOCK_UP',

  // {String} storyUid
  // {String} blockId
  STORY_TOGGLE_BLOCK_PRESENTATION_VISIBILITY = 'STORY_TOGGLE_BLOCK_PRESENTATION_VISIBILITY',

  // {String} storyUid
  // {number} insertAt: Index in block list to insert block.
  // {Object} blockContent: Object describing components in block.
  STORY_INSERT_BLOCK = 'STORY_INSERT_BLOCK',

  // {String} storyUid
  // {String} blockWithToCId
  // {Object} blockContent: Object describing components in block.
  STORY_INSERT_TABLE_OF_CONTENTS = 'STORY_INSERT_TABLE_OF_CONTENTS',

  // {String} blockId
  // {Number|String} componentIndex
  // {String} type: New component type
  // {any} value: New component value
  // {Object} layout: grid data layout
  BLOCK_INSERT_COMPONENT = 'BLOCK_INSERT_COMPONENT',

  // {String} storyUid
  // {String} blockId
  // {Number|String} componentIndex
  BLOCK_DELETE_COMPONENT = 'BLOCK_DELETE_COMPONENT',

  // {String} blockId
  // {number|String} componentIndex
  // {String} type: New component type.
  // {any} value: New component value.
  BLOCK_UPDATE_COMPONENT = 'BLOCK_UPDATE_COMPONENT',

  // {String} blockId
  // {Object} components: Object describing components in block.
  BLOCK_UPDATE_COMPONENTS_LAYOUT = 'BLOCK_UPDATE_COMPONENTS_LAYOUT',

  // {String} blockId
  // {String} color
  BLOCK_UPDATE_COLOR = 'BLOCK_UPDATE_COLOR',

  // {Array of String} blockId
  BLOCK_CONFIRMATION_CREATE = 'BLOCK_CONFIRMATION_CREATE',

  // {String} blockId
  BLOCK_CONFIRMATION_UPDATE = 'BLOCK_CONFIRMATION_UPDATE',

  /**
   * Story save
   */

  // {String} storyUid
  STORY_SAVE_STARTED = 'STORY_SAVE_STARTED',

  // {String} storyUid
  // {String} digest
  STORY_SAVED = 'STORY_SAVED',

  // {String} storyUid
  // {String} message
  STORY_SAVE_FAILED = 'STORY_SAVE_FAILED',

  /**
   * Story metadata and permissions save
   */

  // {String} storyUid
  STORY_SAVE_METADATA = 'STORY_SAVE_METADATA',

  // {boolean} isPublic
  STORY_SET_PERMISSIONS = 'STORY_SET_PERMISSIONS',

  /**
   * Drag drop and double-click-to-add-block actions
   */

  // {String} storyUid
  STORY_DRAG_LEAVE = 'STORY_DRAG_LEAVE',

  // {String} storyUid
  // {Object} blockContent: Data of block being dragged over story.
  // {Object} pointer: Pointer event that triggered this action.
  // {HTMLElement} storyElement: Root of story DOM being dragged over.
  STORY_DRAG_OVER = 'STORY_DRAG_OVER',

  // {String} storyUid
  STORY_DROP = 'STORY_DROP',

  /**
   * Rich text editor -> rich text editor toolbar communication
   */

  // {array[String]} activeFormats: Currently available formatters.
  RTE_TOOLBAR_UPDATE_ACTIVE_FORMATS = 'RTE_TOOLBAR_UPDATE_ACTIVE_FORMATS',

  // {String} activeColor
  RTE_TOOLBAR_SET_ACTIVE_COLOR = 'RTE_TOOLBAR_SET_ACTIVE_COLOR',

  /**
   * History management. Currently only dispatched through Flux.
   */

  // {String} storyUid
  HISTORY_UNDO = 'HISTORY_UNDO',

  // {String} storyUid
  HISTORY_REDO = 'HISTORY_REDO',

  /**
   * Generic asset selector flow
   */

  // No payload
  ASSET_SELECTOR_IMAGE_SEARCH_LOAD_MORE = 'ASSET_SELECTOR_IMAGE_SEARCH_LOAD_MORE',

  // {String} phrase
  ASSET_SELECTOR_IMAGE_SEARCH = 'ASSET_SELECTOR_IMAGE_SEARCH',

  // {String} uri
  ASSET_SELECTOR_IMAGE_SELECTED = 'ASSET_SELECTOR_IMAGE_SELECTED',

  // Have the user select an asset, then insert it into
  // the given block and component.
  // {String} blockId
  // {Number} componentIndex
  ASSET_SELECTOR_SELECT_ASSET_FOR_COMPONENT = 'ASSET_SELECTOR_SELECT_ASSET_FOR_COMPONENT',

  // {Object} file
  ASSET_SELECTOR_IMAGE_UPLOAD = 'ASSET_SELECTOR_IMAGE_UPLOAD',

  // No payload
  ASSET_SELECTOR_IMAGE_PREVIEW_BACK = 'ASSET_SELECTOR_IMAGE_PREVIEW_BACK',

  // {Object} crop
  // {Number} crop.x
  // {Number} crop.y
  // {Number} crop.width
  // {Number} crop.height
  ASSET_SELECTOR_IMAGE_CROP_SET = 'ASSET_SELECTOR_IMAGE_CROP_SET',

  // No payload
  ASSET_SELECTOR_IMAGE_CROP_COMMIT = 'ASSET_SELECTOR_IMAGE_CROP_COMMIT',

  // No payload
  ASSET_SELECTOR_IMAGE_CROP_START = 'ASSET_SELECTOR_IMAGE_CROP_START',

  // No payload
  ASSET_SELECTOR_IMAGE_CROP_RESET = 'ASSET_SELECTOR_IMAGE_CROP_RESET',

  /**
   * Image embed flow
   */

  ASSET_SELECTOR_CANCEL_FILE_UPLOAD = 'ASSET_SELECTOR_CANCEL_FILE_UPLOAD',

  ASSET_SELECTOR_DRAG_FILES = 'ASSET_SELECTOR_DRAG_FILES',

  // No payload
  ASSET_SELECTOR_CHOOSE_IMAGE_UPLOAD = 'ASSET_SELECTOR_CHOOSE_IMAGE_UPLOAD',

  // No payload
  ASSET_SELECTOR_TOGGLE_IMAGE_WINDOW_TARGET = 'ASSET_SELECTOR_TOGGLE_IMAGE_WINDOW_TARGET',

  // {String} altAttribute
  ASSET_SELECTOR_UPDATE_IMAGE_ALT_ATTRIBUTE = 'ASSET_SELECTOR_UPDATE_IMAGE_ALT_ATTRIBUTE',

  // {String} url
  ASSET_SELECTOR_UPDATE_IMAGE_URL_WRAPPER = 'ASSET_SELECTOR_UPDATE_IMAGE_URL_WRAPPER',

  // {String} titleAttribute
  ASSET_SELECTOR_UPDATE_TITLE_ATTRIBUTE = 'ASSET_SELECTOR_UPDATE_TITLE_ATTRIBUTE',

  // {String} id
  URL_UPLOAD = 'URL_UPLOAD',

  // {String} id
  // {File, Blob} file
  FILE_UPLOAD = 'FILE_UPLOAD',

  // No payload
  FILE_CANCEL = 'FILE_CANCEL',

  // Edit an existing asset.
  // {String} blockId
  // {number} componentIndex
  // {String} provider. One of:
  //  - 'SOCRATA_VISUALIZATION'
  //  - 'YOUTUBE'
  //  - 'IMAGE'
  //  - 'EMBED_CODE'
  ASSET_SELECTOR_EDIT_EXISTING_ASSET_EMBED = 'ASSET_SELECTOR_EDIT_EXISTING_ASSET_EMBED',

  // In the current workflow, jump (usually back) to a specific step.
  // {String} Step. One of AssetSelectorStore.WIZARD_STEP.
  ASSET_SELECTOR_JUMP_TO_STEP = 'ASSET_SELECTOR_JUMP_TO_STEP',

  // No payload
  ASSET_SELECTOR_CLOSE = 'ASSET_SELECTOR_CLOSE',

  // {String} type. New component type.
  ASSET_SELECTOR_UPDATE_COMPONENT_TYPE = 'ASSET_SELECTOR_UPDATE_COMPONENT_TYPE',

  // {Boolean} type, dictates whether a custom measure link is being used in stories
  ASSET_SELECTOR_IS_CUSTOM_MEASURE_LINK_ENABLED = 'ASSET_SELECTOR_IS_CUSTOM_MEASURE_LINK_ENABLED',

  // {String} type, custom link for measure tile in stories
  ASSET_SELECTOR_UPDATE_CUSTOM_MEASURE_HREF = 'ASSET_SELECTOR_UPDATE_CUSTOM_MEASURE_HREF',

  // {String} type, custom link text for measure tile in stories
  ASSET_SELECTOR_UPDATE_CUSTOM_MEASURE_TEXT = 'ASSET_SELECTOR_UPDATE_CUSTOM_MEASURE_TEXT',

  // The user chose an asset provider.
  // {String} provider. One of:
  //  - 'SOCRATA_VISUALIZATION'
  //  - 'YOUTUBE'
  //  - 'IMAGE'
  //  - 'EMBED_CODE'
  //  - 'GLOBAL_FILTER'
  ASSET_SELECTOR_PROVIDER_CHOSEN = 'ASSET_SELECTOR_PROVIDER_CHOSEN',

  // The user chose a visualization option.
  // {String} visualizationOption. One of:
  // - 'INSERT_VISUALIZATION'
  // - 'CREATE_VISUALIZATION'
  ASSET_SELECTOR_VISUALIZATION_OPTION_CHOSEN = 'ASSET_SELECTOR_VISUALIZATION_OPTION_CHOSEN',

  // The user chose the 'other media' option.
  // {String} otherOption. One of:
  // - 'YOUTUBE'
  // - 'STORY_TILE'
  // - 'EMBED_CODE'
  ASSET_SELECTOR_OTHER_MEDIA_OPTION_CHOSEN = 'ASSET_SELECTOR_OTHER_MEDIA_OPTION_CHOSEN',

  // The user chose a measure option.
  // {String} measureOption. One of:
  // - 'INSERT_MEASURE'
  // - 'CREATE_MEASURE'
  ASSET_SELECTOR_MEASURE_OPTION_CHOSEN = 'ASSET_SELECTOR_MEASURE_OPTION_CHOSEN',

  /**
   * Story tile embed flow
   */

  // {String} url
  ASSET_SELECTOR_UPDATE_STORY_URL = 'ASSET_SELECTOR_UPDATE_STORY_URL',

  // No payload
  ASSET_SELECTOR_TOGGLE_STORY_WINDOW_TARGET = 'ASSET_SELECTOR_TOGGLE_STORY_WINDOW_TARGET',

  /**
   * YouTube embed flow
   */

  // {String} url
  ASSET_SELECTOR_UPDATE_YOUTUBE_URL = 'ASSET_SELECTOR_UPDATE_YOUTUBE_URL',

  /**
   * Socrata visualization flow
   */

  // No payload
  ASSET_SELECTOR_CHOOSE_VISUALIZATION = 'ASSET_SELECTOR_CHOOSE_VISUALIZATION',

  // {String} datasetUid
  // {String} domain
  ASSET_SELECTOR_CHOOSE_MEASURE = 'ASSET_SELECTOR_CHOOSE_MEASURE',

  // {String} datasetUid
  // {boolean} isNewBackend
  // {String} domain
  ASSET_SELECTOR_CHOOSE_VISUALIZATION_DATASET = 'ASSET_SELECTOR_CHOOSE_VISUALIZATION_DATASET',

  // {String} mapOrChartUid
  // {String} domain
  ASSET_SELECTOR_CHOOSE_VISUALIZATION_MAP_OR_CHART_OR_CALENDAR = 'ASSET_SELECTOR_CHOOSE_VISUALIZATION_MAP_OR_CHART_OR_CALENDAR',

  // User chose to visualize the dataset as a chart.
  // No payload.
  ASSET_SELECTOR_VISUALIZE_AS_CHART_OR_MAP = 'ASSET_SELECTOR_VISUALIZE_AS_CHART_OR_MAP',

  // {Object} cardData
  ASSET_SELECTOR_UPDATE_VISUALIZATION_CONFIGURATION = 'ASSET_SELECTOR_UPDATE_VISUALIZATION_CONFIGURATION',

  // {Object} measureConfig
  ASSET_SELECTOR_UPDATE_MEASURE_CONFIGURATION = 'ASSET_SELECTOR_UPDATE_MEASURE_CONFIGURATION',

  /**
   * Code embed flow
   */

  // No payload
  ASSET_SELECTOR_CHOOSE_EMBED_CODE = 'ASSET_SELECTOR_CHOOSE_EMBED_CODE',

  // {String} html
  ASSET_SELECTOR_UPDATE_EMBED_CODE = 'ASSET_SELECTOR_UPDATE_EMBED_CODE',

  // {number} percentLoaded
  EMBED_CODE_UPLOAD_PROGRESS = 'EMBED_CODE_UPLOAD_PROGRESS',

  // No payload
  EMBED_CODE_UPLOAD_DONE = 'EMBED_CODE_UPLOAD_DONE',

  // No payload
  EMBED_CODE_UPLOAD_ERROR = 'EMBED_CODE_UPLOAD_ERROR',

  /**
   * Link Modal
   */

  // No Payload
  LINK_MODAL_CLOSE = 'LINK_MODAL_CLOSE',

  // {String} editorId
  // {String} text
  // {String} url
  // {boolean} openInNewWindow
  LINK_MODAL_OPEN = 'LINK_MODAL_OPEN',

  // {String} text
  // {String} url
  // {boolean} openInNewWindow
  LINK_MODAL_UPDATE = 'LINK_MODAL_UPDATE',

  // {String} text
  // {String} url
  // {boolean} openInNewWindow
  LINK_MODAL_ACCEPT = 'LINK_MODAL_ACCEPT',

  /*
   * Make a Copy flow
   */

  // No payload
  STORY_MAKE_COPY_MODAL_OPEN = 'STORY_MAKE_COPY_MODAL_OPEN',

  // No payload
  STORY_MAKE_COPY_MODAL_SUBMIT = 'STORY_MAKE_COPY_MODAL_SUBMIT',

  // No payload
  STORY_MAKE_COPY_MODAL_CANCEL = 'STORY_MAKE_COPY_MODAL_CANCEL',

  // Only used for testing as a temporary fix
  // No payload
  STORY_STORE_RESET = 'STORY_STORE_RESET',

  /**
   * Link Action Tip
   */

  // {String} text
  // {String} link
  // {String} editorId
  // {boolean} openInNewWindow
  // {ClientRect} boundingClientRect
  LINK_TIP_OPEN = 'LINK_TIP_OPEN',

  // No Payload
  LINK_TIP_CLOSE = 'LINK_TIP_CLOSE',

  // No Payload
  LINK_TIP_REMOVE = 'LINK_TIP_REMOVE',

  /**
   * Collaborators Modal / Access Manager
   */

  // No payload
  COLLABORATORS_MODAL_OPEN = 'COLLABORATORS_MODAL_OPEN',

  // No payload
  COLLABORATORS_MODAL_CLOSE = 'COLLABORATORS_MODAL_CLOSE',

  /*
   * Edit Metadata Modal
   */

  // No payload
  EDIT_METADATA_MODAL_OPEN = 'EDIT_METADATA_MODAL_OPEN',

  // No payload
  EDIT_METADATA_MODAL_CLOSE = 'EDIT_METADATA_MODAL_CLOSE',

  /*
   * Share and Embed Modal
   */

  // No payload
  SHARE_AND_EMBED_MODAL_OPEN = 'SHARE_AND_EMBED_MODAL_OPEN',

  // No payload
  SHARE_AND_EMBED_MODAL_CLOSE = 'SHARE_AND_EMBED_MODAL_CLOSE',

  // No payload
  LOGIN_BUTTON_CLICK = 'LOGIN_BUTTON_CLICK',

  // {Object} Downtime
  DOWNTIME_ACKNOWLEDGE = 'DOWNTIME_ACKNOWLEDGE',

  // {String} blockId
  // {Number} componentIndex
  COPY_COMPONENT = 'COPY_COMPONENT',

  // {String} blockId
  // {Number} componentIndex
  PASTE_COMPONENT = 'PASTE_COMPONENT',

  // {String} blockId
  // {Number} componentIndex
  RESET_COMPONENT = 'RESET_COMPONENT',

  // {String} blockId
  // {Number} componentIndex
  // {String} actionType
  ACTION_COMPONENT_START = 'ACTION_COMPONENT_START',

  // {String} blockId
  // {Number} componentIndex
  MOVE_COMPONENT_DESTINATION_CHOSEN = 'MOVE_COMPONENT_DESTINATION_CHOSEN',

  // Initialize the global filter reducer
  // {object} storyData
  POPULATE_GLOBAL_FILTER = 'POPULATE_GLOBAL_FILTER',

  // Set metadata for the story's data source
  // {object} storyData
  POPULATE_DATASET_METADATA = 'POPULATE_DATASET_METADATA',

  // User has selected a story level dataset from a story component
  // {String} storyUid
  // {String} datasetUid
  STORY_DATA_SOURCE_UPDATED = 'STORY_DATA_SOURCE_UPDATED',

  // No Payload
  ACTION_COMPONENT_CANCEL = 'ACTION_COMPONENT_CANCEL',

  /**
   * {object} tableVif: The new table vif. Replaces the old vif.
   */
  UPDATE_TABLE_VIF = 'UPDATE_TABLE_VIF',

  // No payload
  TOGGLE_ACCESS_MANAGER = 'TOGGLE_ACCESS_MANAGER',

  // {String} storyUid
  // {object} globalFilters: mapping from storyuid to array of SoqlFilters
  GLOBAL_FILTER_UPDATED = 'GLOBAL_FILTER_UPDATED',

  // No payload
  GLOBAL_FILTER_REMOVED = 'GLOBAL_FILTER_REMOVED',

  // {String} storyUid
  // {String} datasetUid
  STORY_DATA_SOURCE_REMOVED = 'STORY_DATA_SOURCE_REMOVED',

  /**
   * Data and Filtering (Multi source GFB)
   */

  // no payload
  REFRESH_REQUIRED_DATA_SOURCES = 'REFRESH_REQUIRED_DATA_SOURCES',

  // no payload
  GLOBAL_FILTER_SAVE_STORY_AFTER_NEW_SOURCES_ADDED = 'GLOBAL_FILTER_SAVE_STORY_AFTER_NEW_SOURCES_ADDED',

  // {object} filter or parameter configuration
  FILTER_PARAMETER_CONFIGURATION_ADDED = 'FILTER_PARAMETER_CONFIGURATION_ADDED',

  // {number} index to remove
  FILTER_PARAMETER_CONFIGURATION_DELETED = 'FILTER_PARAMETER_CONFIGURATION_DELETED',

  // {FilterParameterConfiguration} updatedConfig
  // {number} index (into filterParameterConfigurations)
  FILTER_PARAMETER_CONFIGURATION_UPDATED = 'FILTER_PARAMETER_CONFIGURATION_UPDATED',

  // {FilterParameterConfigurations[]} updatedConfigs
  ALL_FILTER_PARAMETER_CONFIGURATIONS_UPDATED = 'ALL_FILTER_PARAMETER_CONFIGURATIONS_UPDATED'
}

export default Actions;
