import React from 'react';

import ComponentEditMenu from 'lib/components/block-component-renderers/shared/ComponentEditMenu';
import ComponentDraggerControl from 'editor/components/ComponentDraggerControl';
import ComponentActionOverlay from 'lib/components/block-component-renderers/shared/componentActionOverlay';
import { isFlexibleStory } from 'lib/FlexibleLayoutUtils';

import { BlockComponent, ComponentType } from 'types';

export interface ComponentEditControlsProps {
  blockId: string;
  componentIndex: number;
  componentData: BlockComponent;
  editMode: boolean;
}

/**
 * The ComponentEditControls component will render the controls for a React component
 * including the edit menu, dragger control (flexible layout only), and action overlay
 */
const ComponentEditControls: React.FC<ComponentEditControlsProps> = ({
  blockId,
  componentIndex,
  componentData,
  editMode
}) => {
  if (!editMode) {
    return null;
  }

  const needsComponentDragger =
    editMode && isFlexibleStory() && componentData.type !== ComponentType.GLOBAL_FILTER;

  return (
    <>
      <ComponentEditMenu
        {...{
          blockId,
          componentIndex,
          componentData,
          editMode
        }}
      />
      {needsComponentDragger && <ComponentDraggerControl blockId={blockId} componentIndex={componentIndex} />}
      <ComponentActionOverlay blockId={blockId} componentIndex={componentIndex} />
    </>
  );
};

export default ComponentEditControls;
