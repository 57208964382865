import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { ForgeIcon } from '@tylertech/forge-react';

import { FeatureFlags } from 'common/feature_flags';
import I18n from 'common/i18n';
import SocrataIcon from 'common/components/SocrataIcon';
import {
  ForgeStatusIcons,
  StatusIcons,
  StatusValues,
  STATUS_ENDED
} from 'common/performance_measures/lib/constants';

const StatusIcon = ({ value }) => {
  if (!FeatureFlags.valueOrDefault('enable_global_filters_measures', false)) {
    const icon = StatusIcons[value];
    return icon ? <SocrataIcon name={icon} /> : null;
  }

  const icon = ForgeStatusIcons[value];
  return icon ? <ForgeIcon name={icon} /> : null;
};

/**
 * Creates a status banner for the Measure Result Card.
 *
 * Status is user configured as part of measure creation.
 * Props:
 * - value -- specifies the color, icon, and default label. If not provided, the status banner is disabled.
 * - labels -- overrides specific to each possible value
 */
const StatusBanner = ({ ended, value, labels }) => {
  if (!value) {
    return null;
  }

  const labelOverride = labels && labels[value];
  const labelDefault = I18n.t(`shared.performance_measures.measure.status.values.${value}`);
  const label = ended ? labels.ended : labelOverride || labelDefault;

  return (
    <div className={`status-banner status-banner-${value}`}>
      <div className="status-banner-content">
        <StatusIcon value={value} />
        <span className="status-banner-text" title={label}>
          {label}
        </span>
      </div>
      <div className="flag-ends"></div>
    </div>
  );
};

StatusBanner.propTypes = {
  ended: PropTypes.bool,
  labels: PropTypes.objectOf(PropTypes.string),
  value: PropTypes.oneOf([..._.values(StatusValues), STATUS_ENDED])
};

export default StatusBanner;
