import React from 'react';
import { IMenuSelectEventData } from '@tylertech/forge';
import { ForgeMenu, ForgeIconButton, ForgeIcon } from '@tylertech/forge-react';

import I18n from 'common/i18n';

const scope = 'shared.components.filterable_card_header';

/**
 * Display a More options menu for the FilterableCardHeader. This will handle
 * adding a View source link, and additional options can be added by passing in
 * props.
 */
const MoreOptionsMenu = ({
  additionalOptions = [],
  onKebabMenuSelect,
  kebabMenuOptionBuilder,
  viewSourceDataLink,
  viewSourceDataText
}: {
  additionalOptions?: unknown[];
  onKebabMenuSelect?: (customEvent: CustomEvent<IMenuSelectEventData>) => void;
  kebabMenuOptionBuilder?: (option: { value: string; label: string }, listItem: any) => void;
  viewSourceDataLink?: string;
  viewSourceDataText?: string;
}) => {
  const VIEW_SOURCE = 'view_source';
  const onMenuSelect = (customEvent: CustomEvent<IMenuSelectEventData>) => {
    switch (customEvent.detail.value) {
      case VIEW_SOURCE: {
        window.open(viewSourceDataLink, '_blank', 'noopener,noreferrer');
        break;
      }
      default:
        onKebabMenuSelect?.(customEvent);
    }
  };

  const menuOptions = viewSourceDataLink?.length
    ? [
        {
          value: VIEW_SOURCE,
          label: viewSourceDataText
        },
        ...additionalOptions
      ]
    : additionalOptions;

  const menuOptionBuilder = (option: { value: string; label: string }, listItem: any) => {
    if (option.value === VIEW_SOURCE) {
      // forge-menu has internal logic that converts the menu item to a link
      // when href is provided. We want the item to be a link so that clicking it
      // does not trigger the browser's pop-up blocker.
      listItem.href = viewSourceDataLink;
      listItem.target = '_blank';
      listItem.setAttribute('data-pendo', 'view-source-link');
      listItem.setAttribute('data-testid', 'view-source-link');
    } else {
      kebabMenuOptionBuilder?.(option, listItem);
    }

    return option.label;
  };

  if (menuOptions.length === 0) {
    return null;
  }

  return (
    <ForgeMenu
      placement="bottom-end"
      on-forge-menu-select={onMenuSelect}
      options={menuOptions}
      optionBuilder={menuOptionBuilder}
    >
      <ForgeIconButton dense density-level="3">
        <button
          type="button"
          data-testid="filterable-card-header-vertical-kabob"
          className="tyler-icons"
          aria-label={I18n.t('more_options_menu', { scope })}
        >
          <ForgeIcon name="more_vert" />
        </button>
      </ForgeIconButton>
    </ForgeMenu>
  );
};

export default MoreOptionsMenu;
